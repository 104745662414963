<script lang="ts" setup>
import { useAuth0 } from '@auth0/auth0-vue';
import { AppModalSize } from '@karta.io/app-components';
import {
  UiButtonSize,
  UiButtonTheme,
  UiColorVar,
  UiDropdownPlacement,
  UiFormItemSize,
  UiIconValue,
  UiTypography,
} from '@karta.io/ui-components';

import { useStagingManagement } from '@/composables';

import { CoreLocalStorage } from '@/enums';

interface Props {
  modelValue: boolean;
}

defineOptions({ name: 'StagingManagementModal' });

const props = defineProps<Props>();

const emit = defineEmits<{
  'update:modelValue': [value: boolean];
  destroy: [];
}>();

const { logout } = useAuth0();

const isOpen = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value),
});
const closeModal = () => emit('update:modelValue', false);

const cn = useClassName('staging-management-modal');
const apiBaseUrlStore = useStorage<string>(CoreLocalStorage.ApiBaseUrl, '');
const isDisabled = ref(false);

const {
  urls: items,

  isLoading,
  isFetching,
  isError,

  reload,
  updateSearch,
  updateStaging,
} = useStagingManagement();

const isNoContent = computed(() => search.value && !items.value?.length);

const handleSwitch = async (url: string) => {
  isDisabled.value = true;
  apiBaseUrlStore.value = url;

  await logout({
    logoutParams: {
      returnTo: `${window.location.origin}/auth/logout`,
    },
  });
};

const isCollapsed = useStorage<boolean>(
  CoreLocalStorage.StagingManagementCardCollapsed,
  true,
);
const collapse = () => (isCollapsed.value = !isCollapsed.value);

const tooltipText = `After changing the backend url, the application will be restarted and the
  account will be logged out. <br /> <br /> You can open this modal on every page. Just add the hash #staging-management to the page address.
`;

const search = ref('');
const headerIconElement = ref<HTMLDivElement>();

const handleSearchUpdate = (value: string) => {
  search.value = value;
  updateSearch(value);
};
const handleSearchClear = () => {
  search.value = '';
  updateSearch('');
};
</script>

<template>
  <AppModal
    v-model="isOpen"
    :size="AppModalSize.Lg"
    classModifier="staging-management"
    @destroy="emit('destroy')"
  >
    <AppModalContent :class="cn.b()" @click:close="closeModal">
      <template #header>
        <AppModalHeader>
          <template #title>
            <div :class="cn.e('header-title')">
              <AppText :typography="UiTypography.TextLgSemibold">
                Staging management
              </AppText>

              <div ref="headerIconElement" :class="cn.e('header-icon')">
                <UiIcon :value="UiIconValue.InformationCircle" size="16px" />
              </div>

              <UiTooltip
                :target="headerIconElement"
                :mainText="tooltipText"
                hasArrow
                :delay="150"
                :placement="UiDropdownPlacement.Bottom"
                :zIndex="9000"
              />
            </div>
          </template>
        </AppModalHeader>
      </template>

      <template #body>
        <div :class="cn.e('body')">
          <div :class="cn.e('controls')">
            <div :class="cn.e('search')">
              <UiInput
                :modelValue="search"
                :size="UiFormItemSize.Md"
                placeholder="Search by clickup id, clickup name, clickup url or backend url"
                :prefixIcon="UiIconValue.Search"
                @update:modelValue="handleSearchUpdate"
              >
                <template v-if="search" #suffix>
                  <UiButton
                    :theme="UiButtonTheme.SecondaryLink"
                    :size="UiButtonSize.Xs"
                    :iconOnly="UiIconValue.X"
                    @click="handleSearchClear"
                  />
                </template>
              </UiInput>
            </div>

            <UiButton
              :theme="UiButtonTheme.SecondaryOutline"
              :size="UiButtonSize.Md"
              :disabled="isLoading"
              :iconOnly="
                isCollapsed
                  ? UiIconValue.ArrowsPointingOut
                  : UiIconValue.ArrowsPointingIn
              "
              @click="collapse"
            />

            <UiButton
              :theme="UiButtonTheme.SecondaryOutline"
              :size="UiButtonSize.Md"
              :disabled="isLoading || isFetching"
              :loading="isLoading || isFetching"
              :iconOnly="UiIconValue.Refresh"
              @click="reload"
            />
          </div>

          <div :class="cn.e('container')">
            <div v-show="isError && !isLoading" :class="cn.e('not-found')">
              <AppText :typography="UiTypography.TextMdSemibold">
                Error! Something went wrong
              </AppText>
            </div>

            <div v-if="isLoading" :class="cn.e('loader')">
              <UiIcon
                :value="UiIconValue.LoadingLoop"
                :color="UiColorVar.Primary500"
                size="32px"
              />
            </div>

            <ul
              v-show="!isLoading && !isNoContent && !isError"
              :class="cn.e('list')"
            >
              <li
                v-for="(item, index) in items"
                :key="`${item.clickupCustomId}-${index}`"
                :class="cn.e('item')"
              >
                <StagingManagementCard
                  :id="item.id"
                  :isActive="item.isActive"
                  :isCollapsed="isCollapsed"
                  :isDisabled="isDisabled"
                  :url="item.host"
                  :clickupName="item.clickup?.name"
                  :clickupUrl="item.clickup?.url"
                  :clickupCustomId="item.clickupCustomId"
                  :clickupStatus="item.clickup?.status"
                  :status="item.status"
                  @click:switch="handleSwitch"
                  @updated="updateStaging"
                />
              </li>
            </ul>

            <div
              v-show="!isLoading && isNoContent && !isError"
              :class="cn.e('not-found')"
            >
              <AppText :typography="UiTypography.TextSmSemibold">
                No items found
              </AppText>
            </div>
          </div>
        </div>
      </template>
    </AppModalContent>
  </AppModal>
</template>

<style lang="scss">
.app-modal_staging-management {
  --app-modal-container-max-width: 1200px;
  --app-modal-container-height: 90%;
  --app-modal-container-max-height: 1200px;
}

.staging-management-modal {
  &__header-title {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, max-content));
    align-items: center;
    gap: var(--spacing-02);
  }

  &__header-icon {
    padding-bottom: 2px;
    color: var(--color-secondary-500);
    transition: color var(--transition-duration);
    &:hover {
      color: var(--color-secondary-900);
      cursor: pointer;
    }
  }

  &__body {
    padding: 0 0 var(--spacing-10);
  }

  &__alert {
    margin-bottom: var(--spacing-06);
  }

  &__controls {
    position: sticky;
    top: 0;
    z-index: 1;
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-gap: var(--spacing-04);
    padding: var(--spacing-04);
    background-color: var(--color-base-white);
    border-bottom: 1px solid var(--color-secondary-200);
  }

  &__container {
    padding: var(--spacing-04);
  }

  &__search {
    .ui-input__suffix {
      --ui-input-icon-size: 18px;
    }

    .ui-button__icon {
      --ui-button-icon-size: 18px;
    }
  }

  &__loader {
    display: flex;
    width: 100%;
    height: 60px;
    justify-content: center;
    align-items: center;
  }

  &__not-found {
    display: flex;
    width: 100%;
    height: 60px;
    justify-content: center;
    align-items: center;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &__item {
    border-radius: var(--border-radius-lg);
    box-shadow: var(--shadow-md);
  }

  &__item:not(:last-child) {
    margin-bottom: var(--spacing-06);
  }
}

@include breakpoint('sm') {
  .staging-management-modal {
    &__controls {
      padding: var(--spacing-04) var(--spacing-06);
    }

    &__container {
      padding: var(--spacing-04) var(--spacing-06);
    }
  }
}
</style>
