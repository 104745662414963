import { UiIconValue } from '@karta.io/ui-components';

import { NavigationGroup } from '@/enums';
import { CoreUserPermissionsMap } from '@/data';
import type { NavigationItem, Page } from '@/interfaces';

export const COUNTERPARTIES_NAVIGATION: NavigationItem = {
  id: 'counterparties',
  title: 'Counterparties',
  to: '/counterparties',
  icon: UiIconValue.Identification,
  permissions: [CoreUserPermissionsMap.ReadCounterparty],
  disabled: false,
  group: (hasCompanyAccounts, hasExternalAccounts) =>
    hasCompanyAccounts || hasExternalAccounts
      ? NavigationGroup.Active
      : NavigationGroup.Inactive,
};

export const COUNTERPARTIES_INDEX_PAGE: Page = {
  name: 'counterparties-index-page',
  metaInfo: {
    title: 'Counterparties',
  },
  navigation: COUNTERPARTIES_NAVIGATION,
};
