<script lang="ts" setup>
/**
 * @deprecated use CompanyAccountSelect
 */
import { type Nullable, UiColor } from '@karta.io/ui-components';

import { useCoreStore } from '@/stores';
import { readCompanyAccounts } from '@/api';
import { sliceString } from '@/helpers';
import {
  CompanyAccountStatusesMap,
  KARTA_PRIMARY_ACCOUNT_IMAGE,
  KARTA_SUB_ACCOUNT_IMAGE,
} from '@/data';

import {
  CompanyAccountStatus,
  CompanyAccountType,
  CounterpartyPaymentAccountType,
} from '@/enums';
import type { CompanyAccount, CompanyAccountsFilters } from '@/interfaces';

interface Props {
  modelValue?: CompanyAccount['id'];
  filters?: Nullable<CompanyAccountsFilters>;
  isPreSelected?: boolean;
  isClearable?: boolean;
  isDisabledIfAlone?: boolean;
  excludedIds?: CompanyAccount['id'][];
  optionsMaxHeight?: number;
  searchable?: boolean;
  paymentAccountType?: CounterpartyPaymentAccountType;
  hasSourceAccountTag?: boolean;
  isBodyAppended?: boolean;
  zIndex?: number;
  disabledStatuses?: Nullable<CompanyAccount['status'][]>;
}

defineOptions({ name: 'CompanyAccountsSelect' });

const props = withDefaults(defineProps<Props>(), {
  excludedIds: () => [],
  disabledStatuses: () => [
    CompanyAccountStatus.Closed,
    CompanyAccountStatus.Locked,
  ],
  optionsMaxHeight: 320,
  zIndex: 1,
  isDisabledIfAlone: false,
  searchable: false,
  paymentAccountType: CounterpartyPaymentAccountType.Bank,
  filters: null,
  hasSourceAccountTag: false,
  isBodyAppended: false,
});

const emit = defineEmits<{
  'update:modelValue': [value?: CompanyAccount['id']];
  change: [value?: CompanyAccount];
}>();

const DEFAULT_ORDERING = {
  ordering: 'type',
};

const coreStore = useCoreStore();

const currentCompanyAccountId = computed({
  get() {
    return props.modelValue;
  },
  set(value?: CompanyAccount['id']) {
    emit('update:modelValue', value);
  },
});

const currentCompanyAccount = computed(() =>
  companyAccountItems.value?.find(
    item => item.id === currentCompanyAccountId.value,
  ),
);

const isSkeletonShown = computed(
  () =>
    isLoading.value &&
    props.isPreSelected &&
    !companyAccountOptions.value?.length &&
    !search.value,
);

const isDisabled = computed(() => {
  /**
   * Новая фича. Дизейблим если isPreSelected и вернулся только 1 ответ
   */
  if (
    props.isDisabledIfAlone &&
    props.isPreSelected &&
    !isLoading.value &&
    !search.value &&
    companyAccountOptions.value?.length === 1
  ) {
    return true;
  }

  return false;
});

const mapCompanyAccountOption = (account: CompanyAccount) => {
  const isOptionDisabled = Boolean(
    props.disabledStatuses?.length &&
      props.disabledStatuses?.includes(account.status),
  );

  let tag;

  if (
    props.hasSourceAccountTag &&
    account.type === CompanyAccountType.SubAccount
  ) {
    tag = {
      label: account.sourceAccount?.name,
      color: UiColor.Secondary,
    };
  }

  if (isOptionDisabled) {
    tag = {
      label: CompanyAccountStatusesMap[account.status].title,
      color: UiColor.Error,
    };
  }

  return {
    tag,
    label: account.accountNumber
      ? `${account.name} ${sliceString(account.accountNumber)}`
      : account.name,
    value: account.id,
    icon:
      account.type === CompanyAccountType.Primary
        ? KARTA_PRIMARY_ACCOUNT_IMAGE
        : KARTA_SUB_ACCOUNT_IMAGE,
    caption: formatMoney(account.balance, account.currency),
    disabled: isOptionDisabled,
  };
};

const params = computed(() => ({
  excludedIds: props.excludedIds?.length ? props.excludedIds : undefined,
  ...(props.filters && {
    filters: props.filters,
  }),
  extraFields: 'source_account',
  ...DEFAULT_ORDERING,
}));

const {
  search,
  options: companyAccountOptions,
  items: companyAccountItems,
  isLoading,
  hasNextPage,

  loadPage,
  updateSearch,
} = useAsyncSelect({
  apiMethod: readCompanyAccounts,
  selectedValue: computed(() => props.modelValue),
  queryKey: coreStore.queryKeyByCurrentCompanyId('companyAccountsSelect'),
  params,
  mapOptionCallback: mapCompanyAccountOption,
});

watch(
  isLoading,
  async newValue => {
    await nextTick();

    /**
     * @description Логика для isPreSelected
     */
    if (!newValue && !currentCompanyAccountId.value && props.isPreSelected) {
      const notDisabledCompanyAccount = companyAccountItems.value?.find(
        item => !props.disabledStatuses?.includes(item.status),
      );
      currentCompanyAccountId.value = notDisabledCompanyAccount?.id;
    }
  },
  { immediate: true },
);

watch(
  currentCompanyAccount,
  newCompanyAccount => {
    emit('change', newCompanyAccount);
  },
  { immediate: true },
);
</script>

<template>
  <BaseSelect
    v-model="currentCompanyAccountId"
    :search="search"
    :options="companyAccountOptions"
    :loading="isLoading"
    :disabled="isDisabled"
    isAsync
    :searchable="props.searchable"
    :clearable="props.isClearable"
    placeholder="Select account"
    :hasNextPage="hasNextPage"
    :optionsMaxHeight="props.optionsMaxHeight"
    :isSkeletonShown="isSkeletonShown"
    :zIndex="props.zIndex"
    :isBodyAppended="props.isBodyAppended"
    @load:nextPage="loadPage"
    @update:search="updateSearch"
  />
</template>
