<script setup lang="ts">
import {
  UiButtonSize,
  UiButtonTheme,
  UiColor,
  UiColorVar,
  UiDropdownPlacement,
  UiIconValue,
  UiTypography,
} from '@karta.io/ui-components';

import { useStagingManagement } from '@/composables';

defineOptions({ name: 'StagingManagementBanner' });

const { openModal, baseUrlStore } = useStagingManagement();
const cn = useClassName('staging-management-banner');

const popoverTarget = ref<HTMLDivElement>();
</script>

<template>
  <Banner :color="UiColor.Secondary">
    <div :class="cn.b()">
      <div :class="cn.e('title')">
        <UiIcon :value="UiIconValue.Chip" />
        <AppText
          :typography="UiTypography.TextSmMedium"
          :color="UiColorVar.BaseWhite"
        >
          Custom API
        </AppText>
      </div>

      <div ref="popoverTarget" :class="cn.e('url')">
        <AppText
          :typography="UiTypography.TextSmMedium"
          :color="UiColorVar.BaseWhite"
          textAlign="center"
          textEllipsis="100%"
        >
          {{ baseUrlStore }}
        </AppText>
      </div>

      <UiButton
        :size="UiButtonSize.Md"
        :theme="UiButtonTheme.PrimaryLink"
        :suffixIcon="UiIconValue.ChevronRight"
        @click="openModal"
      >
        Switch URL
      </UiButton>
    </div>

    <UiPopover
      :target="popoverTarget"
      :placement="UiDropdownPlacement.Bottom"
      :delay="300"
      width="600px"
      maxWidth="90vw"
    >
      <StagingManagementBannerPopoverContent :url="baseUrlStore" />
    </UiPopover>
  </Banner>
</template>

<style lang="scss">
.staging-management-banner {
  display: grid;
  grid-template-columns: auto minmax(50px, 1fr) auto;
  align-items: center;
  gap: var(--spacing-03);
  width: 100%;
  height: 24px;

  &__title {
    display: flex;
    gap: var(--spacing-02);
    align-items: center;
    color: var(--color-base-white);
  }

  &__url {
    cursor: pointer;
  }
}
</style>
