import { UiColor, UiToastPosition } from '@karta.io/ui-components';

import { useCoreAppVersion } from '@/composables';

export function useAppBaseChecks() {
  let isVersionToastShow = false;

  const { loadAppVersion } = useCoreAppVersion();

  const checkAppVersion = async () => {
    const isEqual = await loadAppVersion();

    if (isEqual || isVersionToastShow) return;

    UiToastNotify({
      title: 'Attention!',
      message: 'A new version of the site is available.',
      color: UiColor.Primary,
      position: UiToastPosition.BottomLeft,
      duration: 0,
      buttons: {
        first: {
          title: 'Refresh',
          click: () => window.location.reload(),
        },
      },
      onClose: () => {
        isVersionToastShow = false;
      },
    });

    isVersionToastShow = true;
  };

  return {
    checkAppVersion,
  };
}
