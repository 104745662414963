import {
  CompanyPlanKey,
  CompanyProviderIncomePaymentMethod,
  CompanyProviderPaymentService,
} from '@/enums';

type DepositWireFeeMapType = {
  [key in CompanyProviderPaymentService]?: {
    [key in CompanyProviderIncomePaymentMethod]?: {
      label: string;
      planKey: CompanyPlanKey;
    };
  };
};

export const DepositWireFeeMap: DepositWireFeeMapType = {
  [CompanyProviderPaymentService.Stripe]: {
    [CompanyProviderIncomePaymentMethod.InboundAch]: {
      label: 'ACH Processing fee',
      planKey: CompanyPlanKey.StripeInboundAchFee,
    },
    [CompanyProviderIncomePaymentMethod.InboundWire]: {
      label: 'Fedwire processing fee',
      planKey: CompanyPlanKey.StripeInboundWireFee,
    },
  },
  [CompanyProviderPaymentService.Airwallex]: {
    [CompanyProviderIncomePaymentMethod.InboundAch]: {
      label: 'ACH Processing fee',
      planKey: CompanyPlanKey.AirwallexInboundAchFee,
    },
    [CompanyProviderIncomePaymentMethod.InboundWire]: {
      label: 'Fedwire processing fee',
      planKey: CompanyPlanKey.AirwallexInboundWireFee,
    },
    [CompanyProviderIncomePaymentMethod.InboundSwift]: {
      label: 'SWIFT processing fee',
      planKey: CompanyPlanKey.AirwallexInboundSwiftFee,
    },
  },
} as const;
