interface Options {
  sliceCount: number;
  prefix: string;
}

export function sliceString(
  value?: string,
  options: Options = { sliceCount: -4, prefix: '••' },
) {
  if (!value) return '';
  return `${options.prefix}${value?.slice(options.sliceCount)}`;
}
