import { type HttpRequestConfig, convertParams, http, httpPlain } from '@/api';

import type {
  Company,
  CompanyCreateRequest,
  CompanyEditableData,
  CompanyInviteCheckReadResponse,
  CompanyInviteReadResponse,
  CompanyInviteUpdateRequest,
  CompanyInviteUpdateResponse,
  CompanyKybForm,
  CompanyKybReadResponse,
  CompanyKyc,
  CompanyKycCreateRequest,
  CompanyKycReadRequest,
  CompanyKycSubmitResponse,
  CompanyKycUpdateRequest,
  CompanyPlan,
  CompanyTotalPreview,
  CompanyUser,
  CompanyUserEditableData,
  CompanyUserInviteCreateRequest,
  CompanyUsersReadRequest,
  CompanyUsersReadResponse,
  CompanyUsersTotalReadRequest,
  CoreTotalPeriodSpendResponse,
} from '@/interfaces';

const BASE_PATH = 'core/company';
const BASE_USER_PATH = `${BASE_PATH}/user`;
const BASE_KYC_PATH = 'kyc';

export const createCompany = (data: CompanyCreateRequest): Promise<Company> =>
  http.post(`${BASE_PATH}/`, data);

export const updateCompany = (data: CompanyEditableData): Promise<Company> =>
  http.patch(`${BASE_PATH}/`, data);

// COMPANY USER

export const readCompanyUsers = (
  params: CompanyUsersReadRequest,
  config?: HttpRequestConfig,
): Promise<CompanyUsersReadResponse> =>
  http.get(`${BASE_USER_PATH}/`, {
    params: convertParams(params, undefined, { name: 'readCompanyUsers' }),
    ...config,
  });

export const readCompanyUsersTotal = (
  params: CompanyUsersTotalReadRequest,
  config?: HttpRequestConfig,
): Promise<CoreTotalPeriodSpendResponse> => {
  return http.get(`${BASE_USER_PATH}/total/`, {
    params: convertParams(params, undefined, { name: 'readCompanyUsersTotal' }),
    ...config,
  });
};

export const readCompanyUser = (
  companyUserId: CompanyUser['id'],
): Promise<CompanyUser> => http.get(`${BASE_USER_PATH}/${companyUserId}/`);

export const updateCompanyUser = (
  companyUserId: CompanyUser['id'],
  data: CompanyUserEditableData,
): Promise<CompanyUser> =>
  http.patch(`${BASE_USER_PATH}/${companyUserId}/`, data);

export const deleteCompanyUser = (
  companyUserId: CompanyUser['id'],
): Promise<{ message: string }> =>
  http.delete(`${BASE_USER_PATH}/${companyUserId}/`);

// PLAN

export const readCompanyPlan = (): Promise<CompanyPlan> =>
  http.get(`${BASE_PATH}/plan/`);

// KYB/KYC

export const readCompanyBusinessInfo = (): Promise<CompanyKybReadResponse> =>
  http.get(`${BASE_KYC_PATH}/company_business_info/`);

export const updateCompanyBusinessInfo = (
  data: CompanyKybForm,
): Promise<CompanyKybReadResponse> =>
  http.patch(`${BASE_KYC_PATH}/company_business_info/`, data);

export const createCompanyUserQuestionnaire = (
  data: CompanyKycCreateRequest,
): Promise<CompanyKyc> =>
  http.post(`${BASE_KYC_PATH}/user_questionnaire/`, data);

export const readCompanyUserQuestionnaire = (
  params: CompanyKycReadRequest = {},
  config?: HttpRequestConfig,
): Promise<CompanyKyc[]> =>
  http.get(`${BASE_KYC_PATH}/user_questionnaire/`, {
    params: convertParams(params, undefined, {
      name: 'readCompanyUserQuestionnaire',
    }),
    ...config,
  });

export const updateCompanyUserQuestionnaire = (
  id: CompanyKyc['id'],
  data: CompanyKycUpdateRequest,
): Promise<CompanyKyc> =>
  http.patch(`${BASE_KYC_PATH}/user_questionnaire/${id}/`, data);

export const deleteCompanyUserQuestionnaire = (
  id: CompanyKyc['id'],
): Promise<{ message: string }> =>
  http.delete(`${BASE_KYC_PATH}/user_questionnaire/${id}/`);

export const createCompanyKycSubmit = (
  iovationBlackbox: string,
): Promise<CompanyKycSubmitResponse> =>
  http.post(`${BASE_PATH}/run_kyc/`, { iovationBlackbox });

// INVITE

export const createCompanyUserInvite = (
  data: CompanyUserInviteCreateRequest,
): Promise<CompanyUser> => http.post(`${BASE_USER_PATH}/invite/`, data);

export const createCompanyUserReinvite = (
  companyUserId: CompanyUser['id'],
): Promise<null> => http.post(`${BASE_USER_PATH}/${companyUserId}/reinvite/`);

export const readCompanyInvite = (
  token: string,
): Promise<CompanyInviteReadResponse> =>
  http.get(`${BASE_PATH}/invite/${token}/`);

export const updateCompanyInvite = (
  token: string,
  data: CompanyInviteUpdateRequest,
): Promise<CompanyInviteUpdateResponse> =>
  http.patch(`${BASE_PATH}/invite/${token}/`, data);

export const readCompanyInviteCheck = (
  token: string,
): Promise<CompanyInviteCheckReadResponse> =>
  httpPlain.get(`${BASE_PATH}/invite/${token}/check/`);

/**
 * Total preview company
 */

export const readCompaniesTotalPreview = (
  config?: HttpRequestConfig,
): Promise<CompanyTotalPreview[]> =>
  http.get(`${BASE_PATH}/total_preview/`, config);

/**
 * Company provider
 */

export const readCompanyProviderExternalKycLink = (
  id: number,
): Promise<{ link: string }> =>
  http.get(`karta/company_provider/${id}/external_kyc_link/`);
