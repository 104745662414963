export enum CounterpartyBusinessType {
  Individual = 'INDIVIDUAL',
  Business = 'BUSINESS',
}

export enum CounterpartyPaymentMethod {
  Card = 'CARD',
  Ach = 'ACH',
  Wire = 'WIRE',
  WireInternational = 'WIRE_INTERNATIONAL',
}

/**
 * @deprecated Убрать после правок бекенда
 */
export enum CounterpartyPaymentAccountType {
  Karta = 'KARTA',
  Bank = 'BANK',
}

export enum CounterpartyPaymentAccountTransferType {
  Ach = 'ACH',
  Fedwire = 'FEDWIRE',
  Sepa = 'SEPA',
  Karta = 'KARTA',
  Swift = 'SWIFT',
}

export enum CounterpartyCreateStep {
  Counterparty = 'COUNTERPARTY',
  PaymentAccount = 'PAYMENT_ACCOUNT',
}
