import { CounterpartyCreateStep } from '@/enums';
import type {
  BaseVerticalTab,
  Counterparty,
  CounterpartyPaymentAccount,
} from '@/interfaces';

interface State {
  counterparty?: Counterparty;
  counterpartyPaymentAccount?: Partial<CounterpartyPaymentAccount>;
  step: CounterpartyCreateStep;
  isLoading: boolean;
}

export const useCounterpartyCreateStore = defineStore(
  'counterpartyCreateStore',
  {
    state: (): State => ({
      counterparty: undefined,
      counterpartyPaymentAccount: undefined,
      step: CounterpartyCreateStep.Counterparty,
      isLoading: false,
    }),
    getters: {
      steps(state: State): BaseVerticalTab<CounterpartyCreateStep>[] {
        return [
          {
            value: CounterpartyCreateStep.Counterparty,
            label: 'Counterparty details',
            isDisabled: state.isLoading,
          },
          {
            value: CounterpartyCreateStep.PaymentAccount,
            label: 'Add payment account',
            isDisabled: Boolean(!state.counterparty) || state.isLoading,
          },
        ];
      },
    },
  },
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useCounterpartyCreateStore, import.meta.hot),
  );
}
