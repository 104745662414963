import { UiIconValue } from '@karta.io/ui-components';

import { NavigationGroup } from '@/enums';
import { CoreUserPermissionsMap } from '@/data';

import type { NavigationItem, Page } from '@/interfaces';

export const ONBOARDING_USER_DATA_PAGE: Page = {
  name: 'onboarding-user-data-page',
  metaInfo: {
    title: 'Get started',
  },
};

export const ONBOARDING_EMAIL_PAGE: Page = {
  name: 'onboarding-email-page',
  metaInfo: {
    title: 'Confirm email',
  },
};

export const ONBOARDING_CREATE_COMPANY_PAGE: Page = {
  name: 'onboarding-create-company-page',
  metaInfo: {
    title: 'Tell us more about your company',
  },
};

export const ONBOARDING_ACTIVATE_PROFILE_PAGE: Page = {
  name: 'onboarding-activate-profile-page',
  metaInfo: {
    title: 'Activate your profile',
  },
};

export const ONBOARDING_KYB_PAGE: Page = {
  name: 'onboarding-kyb-page',
  metaInfo: {
    title: 'Tell us more about your business',
  },
};

export const ONBOARDING_KYC_PAGE: Page = {
  name: 'onboarding-kyc-page',
  metaInfo: {
    title: 'Representative and owners',
  },
};

export const ONBOARDING_GET_STARTED_NAVIGATION: NavigationItem = {
  id: 'get-started',
  title: 'Get started',
  to: '/get-started',
  icon: UiIconValue.LightningBolt,
  permissions: [CoreUserPermissionsMap.ReadCompanyAccount],
  disabled: false,
  group: () => NavigationGroup.Active,
};

export const ONBOARDING_GET_STARTED_PAGE: Page = {
  name: 'onboarding-get-started-page',
  metaInfo: {
    title: 'Get started',
  },
  navigation: ONBOARDING_GET_STARTED_NAVIGATION,
};
