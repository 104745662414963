import { v4 as uuidv4 } from 'uuid';

import { CoreLocalStorage, NeuroIdSdkMethod } from '@/enums';
import { useEnv } from './index';

const { neuroIdScriptUrl, isProduction } = useEnv();

const SCRIPT_ID = 'neuro-id-script';

let isStarted = false;

export function useNeuroIdSdk() {
  const isReady = ref(false);
  const isDebug = useStorage<boolean>(CoreLocalStorage.Debug, null);

  const init = () => {
    if (!neuroIdScriptUrl) return;
    const scriptFromDOM = document.getElementById(SCRIPT_ID);

    if (scriptFromDOM) {
      isReady.value = true;
      return;
    }

    const script = document.createElement('script');
    script.async = true;
    script.src = neuroIdScriptUrl;
    script.id = SCRIPT_ID;

    window.nid =
      window.nid ||
      function () {
        // @ts-expect-error
        // eslint-disable-next-line prefer-rest-params
        (window.nid.q = window.nid.q || []).push(arguments);
      };

    window.nid(
      NeuroIdSdkMethod.SetDebug,
      isDebug.value !== null
        ? JSON.parse(String(isDebug.value))
        : !isProduction,
    );

    script.onload = () => {
      isReady.value = true;
    };

    document.head.appendChild(script);
  };

  const start = (pageName: string, uuid?: string): string | null => {
    if (typeof window.nid !== 'function' || !pageName || !isReady.value) {
      return null;
    }

    if (isStarted) {
      window.nid(NeuroIdSdkMethod.ChangePage, pageName);
      return null;
    }

    window.nid(NeuroIdSdkMethod.Start, pageName);

    const newUuid = uuid ?? uuidv4();
    window.nid(NeuroIdSdkMethod.Identify, newUuid);

    isStarted = true;

    return newUuid;
  };

  const submit = (userId: string): void => {
    if (typeof window.nid !== 'function' || !isReady.value) return;
    window.nid(NeuroIdSdkMethod.Submit, userId);
  };

  const closeSession = (): void => {
    if (typeof window.nid !== 'function' || !isReady.value) return;
    window.nid(NeuroIdSdkMethod.CloseSession);
  };

  init();

  return {
    isStarted,
    isReady,
    start,
    submit,
    closeSession,
  };
}
