import { httpStagingManagement } from '@/api';

import type {
  Staging,
  StagingReadRequest,
  StagingUpdateRequest,
} from '@/interfaces';

const BASE_URL = 'karta-staging/';

export const readStagingList = (
  query?: StagingReadRequest,
): Promise<Staging[]> =>
  httpStagingManagement.get(BASE_URL, {
    params: {
      ...query,
    },
  });

export const updateStaging = (
  id: Staging['id'],
  body: StagingUpdateRequest,
): Promise<Staging> => httpStagingManagement.patch(`${BASE_URL}${id}`, body);
