import { createRouter, createWebHistory } from 'vue-router';
import * as Sentry from '@sentry/vue';
import { StorageSerializers, useStorage } from '@vueuse/core';

// MODULES
import accountsRoutes from '@/modules/accounts/accounts.route';
import coreRoutes from '@/modules/core/core.route';
import companyAccountsRoutes from '@/modules/company-accounts/companyAccounts.route';
import externalAccountsRoute from '@/modules/external-accounts/externalAccounts.route';
import budgetsRoutes from '@/modules/budgets/budgets.route';
import companiesRoutes from '@/modules/companies/companies.route';
import cardsRoutes from '@/modules/cards/cards.route';
import onboardingRoutes from '@/modules/onboarding/onboarding.route';
import transactionsRoutes from '@/modules/transactions/transactions.route';
import counterpartiesRoutes from '@/modules/counterparties/counterparties.route';
import overviewRoutes from '@/modules/overview/overview.route';

import { useCompanyAccountsStore, useCoreStore } from '@/stores';
import { readCoreUser } from '@/api';
import { EnrichError } from '@/helpers';

import { CORE_FORBIDDEN_INDEX_PAGE } from '@/data';

import { CoreSessionStorage } from '@/enums';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    ...accountsRoutes,
    ...coreRoutes,
    ...companyAccountsRoutes,
    ...externalAccountsRoute,
    ...budgetsRoutes,
    ...cardsRoutes,
    ...transactionsRoutes,
    ...companiesRoutes,
    ...onboardingRoutes,
    ...counterpartiesRoutes,
    ...overviewRoutes,
  ],
});

router.beforeEach(async () => {
  const coreStore = useCoreStore();

  if (coreStore.isReadyForWork) {
    return;
  }

  try {
    const data = await readCoreUser();

    const { loadPrimaryCompanyAccounts } = useCompanyAccountsStore();
    loadPrimaryCompanyAccounts();
    coreStore.loadExchangeRates();
    coreStore.$patch({ ...data, orgId: 'auth0Id' });
  } catch (_error) {
    return { name: CORE_FORBIDDEN_INDEX_PAGE.name };
  } finally {
    coreStore.$patch({ isReadyForWork: true, isGlobalLoaderShow: false });
  }
});

/**
 * Объект errorsByRouter нужен для подсчета количества ошибок.
 * Это предотвратит бесконечную перезагрузку приложения в случае, если какой-то файл не доступен вообще
 */
const errorsByRouter = useStorage(
  CoreSessionStorage.ErrorsByRouter,
  null,
  sessionStorage,
  {
    serializer: StorageSerializers.object,
  },
);

/**
 * Обработка ошибок роутера при недоступности каких-то файлов. Доработанная версия
 * @see https://stackoverflow.com/questions/69300341
 */
router.onError((error, to) => {
  const errorMessage = error.message as string;

  const errorCounter = errorsByRouter.value?.[errorMessage]
    ? errorsByRouter.value[errorMessage] + 1
    : 1;

  errorsByRouter.value = {
    ...errorsByRouter.value,
    [errorMessage]: errorCounter,
  };

  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed') ||
    error.message.includes('Unable to preload CSS')
  ) {
    /**
     * @todo помониторить ошибки, если errorCounter будет равен только 1, то можно добавлять в игнор
     */
    Sentry.captureException(
      new EnrichError({
        message: errorMessage,
        errorCounter,
      }),
      {
        level: errorCounter > 1 ? 'error' : 'warning',
        tags: { customException: 'ErrorsByRouter' },
      },
    );

    if (errorCounter > 1) return;

    if (!to?.fullPath) {
      window.location.reload();
    } else {
      window.location.href = to.fullPath;
    }
  }
});

export default router;
