import type { SocketCmsListener } from '@/enums';
import type { CoreAppConfig } from '@/interfaces';
import { useEnv, useWebsocket } from './index';

const { cmsBaseUrl } = useEnv();

const websocket = useWebsocket({
  url: cmsBaseUrl,
  loggerName: 'cms',
});

export function useWebsocketCms() {
  websocket.init();

  const on = (
    listener: SocketCmsListener,
    callback: (message: CoreAppConfig['data']) => void,
  ) => {
    websocket.on(listener, callback);
  };

  const off = (
    listener: SocketCmsListener,
    callback: (message: CoreAppConfig['data']) => void,
  ) => {
    websocket.off(listener, callback);
  };

  return {
    on,
    off,
    disconnect: websocket.disconnect,
  };
}
