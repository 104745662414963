import { useCoreStore } from '@/stores';
import type { CoreUserPermissionKey, PermissionAction } from '@/enums';

export function useCorePermissions() {
  const coreStore = useCoreStore();

  const can = (permissionMap: {
    key: CoreUserPermissionKey;
    action: PermissionAction;
  }): boolean => {
    if (!coreStore.permissions) return false;

    const userPermission = coreStore.permissions?.[permissionMap.key];
    const { action } = permissionMap;

    if (!userPermission) return false;
    if (!(action in userPermission)) return false;

    return userPermission[action as keyof typeof userPermission];
  };

  return { can };
}
