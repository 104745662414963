import {
  StagingManagementWsClientEvent,
  StagingManagementWsServerEvent,
} from '@/enums';
import { useEnv, useWebsocket } from '@/composables';
import type {
  StagingWsClientToServerEvents,
  StagingWsServerToClientEvents,
} from '@/interfaces';

const {
  stagingManagementWsUrl,
  stagingManagementUsername,
  stagingManagementPassword,
} = useEnv();

const websocket = useWebsocket<
  StagingWsServerToClientEvents,
  StagingWsClientToServerEvents
>({
  url: stagingManagementWsUrl,
  loggerName: 'core-websocket-staging-management',
});

export function useStagingManagementWebsocket() {
  /**
   * @description вызывается один раз в приложении
   */
  const createConnection = () => {
    const isConnected = ref(false);
    const isAuthorized = ref(false);

    websocket.init();

    websocket.on('connect', () => {
      isConnected.value = true;
    });
    websocket.on('disconnect', () => {
      isConnected.value = false;
      isAuthorized.value = false;
    });
    websocket.on(StagingManagementWsServerEvent.AuthLoginSuccess, () => {
      isAuthorized.value = true;
      websocket.logger.log(
        'received',
        StagingManagementWsServerEvent.AuthLoginSuccess,
      );
    });
    websocket.on(
      StagingManagementWsServerEvent.AuthLoginFailed,
      (error: any) => {
        isAuthorized.value = false;
        websocket.logger.error(
          'received',
          StagingManagementWsServerEvent.AuthLoginFailed,
          error,
        );
      },
    );

    watch(
      [isConnected, isAuthorized],
      ([isConnectedValue, isAuthorizedValue]) => {
        if (!isConnectedValue || isAuthorizedValue) return;

        try {
          websocket.emit(StagingManagementWsClientEvent.AuthLogin, {
            username: stagingManagementUsername,
            password: stagingManagementPassword,
          });
        } catch (error) {
          websocket.logger.error('getTokenSilently', error);
        }
      },
      { immediate: true },
    );
  };

  return {
    on: websocket.on,
    off: websocket.off,
    emit: websocket.emit,
    createConnection,
    disconnect: websocket.disconnect,
    logger: websocket.logger,
  };
}
