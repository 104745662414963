<script setup lang="ts">
import {
  UiDropdownPlacement,
  UiIconValue,
  UiLinkTheme,
  UiTypography,
} from '@karta.io/ui-components';

interface Props {
  url: string;
}

defineOptions({ name: 'StagingManagementBannerPopoverContent' });

const props = withDefaults(defineProps<Props>(), {
  url: '',
});

const cn = useClassName('staging-management-banner-popover-content');
</script>

<template>
  <div :class="cn.b()">
    <div v-if="props.url" :class="cn.e('row')">
      <div :class="cn.e('label')">Url:</div>
      <div :class="cn.e('value')">
        <AppText
          :typography="UiTypography.TextSmRegular"
          canCopy
          tooltipTextSuccess="Custom url copied!"
          :tooltipPlacement="UiDropdownPlacement.Bottom"
        >
          {{ props.url }}
        </AppText>
      </div>
    </div>

    <div v-if="props.url" :class="cn.e('row')">
      <div :class="cn.e('label')">Links:</div>
      <div :class="cn.e('value')">
        <UiLink
          :theme="UiLinkTheme.PrimaryLink"
          :href="`${props.url}/swagger?urls.primaryName=schema-app-internal`"
          :suffixIcon="UiIconValue.ExternalLink"
        >
          Swagger
        </UiLink>

        <UiLink
          :theme="UiLinkTheme.SecondaryLink"
          :href="`${props.url}/admin`"
          :suffixIcon="UiIconValue.ExternalLink"
        >
          Admin
        </UiLink>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.staging-management-banner-popover-content {
  &__row:not(:last-child) {
    margin-bottom: var(--spacing-02);
  }

  &__label {
    margin-bottom: 2px;
    @include typography('text-sm-semibold');
  }

  &__value {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-04);
  }
}

@include breakpoint('xs') {
  .staging-management-banner-popover-content {
    &__row {
      display: flex;
    }

    &__label {
      width: 80px;
    }
  }
}
</style>
