/**
 * @todo скорее всего эти события нужно перенести в модуль транзакций
 */
export enum MoveMoneyEvent {
  TransferCreated = 'create:transfer',
  SendFundsCreated = 'create:sendFunds',
}

export enum MoveMoneyInternalTransferStep {
  SetData = 'SET_DATA',
  Review = 'REVIEW',
}

export enum MoveMoneySendFundsStep {
  Details = 'DETAILS',
  Amount = 'AMOUNT',
  Review = 'REVIEW',
}
