import { UiIconValue } from '@karta.io/ui-components';

import { CoreUserPermissionsMap } from '@/data';
import { NavigationGroup } from '@/enums';

import type { NavigationItem, Page } from '@/interfaces';

export const BUDGETS_NAVIGATION: NavigationItem = {
  id: 'budgets',
  title: 'Budgets',
  to: '/budgets',
  icon: UiIconValue.Collection,
  permissions: [CoreUserPermissionsMap.ReadBudget],
  disabled: false,
  group: (hasCompanyAccounts, hasExternalAccounts, isCompanyAccountFunded) =>
    hasCompanyAccounts && isCompanyAccountFunded
      ? NavigationGroup.Active
      : NavigationGroup.Inactive,
};

export const BUDGETS_INDEX_PAGE: Page = {
  name: 'budgets-index-page',
  metaInfo: {
    title: 'Budgets',
  },
  navigation: BUDGETS_NAVIGATION,
};

export const BUDGET_CARDS_PAGE: Page = {
  name: 'budget-cards-page',
  metaInfo: {
    title: 'Budget virtual cards',
  },
  navigation: BUDGETS_NAVIGATION,
};

export const BUDGET_TRANSACTIONS_PAGE: Page = {
  name: 'budget-transactions-page',
  metaInfo: {
    title: 'Budget transactions',
  },
  navigation: BUDGETS_NAVIGATION,
};

export const BUDGET_MEMBERS_PAGE: Page = {
  name: 'budget-members-page',
  metaInfo: {
    title: 'Budget members',
  },
  navigation: BUDGETS_NAVIGATION,
};

export const BUDGET_INDEX_PAGE: Page = {
  name: 'budget-index-page',
  metaInfo: {
    title: 'Budget',
  },
  navigation: BUDGETS_NAVIGATION,
  tabs: [
    {
      label: 'Transactions',
      value: BUDGET_TRANSACTIONS_PAGE.name,
      disabled: false,
    },
    {
      label: 'Virtual cards',
      value: BUDGET_CARDS_PAGE.name,
      disabled: false,
    },
    {
      label: 'Members',
      value: BUDGET_MEMBERS_PAGE.name,
      disabled: false,
    },
  ],
};
