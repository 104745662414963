export enum CardType {
  Virtual = 'VIRTUAL',
  Physical = 'PHYSICAL',
}

export enum CardLimitType {
  NoLimit = 'NO_LIMIT',
  Daily = 'DAILY_LIMIT',
  Weekly = 'WEEKLY_LIMIT',
  Monthly = 'MONTHLY_LIMIT',
  Year = 'YEAR_LIMIT',
  Lifetime = 'LIFETIME_LIMIT',
  Transaction = 'TRANSACTION_LIMIT',
}

export enum CardStatus {
  Active = 'ACTIVE',
  Locked = 'PAUSED',
  Closed = 'CLOSED',
  Pending = 'PENDING',
}

export enum CardBrand {
  Mastercard = 'MASTERCARD',
  Visa = 'VISA',
}

export enum CardEvent {
  CardCreated = 'create:card',
  CardUpdated = 'update:card',
}

export enum CardIssuingReason {
  BudgetLimitExceeded = 'BUDGET_LIMIT_EXCEEDED',
  BudgetUserLimitExceeded = 'BUDGET_USER_LIMIT_EXCEEDED',
  CompanyUserLimitExceeded = 'COMPANY_USER_LIMIT_EXCEEDED',
  ActiveCardLimitReached = 'ACTIVE_CARD_LIMIT_REACHED',
  InsufficientBalance = 'INSUFFICIENT_BALANCE',
}
