import { UiIconValue } from '@karta.io/ui-components';

import { NavigationGroup } from '@/enums';

import { CoreUserPermissionsMap } from '@/data';
import type { NavigationItem, Page } from '@/interfaces';

export const COMPANY_USERS_NAVIGATION: NavigationItem = {
  id: 'users',
  title: 'Team',
  to: '/team',
  icon: UiIconValue.Users,
  // role: 'Manager',
  permissions: [CoreUserPermissionsMap.ReadCompanyUser],
  disabled: false,
  group: () => NavigationGroup.Active,
};

export const COMPANY_USERS_INDEX_PAGE: Page = {
  name: 'company-users-index-page',
  metaInfo: {
    title: 'Team',
  },
  navigation: COMPANY_USERS_NAVIGATION,
};
