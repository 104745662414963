import { camelCase, omit } from 'lodash-es';
import qs from 'qs';

import type { RouteLocationNormalized } from 'vue-router';

import { useLogger } from '@karta.io/utils';

import { useCoreStore } from '@/stores';
import { useCoreUiSettings } from '@/composables';

interface SaveQueryParams {
  name?: string;
  id?: string | number;
  query: Record<string, any>;
  excluded?: string[];
}

const DEFAULT_EXCLUDED_KEYS = [
  'page',
  'referrerFromName',
  'returnToName',
  'returnToUrl',
  'period',
  'startDate',
  'endDate',
];

export function useSaveQuery(routeName: string) {
  const logger = useLogger('use-save-query', {
    tag: routeName,
    color: '#F5A400',
  });

  /**
   * @description Проверяет условия и подставляет сохраненную кверю
   * в случае успеха. Используется в Per-Route Guard beforeEnter
   */
  const setQuery = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ) => {
    const name = to.name as string;
    const query = to.query as Record<string, any>;
    const id = (to.params?.id ?? 0) as string | number;

    const coreStore = useCoreStore();

    const nameCamelized = camelCase(name);
    const savedValue = coreStore.uiSettings?.filters?.[nameCamelized]?.[id];

    if (savedValue && !Object.keys(query).length && from.name !== name) {
      logger.log('*setQuery()*', '=>', savedValue);

      if (to.hash) return `${to.path}?${savedValue}${to.hash}`;

      return `${to.path}?${savedValue}`;
    }
  };

  /**
   * @description Функция сохранения квери.
   * Используется в watch или там где необходимо сохранить
   */
  const saveQuery = ({
    name = routeName,
    id = 0,
    query,
    excluded = DEFAULT_EXCLUDED_KEYS,
  }: SaveQueryParams) => {
    const { updatePageFilters } = useCoreUiSettings();
    const valueForSave = omit(query, excluded);

    logger.log('*saveQuery()*', '=>', valueForSave);

    updatePageFilters({
      name: camelCase(name),
      id,
      value: qs.stringify(valueForSave),
    });
  };

  return {
    setQuery,
    saveQuery,
  };
}
