import { permissionsGuard } from '@/router/guards';
import { useSaveQuery } from '@/composables';
import { COUNTERPARTIES_INDEX_PAGE, CoreUserPermissionsMap } from '@/data';

const { setQuery } = useSaveQuery(COUNTERPARTIES_INDEX_PAGE.name);

export default [
  {
    path: '/counterparties',
    name: COUNTERPARTIES_INDEX_PAGE.name,
    component: () =>
      import('@/modules/counterparties/pages/CounterpartiesIndexPage.vue'),
    beforeEnter: [permissionsGuard, setQuery],
    meta: {
      auth: true,
      navigation: COUNTERPARTIES_INDEX_PAGE.navigation,
      permissions: [CoreUserPermissionsMap.ReadCounterparty],
    },
  },
];
