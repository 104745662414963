import type { Page } from '@/interfaces';

export const SETTINGS_PERSONAL_PAGE: Page = {
  name: 'settings-personal-page',
  metaInfo: {
    title: 'Personal settings',
  },
};

export const SETTINGS_COMPANY_PAGE: Page = {
  name: 'settings-company-page',
  metaInfo: {
    title: 'Company Settings',
  },
};

export const SETTINGS_BILLING_PAGE: Page = {
  name: 'settings-billing-page',
  metaInfo: {
    title: 'Billing',
  },
};

export const SETTINGS_TOKENS_PAGE: Page = {
  name: 'settings-tokens-page',
  metaInfo: {
    title: 'API tokens',
  },
};

export const SETTINGS_INDEX_PAGE: Page = {
  name: 'settings-index-page',
  metaInfo: {
    title: 'Settings',
  },
};
