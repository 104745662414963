<script lang="ts" setup>
import { UiTypography } from '@karta.io/ui-components';

interface Props {
  align?: 'left' | 'right' | 'center';
  value?: any;
  defaultValue?: any;
  typography?: UiTypography;
}

defineOptions({ name: 'TableCellDefault' });

const props = withDefaults(defineProps<Props>(), {
  align: 'left',
  value: '',
  typography: UiTypography.TextSmRegular,
});

const cn = useClassName('table-cell-default');
</script>

<template>
  <div :class="[cn.b(), cn.mv('align', props.align)]">
    <div :class="cn.e('container')">
      <AppText
        :typography="props.typography"
        textEllipsis="100%"
        :textAlign="props.align"
      >
        {{ value || defaultValue }}
      </AppText>
    </div>
  </div>
</template>

<style lang="scss">
.table-cell-default {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;

  &__container {
    width: 100%;
  }

  &_align_left {
    justify-content: flex-start;
  }

  &_align_right {
    justify-content: flex-end;
  }

  &_align_center {
    justify-content: center;
  }
}
</style>
