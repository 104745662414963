<script lang="ts" setup>
/**
 * @todo выпилить
 */
import { UiTypography } from '@karta.io/ui-components';

defineOptions({ name: 'PanelTableSkeleton' });

const cn = useClassName('panel-table-skeleton');
</script>

<template>
  <div :class="cn.b()">
    <div :class="cn.e('header')">
      <div :class="cn.e('header-cell')">
        <UiSkeletonText width="50%" :typography="UiTypography.Text2xsRegular" />
      </div>

      <div :class="[cn.e('cell'), cn.e('cell_last')]">
        <UiSkeletonText width="40%" :typography="UiTypography.Text2xsRegular" />
      </div>
    </div>

    <div :class="cn.e('body')">
      <div
        v-for="item in 5"
        :key="`panel-table-skeleton-row-${item}`"
        :class="cn.e('row')"
      >
        <div :class="cn.e('cell')">
          <UiSkeletonText
            width="80%"
            :typography="UiTypography.TextXlRegular"
          />
        </div>

        <div :class="[cn.e('cell'), cn.e('cell_last')]">
          <UiSkeletonText
            width="60%"
            :typography="UiTypography.TextXlRegular"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.panel-table-skeleton {
  width: 100%;

  &__header {
    display: grid;
    grid-template-columns: 70% 30%;
    align-items: center;
    height: 44px;
    padding: 0 var(--spacing-04);
    border-top: 1px solid var(--color-secondary-200);
    border-bottom: 1px solid var(--color-secondary-200);
  }

  &__row {
    display: grid;
    grid-template-columns: 70% 30%;
    align-items: center;
    height: 72px;
    margin-top: -1px;
    padding: 0 var(--spacing-04);
    border-top: 1px solid var(--color-secondary-200);
    border-bottom: 1px solid var(--color-secondary-200);
  }

  &__cell {
    &_last {
      display: flex;
      justify-content: end;
    }
  }
}

@include breakpoint('sm') {
  .panel-table-skeleton {
    &__header,
    &__row {
      padding: 0 var(--spacing-08);
    }
  }
}
</style>
