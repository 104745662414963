import { UiColor } from '@karta.io/ui-components';

import {
  CardIssuingReason,
  CardLimitType,
  CardStatus,
  Currency,
  FilterType,
} from '@/enums';
import type { CardsFilters, FiltersConfig } from '@/interfaces';

export const CardLimitsMap = {
  [CardLimitType.NoLimit]: {
    title: 'No limit',
    titleShort: 'No limit',
    description: 'The current virtual card will have no limit.',
    filterOrder: 0,
  },
  [CardLimitType.Daily]: {
    title: 'Daily limit',
    titleShort: 'Daily',
    description: 'Limit applies to a day, starting at midnight.',
    filterOrder: 2,
  },
  [CardLimitType.Weekly]: {
    title: 'Weekly limit',
    titleShort: 'Weekly',
    description: 'Limit applies to a week, starting on Sunday midnight.',
    filterOrder: 3,
  },
  [CardLimitType.Monthly]: {
    title: 'Monthly limit',
    titleShort: 'Monthly',
    description: 'Limit applies to a month, starting on 1st.',
    filterOrder: 4,
  },
  [CardLimitType.Year]: {
    title: 'Yearly limit',
    titleShort: 'Yearly',
    description: 'Limit applies to a year, starting on January 1st.',
    filterOrder: 5,
  },
  [CardLimitType.Lifetime]: {
    title: 'Lifetime limit',
    titleShort: 'Lifetime',
    description: 'Limit applies to all transactions.',
    filterOrder: 6,
  },
  [CardLimitType.Transaction]: {
    title: 'Transaction limit',
    titleShort: 'Per transaction',
    description: 'Limit applies to each transaction.',
    filterOrder: 1,
  },
} as const;

export const CardStatusesMap = {
  [CardStatus.Active]: {
    tagLabel: 'Active',
    tagColor: UiColor.Success,
    credentialsTooltipText: 'Click to reveal',
  },
  [CardStatus.Locked]: {
    tagLabel: 'Locked',
    tagColor: UiColor.Primary,
    credentialsTooltipText: 'Virtual card locked',
  },
  [CardStatus.Closed]: {
    tagLabel: 'Closed',
    tagColor: UiColor.Error,
    credentialsTooltipText: 'Virtual card closed',
  },
  [CardStatus.Pending]: {
    tagLabel: 'Pending',
    tagColor: UiColor.Secondary,
    credentialsTooltipText:
      'The virtual card is being created. It may take a while.',
  },
} as const;

export const CardsFiltersConfig: FiltersConfig<CardsFilters> = {
  companyAccountIds: {
    type: FilterType.Array,
  },
  userIds: {
    type: FilterType.Array,
  },
  budgetIds: {
    type: FilterType.Array,
  },
  statuses: {
    type: FilterType.Array,
    possibleValues: Object.values(CardStatus),
  },
  limitTypes: {
    type: FilterType.Array,
    possibleValues: Object.values(CardLimitType),
  },
  currencies: {
    type: FilterType.Array,
    possibleValues: Object.values(Currency),
  },
  periodName: {
    type: FilterType.String,
  },
  periodDates: {
    type: FilterType.Array,
  },
} as const;

export const CardIssuingReasonsMap = {
  [CardIssuingReason.BudgetLimitExceeded]: {
    title: 'Budget lacks funds',
    description:
      'The budget limit has been reached. To create a virtual card, contact the budget manager for a budget limit increase.',
    errorMessage:
      'The budget needs more funds. Please request it from the budget manager.',
  },
  [CardIssuingReason.BudgetUserLimitExceeded]: {
    title: 'Your personal budget limit has been reached',
    description:
      'To create a virtual card, please contact the budget manager for your personal limit increase.',
    errorMessage:
      'You’ve reached the budget limit. Please contact the budget manager for increase.',
  },
  [CardIssuingReason.CompanyUserLimitExceeded]: {
    title: 'Your company limit has been reached',
    description:
      'To create a virtual card, please contact the admin for a limit increase.',
    errorMessage:
      'You’ve reached the company limit. Please contact admin for increase.',
  },
  [CardIssuingReason.ActiveCardLimitReached]: {
    title: 'Too many virtual cards',
    description:
      'You’ve reached your limit of active virtual cards. To add more, try closing some of the virtual cards or contact support for an upgrade.',
    errorMessage:
      'Too many active virtual cards. Please close some or contact support to add more.',
  },
  [CardIssuingReason.InsufficientBalance]: {
    title: 'Not enough money',
    description:
      'Your account balance is too low to create a virtual card. Please top up and try again.',
    errorMessage: 'Not enough funds. Please top up the source account.',
  },
  default: {
    title: 'Something went wrong',
    description:
      'Sorry, we can’t create a virtual card for you right now. Please try again later.',
    errorMessage: 'Sorry, we can’t create a virtual card right now',
  },
} as const;

export const CardCredentialsValueStylesDesktop = {
  background: 'transparent',
  color: '#101828',
  'font-family': 'Courier New, monaco, monospace',
  'font-size': '18px',
  'font-weight': '700',
  'line-height': '28px',
  'letter-spacing': 'normal',
};

export const CardCredentialsValueStylesMobile = {
  background: 'transparent',
  color: '#101828',
  'font-family': 'Courier New, monaco, monospace',
  'font-size': '14px',
  'font-weight': '700',
  'line-height': '20px',
  'letter-spacing': 'normal',
};

export const CardCredentialsTooltipText = {
  serverError: `Something went wrong... <br /> You cannot view the credentials for this virtual card`,
  readyToShow: 'Click to reveal',
  notOwner: 'Virtual card details are only available to the cardholder',
  noPermissions: `You do not have permission <br /> to view the credentials of this virtual card`,
  bannedCountry: 'This feature is not available in your region',
};
