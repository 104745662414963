<script setup lang="ts">
import {
  UiButtonSize,
  UiButtonTheme,
  UiColor,
  UiColorVar,
  UiDropdownPlacement,
  UiIconValue,
  UiTypography,
} from '@karta.io/ui-components';

import { CoreLocalStorage } from '@/enums';

defineOptions({ name: 'CoreBannedCountryBanner' });

const cn = useClassName('core-banned-country-banner');
const { isMobile } = useBreakpoint();

const isBannedCountryBannerHidden = useStorage<boolean>(
  CoreLocalStorage.IsBannedCountryBannerHidden,
  false,
);

const tooltipTarget = ref<HTMLDivElement>();

const handleClick = () => {
  isBannedCountryBannerHidden.value = true;
};
</script>

<template>
  <Banner :color="UiColor.Secondary">
    <div :class="cn.b()">
      <div ref="tooltipTarget">
        <AppText
          :typography="UiTypography.TextSmMedium"
          :color="UiColorVar.BaseWhite"
          textAlign="center"
        >
          <UiIcon :value="UiIconValue.Exclamation" size="18px" />

          {{
            isMobile
              ? 'Regional restrictions'
              : 'Some features may not be available in your region'
          }}
        </AppText>
      </div>

      <div :class="cn.e('close-button')">
        <UiButton
          :size="UiButtonSize.Sm"
          :theme="UiButtonTheme.PrimaryLink"
          :iconOnly="UiIconValue.X"
          @click="handleClick"
        />
      </div>
    </div>

    <UiTooltip
      mainText="Please enable a VPN and try again"
      :target="tooltipTarget"
      :placement="UiDropdownPlacement.Bottom"
      :zIndex="9000"
      isBodyAppended
    />
  </Banner>
</template>

<style lang="scss">
.core-banned-country-banner {
  position: relative;

  &__close-button {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-6px) translateX(6px);
  }
}

@include breakpoint('sm') {
  .core-banned-country-banner {
    &__close-button {
      transform: translateY(-6px) translateX(12px);
    }
  }
}

@include breakpoint('md') {
  .core-banned-country-banner {
    &__close-button {
      transform: translateY(-6px) translateX(24px);
    }
  }
}
</style>
