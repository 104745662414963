import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';

import { useEnv } from '@/composables';
import { readStagingList } from '@/api';
import { CoreLocalStorage } from '@/enums';
import type { Staging, StagingClickup } from '@/interfaces';

export function useStagingManagement() {
  const router = useRouter();

  const hash = '#staging-management';

  const { apiBaseUrl, isApp, isProduction, isDemo } = useEnv();
  const { closeAll } = useDialog();
  const baseUrlStore = useStorage<string>(CoreLocalStorage.ApiBaseUrl, '');

  const search = ref('');
  const updateSearch = useDebounceFn((value: string) => {
    search.value = value;
  }, 250);

  const isButtonShown = computed(() => !isProduction && isApp);
  const isUrlCustom = computed(
    () =>
      isButtonShown.value &&
      baseUrlStore.value &&
      baseUrlStore.value !== import.meta.env.API_BASE_URL,
  );

  const openModal = () => router.push({ hash });
  const closeModal = () => closeAll();

  const queryKey = ['apiStagingList', search];
  const { isLoading, isFetching, isError, data, error } = useQuery({
    queryKey,
    queryFn: () => readStagingList({ search: search.value }),
    enabled: !isDemo && !isProduction,
  });

  const queryClient = useQueryClient();

  const { mutate: updateStaging } = useMutation({
    /**
     * @todo поправить типы
     */
    mutationFn: (data: Staging) => {
      const currentQuery = queryClient.getQueryData<Staging[]>(queryKey);
      const newQuery = queryClient.setQueryData(
        queryKey,
        currentQuery?.map(item => {
          if (data.id !== item.id) return item;

          return {
            ...item,
            ...data,
          };
        }),
      );

      return newQuery;
    },
  });

  const { mutate: updateStagingClickup } = useMutation({
    /**
     * @todo поправить типы
     */
    mutationFn: (data: StagingClickup) => {
      const currentQuery = queryClient.getQueryData<Staging[]>(queryKey);
      const newQuery = queryClient.setQueryData(
        queryKey,
        currentQuery?.map(item => {
          if (data.clickupCustomId !== item.clickupCustomId) return item;

          return {
            ...item,
            clickup: data,
          };
        }),
      );

      return newQuery;
    },
  });

  const refetch = () => queryClient.invalidateQueries({ queryKey });
  const reload = () => queryClient.resetQueries({ queryKey });

  const urls = computed(() => {
    if (!Array.isArray(data.value)) return [];
    if (!data.value?.length) return [];

    return data.value
      .map(item => ({
        ...item,
        isActive: item.host === apiBaseUrl,
      }))
      .sort((a, b) => Number(b.isActive) - Number(a.isActive));
  });

  const isUrlCustomExist = computed(() =>
    urls.value.some(item => item.host === baseUrlStore.value),
  );

  return {
    hash,
    baseUrlStore,

    isButtonShown,
    isUrlCustom,

    openModal,
    closeModal,

    /**
     * Request
     */
    urls,
    isUrlCustomExist,
    error,

    isLoading,
    isFetching,
    isError,

    refetch,
    reload,
    updateSearch,
    updateStaging,
    updateStagingClickup,
  };
}
