import { permissionsGuard } from '@/router/guards';
import { useEnv, useSaveQuery } from '@/composables';
import { COMPANY_INVITE_INDEX_PAGE, COMPANY_USERS_INDEX_PAGE } from './data';

const { setQuery } = useSaveQuery(COMPANY_USERS_INDEX_PAGE.name);
const { isApp } = useEnv();

export default [
  {
    path: '/team',
    name: COMPANY_USERS_INDEX_PAGE.name,
    component: () =>
      import('@/modules/companies/pages/CompanyUsersIndexPage.vue'),
    beforeEnter: [permissionsGuard, setQuery],
    meta: {
      auth: true,
      navigation: COMPANY_USERS_INDEX_PAGE.navigation,
      permissions: COMPANY_USERS_INDEX_PAGE.navigation?.permissions,
    },
  },
  ...(isApp
    ? [
        {
          path: '/invite',
          name: COMPANY_INVITE_INDEX_PAGE.name,
          component: () =>
            import('@/modules/companies/pages/CompanyInviteIndexPage.vue'),
          meta: {
            auth: false,
            layout: 'empty',
          },
        },
      ]
    : []),
];
