export enum ExternalAccountStatus {
  Waiting = 'WAITING',
  Connected = 'CONNECTED',
  Disconnected = 'DISCONNECTED',
}

export enum ExternalAccountType {
  Credit = 'CREDIT',
  Depository = 'DEPOSITORY',
  Loan = 'LOAN',
  Other = 'OTHER',
}

export enum ExternalAccountEvent {
  ExternalAccountCreated = 'create:externalAccount',
}
