import { compare, validate } from 'compare-versions';
import * as Sentry from '@sentry/vue';

import { readCoreAppVersion } from '@/api';
import { useCommonStore } from '@/stores';
import { EnrichError } from '@/helpers';

export function useCoreAppVersion() {
  const commonStore = useCommonStore();

  const loadAppVersion = async (): Promise<boolean> => {
    try {
      const { version } = await readCoreAppVersion();

      if (!validate(version)) {
        Sentry.captureException(
          new EnrichError({
            message: 'Version is not valid',
            version,
          }),
          { level: 'error', tags: { customException: 'AppVersion' } },
        );
        return true;
      }

      return compare(commonStore.appVersion, version, '>=');
    } catch (error: any) {
      console.error(error);

      Sentry.captureException(
        {
          message: error.message,
          status: error.status,
          statusMessage: error.statusMessage,
        },
        { level: 'error' },
      );
      return true;
    }
  };

  return {
    loadAppVersion,
  };
}
