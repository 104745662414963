<script lang="ts" setup>
import { UiAvatarSize, UiTypography } from '@karta.io/ui-components';

defineOptions({ name: 'CompanyAccountSelectOptionSkeleton' });

const cn = useClassName('company-account-select-option-skeleton');
</script>

<template>
  <div :class="cn.b()">
    <UiSkeletonAvatar :size="UiAvatarSize.Sm" />

    <div>
      <UiSkeletonText :typography="UiTypography.TextSmMedium" width="60%" />
      <UiSkeletonText :typography="UiTypography.TextXsMedium" width="40%" />
    </div>
  </div>
</template>

<style lang="scss">
.company-account-select-option-skeleton {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: var(--spacing-03);
  padding: var(--spacing-02);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-secondary-100);
}
</style>
