import alloy, { type Init } from '@alloyidentity/web-sdk';

import { useEnv } from './useEnv';

interface UseAlloySdkOptions {
  journeyApplicationToken: Init['journeyApplicationToken'];
  entityToken?: Init['entityToken'];
}

const { alloyJourneyToken, alloySdkKey, isProduction } = useEnv();

export function useAlloySdk() {
  const init = async (options: UseAlloySdkOptions) => {
    if (
      !alloyJourneyToken ||
      !alloySdkKey ||
      !options.journeyApplicationToken
    ) {
      return Promise.reject(new Error('Invalid initial key'));
    }

    await alloy.init({
      ...options,
      key: alloySdkKey,
      journeyToken: alloyJourneyToken,
      production: isProduction,
      isSingleEntity: Boolean(options.entityToken),
      customStyle: {
        theme: {
          primaryColor: '#0f62fe',
          backgroundColor: '#ffffff',
          textColor: '#101828',
          borderRadius: '8px',
          iconColor: '#101828',
          successColor: '#12b76a',
          errorColor: '#f04438',
        },
        componentOverride: {
          PrimaryButton: {
            default: {
              backgroundColor: '#0f62fe',
              border: '1px solid #0f62fe',
              textColor: '#ffffff',
              borderRadius: '8px',
            },
            hover: {
              backgroundColor: '#0150e3',
              border: '1px solid #0f62fe',
              textColor: '#ffffff',
              borderRadius: '8px',
            },
            focus: {
              backgroundColor: '#0150e3',
              border: '1px solid #0150e3',
              textColor: '#ffffff',
              borderRadius: '8px',
            },
          },
          ExitButton: {
            default: {
              backgroundColor: '#0f62fe',
              border: '1px solid #0f62fe',
              textColor: '#ffffff',
              borderRadius: '8px',
            },
            hover: {
              backgroundColor: '#0150e3',
              border: '1px solid #0f62fe',
              textColor: '#ffffff',
              borderRadius: '8px',
            },
            focus: {
              backgroundColor: '#0150e3',
              border: '1px solid #0150e3',
              textColor: '#ffffff',
              borderRadius: '8px',
            },
          },
          HelpBanner: {
            default: {
              backgroundColor: '#ffffff',
              border: 'none',
              textColor: '#101828',
            },
          },
          PhoneInput: {
            default: {
              backgroundColor: '#f9fafb',
              border: '1px solid #d0d5dd',
              textColor: '#101828',
              borderRadius: '8px',
            },
          },
        },
      },
    });
  };

  const open = (callback: (data: any) => void = () => undefined) =>
    alloy.open(callback);

  return {
    init,
    open,
  };
}
