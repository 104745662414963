import Axios, {
  type AxiosError,
  type AxiosResponse,
  type InternalAxiosRequestConfig,
} from 'axios';
import type { ResponseError } from '@karta.io/utils';

import { auth0 } from '@/modules/auth/auth.service';
import { snakelizeKeys } from '@/helpers';
import { useEnv } from '@/composables';
import router from '@/router';
import {
  CORE_COMPANY_BLOCKED_INDEX_PAGE,
  CORE_COMPANY_USER_BLOCKED_INDEX_PAGE,
  CORE_COMPANY_USER_DELETED_INDEX_PAGE,
  CORE_COMPANY_WAITING_DOCUMENTS_INDEX_PAGE,
  CORE_ORGANIZATION_SELECTION_PAGE,
  CORE_USER_BLOCKED_INDEX_PAGE,
} from '@/data';

import { CoreForbiddenReason, CoreLocalStorage } from '@/enums';
import type { CoreLocalStorageUserStore } from '@/interfaces';

const { isDemo } = useEnv();

function getStatusMessage(status: number): string {
  let message = '';

  switch (status) {
    case 200:
      message = 'All done. Request successfully executed';
      break;

    case 201:
      message = 'Data successfully created';
      break;

    case 400:
      message = 'Bad Request';
      break;

    case 401:
      message = 'Need auth';
      break;

    case 404:
      message = 'Not found';
      break;

    case 500:
      message = 'Something wrong. Internal server error';
      break;

    case 503:
      message = 'Service unavailable. Try again later';
      break;

    default:
      message = 'Something wrong. Client default error message';
      break;
  }
  return message;
}

function getResponseErrorMessage(error: any): string {
  const { response } = error;

  if (response && response.data && response.data.error) {
    return response.data.error;
  }
  if (response && response.data && response.data.detail) {
    return response.data.detail;
  }

  return error.message === 'Network Error'
    ? 'Oops! Network Error. Try again later'
    : `${error.name}: ${error.message}`;
}

export async function requestInterceptor(config: InternalAxiosRequestConfig) {
  const userLocalStore = useStorage<CoreLocalStorageUserStore>(
    CoreLocalStorage.UserStore,
    {},
  );

  try {
    if (config.params) {
      config.params = snakelizeKeys(config.params);
    }

    if (config.data) {
      config.data = snakelizeKeys(config.data);
    }

    if (isDemo) return config;

    const accessToken = await auth0.getAccessTokenSilently();

    if (config.headers) {
      config.headers.set('Authorization', `Bearer ${accessToken}`);
    }

    if (config.headers && userLocalStore.value.auth0Id) {
      config.headers.set('Karta-Org-Id', userLocalStore.value.auth0Id);
    }
  } catch (error) {
    console.error('requestInterceptor', error);
  }

  return config;
}

export function responseSuccessInterceptor(
  response: AxiosResponse['data'],
): AxiosResponse['data'] {
  return camelizeKeys(response.data);
}

export async function responseErrorInterceptor(
  error: AxiosError<ResponseError['data']>,
): Promise<ResponseError | void> {
  if (Axios.isCancel(error) as boolean) {
    const cancelError: ResponseError = {
      status: 418,
      statusMessage: `I'm a teapot`,
      message: error.message,
      data: null,
    };

    return Promise.reject(cancelError);
  }

  const status = error?.response?.status || 500;

  const newError: ResponseError = {
    status,
    statusMessage: getStatusMessage(status),
    message: getResponseErrorMessage(error),
    data: error?.response?.data ? camelizeKeys(error.response.data) : null,
  };

  if (status === 401) {
    if (!isDemo) {
      await auth0.logout({
        logoutParams: {
          returnTo: `${window.location.origin}/auth/logout`,
        },
      });

      /**
       * @description Это бесконечный промис который никогда не завершиться.
       * Он необходим так как в auth0.logout асинхронный только процес очистки сторов.
       * Переход на страницу авторизации идет за промисом.
       */
      await new Promise(() => {});
    }
  }

  if (status === 403 && error.response?.data?.reason) {
    switch (error.response.data?.reason) {
      case CoreForbiddenReason.CompanyBlocked:
        router.replace({
          name: CORE_COMPANY_BLOCKED_INDEX_PAGE.name,
          query: { returnToName: CORE_ORGANIZATION_SELECTION_PAGE.name },
        });
        break;

      case CoreForbiddenReason.CompanyWaitingDocuments:
        router.replace({
          name: CORE_COMPANY_WAITING_DOCUMENTS_INDEX_PAGE.name,
          query: { returnToName: CORE_ORGANIZATION_SELECTION_PAGE.name },
        });
        break;

      case CoreForbiddenReason.CompanyUserBlocked:
        router.replace({
          name: CORE_COMPANY_USER_BLOCKED_INDEX_PAGE.name,
          query: { returnToName: CORE_ORGANIZATION_SELECTION_PAGE.name },
        });
        break;

      case CoreForbiddenReason.CompanyUserDeleted:
        router.replace({
          name: CORE_COMPANY_USER_DELETED_INDEX_PAGE.name,
          query: { returnToName: CORE_ORGANIZATION_SELECTION_PAGE.name },
        });
        break;

      case CoreForbiddenReason.CoreUserBlocked:
        router.replace({
          name: CORE_USER_BLOCKED_INDEX_PAGE.name,
        });
        break;

      case CoreForbiddenReason.SpecifyKartaOrgId:
        router.replace({ name: CORE_ORGANIZATION_SELECTION_PAGE.name });
        break;

      default:
        break;
    }
  }

  return Promise.reject(newError);
}
