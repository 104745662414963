<script setup lang="ts">
import { UiColorVar, UiTypography } from '@karta.io/ui-components';

import { COUNTRY_ICONS_BY_CURRENCY } from '@/data';

import type { Currency } from '@/enums';
import type { CoreExchangeRateItem } from '@/interfaces';

interface Props {
  fromCurrency: Currency;
  items: CoreExchangeRateItem[];
}

defineOptions({ name: 'CoreExchangeRatePopoverContent' });

const props = defineProps<Props>();

const cn = useClassName('core-exchange-rate-popover-content');

const fromCurrencyItem = computed(() => ({
  label: `1 ${props.fromCurrency}`,
  country: COUNTRY_ICONS_BY_CURRENCY[props.fromCurrency],
}));

const items = computed(() =>
  props.items.map(item => ({
    label: item.toCurrency,
    country: COUNTRY_ICONS_BY_CURRENCY[item.toCurrency],
    value: formatMoney(item.value, item.toCurrency),
  })),
);
</script>

<template>
  <div :class="cn.b()">
    <dl :class="cn.e('items')">
      <div :class="cn.e('item')">
        <dt :class="cn.e('item-label')">
          <UiIcon
            :value="`circle-flags:${fromCurrencyItem.country}`"
            size="20px"
          />

          <AppText
            :typography="UiTypography.TextSmRegular"
            :color="UiColorVar.Secondary900"
            textEllipsis="100%"
          >
            {{ fromCurrencyItem.label }}
          </AppText>
        </dt>

        <dd>
          <AppText
            :typography="UiTypography.TextSmRegular"
            :color="UiColorVar.Secondary900"
          >
            =
          </AppText>
        </dd>
      </div>
    </dl>

    <UiDivider />

    <dl :class="cn.e('items')">
      <div v-for="item in items" :key="item.label" :class="cn.e('item')">
        <dt :class="cn.e('item-label')">
          <UiIcon :value="`circle-flags:${item.country}`" size="20px" />

          <AppText
            :typography="UiTypography.TextSmRegular"
            :color="UiColorVar.Secondary500"
            textEllipsis="100%"
          >
            {{ item.label }}
          </AppText>
        </dt>

        <dd>
          <AppText
            :typography="UiTypography.TextSmRegular"
            :color="UiColorVar.Secondary900"
          >
            {{ item.value }}
          </AppText>
        </dd>
      </div>
    </dl>

    <AppText
      :typography="UiTypography.TextXsRegular"
      :color="UiColorVar.Secondary400"
      style="margin-top: var(--spacing-01)"
    >
      The total is shown in the default currency using the estimated exchange
      rate.
    </AppText>
  </div>
</template>

<style lang="scss">
.core-exchange-rate-popover-content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-03);

  &__items {
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: var(--spacing-03);
    margin: 0;

    dd,
    dt {
      margin: 0;
    }
  }

  &__item {
    display: grid;
    grid-template-columns: minmax(0, 1fr) max-content;
    grid-column-gap: var(--spacing-03);
    align-content: space-around;
  }

  &__item-label {
    display: flex;
    align-items: center;
    gap: var(--spacing-02);
  }

  &__external-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: var(--color-success-900);
  }
}
</style>
