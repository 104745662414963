import type { CoreUser } from '@/interfaces';
import type { Budget, BudgetUserInStore } from './budgets.interface';

export type BudgetUsersAsNewMap = Map<CoreUser['id'], BudgetUserInStore>;

interface State {
  currentBudget: Budget | null;
  currentBudgetUsersAsMap: BudgetUsersAsNewMap;
  currentBudgetUsersPage: number;
  currentBudgetUsersCount: number;
  loadingCurrentBudgetUsers: boolean;
}

export const useBudgetsStore = defineStore('budgetStore', {
  state: (): State => ({
    currentBudget: null,
    currentBudgetUsersAsMap: new Map(),
    currentBudgetUsersPage: 1,
    currentBudgetUsersCount: 0,
    loadingCurrentBudgetUsers: false,
  }),
  getters: {
    currentBudgetUsers: state =>
      Array.from(state.currentBudgetUsersAsMap.values()),
    currentBudgetUsersById: state => (id: CoreUser['id']) =>
      state.currentBudgetUsersAsMap.get(id),
    hasBudgetUser: state => (id: CoreUser['id']) =>
      state.currentBudgetUsersAsMap.has(id),
  },
  actions: {
    clearAll(): void {
      this.currentBudget = null;
      this.currentBudgetUsersAsMap = new Map();
    },
    addBudgetUser(userId: CoreUser['id'], data: BudgetUserInStore) {
      /**
       * Извращения чтобы поставить нового пользователя первым
       */
      this.currentBudgetUsersAsMap = new Map([
        [userId, data],
        ...Array.from(this.currentBudgetUsersAsMap),
      ]);
      this.currentBudgetUsersCount += 1;
    },
    updateBudgetUser(
      userId: CoreUser['id'],
      data: Partial<Pick<BudgetUserInStore, 'role' | 'limits' | 'disabled'>>,
    ): void {
      const currentUser = this.currentBudgetUsersAsMap.get(userId);

      if (!currentUser) return;
      this.currentBudgetUsersAsMap.set(userId, {
        ...currentUser,
        ...data,
      });
    },
    removeBudgetUser(userId: CoreUser['id']): void {
      this.currentBudgetUsersAsMap.delete(userId);
      this.currentBudgetUsersCount -= 1;
    },
    addBudgetUsers(budgetUsers: BudgetUserInStore[]) {
      this.currentBudgetUsersAsMap = new Map(
        [...this.currentBudgetUsers, ...budgetUsers].map(budgetUser => [
          budgetUser.user.id,
          budgetUser,
        ]),
      );
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBudgetsStore, import.meta.hot));
}
