import type { AppFilterTagDatePreset } from '@karta.io/app-components';
import { UiColorVar } from '@karta.io/ui-components';

import { Currency, FilterDatePeriodName } from '@/enums';

const dayjs = useDayjs();

export const PAGE_DEFAULT = 1;
export const OFFSET_DEFAULT = 0;
export const LIMIT_DEFAULT = 25;
export const LIMIT_MAX = 100;
export const NAME_MIN_LENGTH_DEFAULT = 3;
export const NAME_MAX_LENGTH_DEFAULT = 50;
export const USER_NAME_MAX_LENGTH = 150;
export const NAME_LARGE_MAX_LENGTH_DEFAULT = 255;
export const DESCRIPTION_MAX_LENGTH_DEFAULT = 255;
export const DESCRIPTION_LARGE_MAX_LENGTH_DEFAULT = 512;
export const ACCOUNT_NUMBER_MIN_LENGTH_DEFAULT = 5;
export const ACCOUNT_NUMBER_MIN_LENGTH_SEPA = 5;
export const ACCOUNT_NUMBER_MAX_LENGTH_WIRE = 17;
export const ACCOUNT_NUMBER_MAX_LENGTH_DEFAULT = 34;
export const BIC_NUMBER_MIN_LENGTH_DEFAULT = 8;
export const BIC_NUMBER_MAX_LENGTH_DEFAULT = 11;
export const EIN_NUMBER_LENGTH_DEFAULT = 9;
export const ROUTING_NUMBER_LENGTH_DEFAULT = 9;
export const SMS_CODE_NUMBER_LENGTH_DEFAULT = 6;
export const ADDRESS_LINE_MAX_LENGTH_DEFAULT = 35;
export const REQUIRED_PHONE_NUMBER_MESSAGE_DEFAULT = 'The phone is required';
export const VALIDATE_PHONE_NUMBER_MESSAGE_DEFAULT = 'The phone is invalid';
export const VALIDATE_PHONE_NUMBER_BY_COUNTRY_MESSAGE_DEFAULT =
  'Unfortunately your country is not supported yet';
export const TIMEZONE_DEFAULT = 'Etc/UCT';
export const KARTA_PRIMARY_ACCOUNT_IMAGE =
  'https://static.karta.io/images/karta-account-avatar.png';
export const KARTA_SUB_ACCOUNT_IMAGE =
  'https://static.karta.io/images/karta-sub-account-avatar.png';
export const EXTERNAL_ACCOUNT_IMAGE =
  'https://static.karta.io/images/external-account-avatar.png';
export const VISA_PURCHASING_ICON =
  'https://static.karta.io/icons/visa-purchasing-icon.svg';

export const NO_DATA_PLACEHOLDER = '—';

export const CMS_SALES_URL_VARIABLE = '{companyId}';

export const TOOLTIP_DELAY_DEFAULT = 150;

export const UNKNOWN_USER_NAME = 'Unknown user';
export const UNKNOWN_CARD = 'Unknown virtual card';

export const PHONE_BANNED_COUNTRIES = [
  'AF', // Afghanistan
  'AO', // Angola
  'BF', // Burkina Faso
  'BI', // Burundi
  'KH', // Cambodia
  'CF', // Central African Republic
  'CG', // Congo
  'CU', // Cuba
  'ER', // Eritrea
  'ET', // Ethiopia
  'GN', // Guinea
  'GW', // Guinea-Bissau
  'GY', // Guyana
  'HT', // Haiti
  'IQ', // Iraq
  'LB', // Lebanon
  'LR', // Liberia
  'LY', // Libya
  'MM', // Myanmar
  'PK', // Pakistan
  'PA', // Palau
  'SL', // Sierra Leone
  'SO', // Solomon Islands
  'SS', // South Sudan
  'SD', // Sudan
  'TJ', // Tajikistan
  'TL', // Timor-Leste
  'TT', // Trinidad and Tobago
  'UG', // Uganda
  'VU', // Vanuatu
  'KP', // Korea (the Democratic People's Republic of)
  'CD', // Congo
  'IR', // Iran
  'LA', // Lao People's Democratic Republic
  'PS', // Palestine
  'SY', // Syrian Arab Republic
  'TZ', // Tanzania
  'VE', // Venezuela
  'YE', // Yemen
  'ZW', // Zimbabwe
];
export const SEPA_COUNTRIES = [
  'AT', // Austria
  'BE', // Belgium
  'BG', // Bulgaria
  'HR', // Croatia
  'CY', // Cyprus
  'CZ', // Czech Republic
  'DK', // Denmark
  'EE', // Estonia
  'FI', // Finland
  'FR', // France
  'DE', // Germany
  'GR', // Greece
  'HU', // Hungary
  'IE', // Ireland
  'IT', // Italy
  'LV', // Latvia
  'LT', // Lithuania
  'LU', // Luxembourg
  'MT', // Malta
  'NL', // Netherlands
  'PL', // Poland
  'PT', // Portugal
  'RO', // Romania
  'SK', // Slovakia
  'SI', // Slovenia
  'ES', // Spain
  'SE', // Sweden
  'CH', // Switzerland
  'GB', // UK
  'SM', // San Marino
  'VA', // Vatican City
  'AD', // Andorra
  'MC', // Monaco
  'IS', // Iceland
  'NO', // Norway
  'LI', // Liechtenstein
];

export const BANNED_COUNTRIES = [
  ...PHONE_BANNED_COUNTRIES,
  'RU', // Russia
  'BY', // Belarus
];

export const COUNTRY_ICONS_BY_CURRENCY: { [key: string]: string } = {
  [Currency.Usd]: 'us',
  [Currency.Eur]: 'european-union',
  [Currency.Idr]: 'id',
  [Currency.Rub]: 'ru',
  [Currency.Gbp]: 'uk',
};

export const CURRENCIES = [
  {
    code: Currency.Usd,
    name: 'US Dollar',
    countryCode: 'US',
    icon: 'circle-flags:us',
  },
  {
    code: Currency.Eur,
    name: 'Euro',
    countryCode: 'EU',
    icon: 'circle-flags:eu',
  },
  {
    code: Currency.Gbp,
    name: 'UK Pound Sterling',
    countryCode: 'GB',
    icon: 'circle-flags:gb',
  },
  {
    code: Currency.Jpy,
    name: 'Japanese Yen',
    countryCode: 'JP',
    icon: 'circle-flags:jp',
  },
  {
    code: Currency.Aud,
    name: 'Australian Dollar',
    countryCode: 'AU',
    icon: 'circle-flags:au',
  },
  {
    code: Currency.Cad,
    name: 'Canadian Dollar',
    countryCode: 'CA',
    icon: 'circle-flags:ca',
  },
  {
    code: Currency.Chf,
    name: 'Swiss Franc',
    countryCode: 'CH',
    icon: 'circle-flags:ch',
  },
  {
    code: Currency.Cny,
    name: 'Chinese Yuan',
    countryCode: 'CN',
    icon: 'circle-flags:cn',
  },
  {
    code: Currency.Sek,
    name: 'Swedish Krona',
    countryCode: 'SE',
    icon: 'circle-flags:se',
  },
  {
    code: Currency.Nzd,
    name: 'New Zealand Dollar',
    countryCode: 'NZ',
    icon: 'circle-flags:nz',
  },
  {
    code: Currency.Zar,
    name: 'South African Rand',
    countryCode: 'ZA',
    icon: 'circle-flags:za',
  },
  {
    code: Currency.Inr,
    name: 'Indian Rupee',
    countryCode: 'IN',
    icon: 'circle-flags:in',
  },
  {
    code: Currency.Brl,
    name: 'Brazilian Real',
    countryCode: 'BR',
    icon: 'circle-flags:br',
  },
  {
    code: Currency.Mxn,
    name: 'Mexican Peso',
    countryCode: 'MX',
    icon: 'circle-flags:mx',
  },
  {
    code: Currency.Sgd,
    name: 'Singapore Dollar',
    countryCode: 'SG',
    icon: 'circle-flags:sg',
  },
  {
    code: Currency.Hkd,
    name: 'Hong Kong Dollar',
    countryCode: 'HK',
    icon: 'circle-flags:hk',
  },
  {
    code: Currency.Nok,
    name: 'Norwegian Krone',
    countryCode: 'NO',
    icon: 'circle-flags:no',
  },
  {
    code: Currency.Krw,
    name: 'South Korean Won',
    countryCode: 'KR',
    icon: 'circle-flags:kr',
  },
  {
    code: Currency.Try,
    name: 'Turkish Lira',
    countryCode: 'TR',
    icon: 'circle-flags:tr',
  },
  {
    code: Currency.Pln,
    name: 'Polish Zloty',
    countryCode: 'PL',
    icon: 'circle-flags:pl',
  },
  {
    code: Currency.Dkk,
    name: 'Danish Krone',
    countryCode: 'DK',
    icon: 'circle-flags:dk',
  },
  {
    code: Currency.Thb,
    name: 'Thai Baht',
    countryCode: 'TH',
    icon: 'circle-flags:th',
  },
  {
    code: Currency.Myr,
    name: 'Malaysian Ringgit',
    countryCode: 'MY',
    icon: 'circle-flags:my',
  },
  {
    code: Currency.Idr,
    name: 'Indonesian Rupiah',
    countryCode: 'ID',
    icon: 'circle-flags:id',
  },
  {
    code: Currency.Czk,
    name: 'Czech Koruna',
    countryCode: 'CZ',
    icon: 'circle-flags:cz',
  },
  {
    code: Currency.Huf,
    name: 'Hungarian Forint',
    countryCode: 'HU',
    icon: 'circle-flags:hu',
  },
  {
    code: Currency.Ils,
    name: 'Israeli New Shekel',
    countryCode: 'IL',
    icon: 'circle-flags:il',
  },
  {
    code: Currency.Php,
    name: 'Philippine Peso',
    countryCode: 'PH',
    icon: 'circle-flags:ph',
  },
  {
    code: Currency.Sar,
    name: 'Saudi Riyal',
    countryCode: 'SA',
    icon: 'circle-flags:sa',
  },
  {
    code: Currency.Aed,
    name: 'United Arab Emirates Dirham',
    countryCode: 'AE',
    icon: 'circle-flags:ae',
  },
  {
    code: Currency.Vnd,
    name: 'Vietnamese Dong',
    countryCode: 'VN',
    icon: 'circle-flags:vn',
  },
  {
    code: Currency.Egp,
    name: 'Egyptian Pound',
    countryCode: 'EG',
    icon: 'circle-flags:eg',
  },
  {
    code: Currency.Qar,
    name: 'Qatari Riyal',
    countryCode: 'QA',
    icon: 'circle-flags:qa',
  },
  {
    code: Currency.Kwd,
    name: 'Kuwaiti Dinar',
    countryCode: 'KW',
    icon: 'circle-flags:kw',
  },
  {
    code: Currency.Bhd,
    name: 'Bahraini Dinar',
    countryCode: 'BH',
    icon: 'circle-flags:bh',
  },
  {
    code: Currency.Omr,
    name: 'Omani Rial',
    countryCode: 'OM',
    icon: 'circle-flags:om',
  },
];

export const KYB_KYC_DATE_FORMAT = 'YYYY-MM-DD';

export const DATE_PICKER_FORMAT_DEFAULT = 'YYYY-MM-DD';
export const DATE_PICKER_FORMAT_FOR_COMPARE = 'YYYY-MM-DD';

export const FILTER_DATE_FORMAT_DEFAULT = 'YYYY-MM-DD';

export const getFilterDateDefaultPeriod = () => ({
  start: dayjs().subtract(29, 'day').startOf('day').toDate(),
  end: dayjs().startOf('day').toDate(),
});

export const getFilterDatePresets = (): AppFilterTagDatePreset[] => [
  {
    start: dayjs().subtract(29, 'day').startOf('day').toDate(),
    end: dayjs().startOf('day').toDate(),
    listTitle: 'Last 30 days',
    clearable: false,
    triggerValueColor: UiColorVar.Secondary500,
    periodName: FilterDatePeriodName.LastThirtyDays,
    group: 1,
  },
  {
    start: dayjs().startOf('day').toDate(),
    end: dayjs().startOf('day').toDate(),
    listTitle: 'Today',
    clearable: true,
    triggerValueData: 'Today',
    periodName: FilterDatePeriodName.Today,
    group: 1,
  },
  {
    start: dayjs().startOf('week').toDate(),
    end: dayjs().endOf('week').toDate(),
    listTitle: 'This week',
    clearable: true,
    periodName: FilterDatePeriodName.ThisWeek,
    group: 2,
  },
  {
    start: dayjs().startOf('month').toDate(),
    end: dayjs().endOf('month').toDate(),
    listTitle: 'This month',
    triggerValueData: dayjs().startOf('month').format('MMMM'),
    clearable: true,
    periodName: FilterDatePeriodName.ThisMonth,
    group: 2,
  },
  {
    start: dayjs().startOf('year').toDate(),
    end: dayjs().endOf('year').toDate(),
    listTitle: 'This year',
    clearable: true,
    periodName: FilterDatePeriodName.ThisYear,
    group: 2,
  },
  {
    start: dayjs().subtract(1, 'week').startOf('week').toDate(),
    end: dayjs().subtract(1, 'week').endOf('week').toDate(),
    listTitle: 'Last week',
    clearable: true,
    periodName: FilterDatePeriodName.LastWeek,
    group: 3,
  },
  {
    start: dayjs().subtract(1, 'month').startOf('month').toDate(),
    end: dayjs().subtract(1, 'month').endOf('month').toDate(),
    triggerValueData: dayjs()
      .subtract(1, 'month')
      .startOf('month')
      .format('MMMM'),
    listTitle: 'Last month',
    clearable: true,
    periodName: FilterDatePeriodName.LastMonth,
    group: 3,
  },
  {
    start: dayjs().subtract(1, 'year').startOf('year').toDate(),
    end: dayjs().subtract(1, 'year').endOf('year').toDate(),
    triggerValueData: `${dayjs()
      .subtract(1, 'year')
      .startOf('year')
      .format('DD MMM, YYYY')} - ${dayjs()
      .subtract(1, 'year')
      .endOf('year')
      .format('DD MMM, YYYY')}`,
    listTitle: 'Last year',
    clearable: true,
    periodName: FilterDatePeriodName.LastYear,
    group: 3,
  },
];

export const SENTRY_IGNORE_ERRORS = [
  // fbq
  'fbq is not defined',
  "Can't find variable: fbq",
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  // Generic error code from errors outside the security sandbox
  // You can delete this if using raven.js > 1.0, which ignores these automatically.
  'Script error.',
];

export const SENTRY_DENY_URLS = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
];
