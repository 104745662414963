import type { RouteRecordRaw } from 'vue-router';

import { permissionsGuard } from '@/router/guards';
import { useSaveQuery } from '@/composables';

import { CoreUserPermissionsMap, OVERVIEW_INDEX_PAGE } from '@/data';

const { setQuery } = useSaveQuery(OVERVIEW_INDEX_PAGE.name);

const overviewRoutes: RouteRecordRaw[] = [
  {
    path: '/overview',
    name: OVERVIEW_INDEX_PAGE.name,
    component: () => import('@/modules/overview/pages/OverviewIndexPage.vue'),
    beforeEnter: [permissionsGuard, setQuery],
    meta: {
      auth: true,
      navigation: OVERVIEW_INDEX_PAGE.navigation,
      permissions: [CoreUserPermissionsMap.ReadCompanyAccount],
    },
  },
];

export default overviewRoutes;
