export enum CoreLocalStorage {
  ApiBaseUrl = 'karta-app-api-base-url',
  StagingManagementCardCollapsed = 'karta-app-staging-management-card-collapsed',
  UserStore = 'karta-app-user',
  Debug = 'karta-app-debug',
  Filters = 'karta-app-filters',
  IsAuthenticated = 'karta-app-is-authenticated',
  IsBannedCountryBannerHidden = 'karta-app-is-banned-country-banner-hidden',
}

export enum CoreSessionStorage {
  TechWorksKey = 'karta-app-tech-works-key',
  LoginReturnUrl = 'karta-app-login-return-url',
  SessionId = 'karta-app-session-id',
  ErrorsByRouter = 'karta-app-errors-by-router',
}

export enum CoreUserStatus {
  New = 'NEW',
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
}

export enum CoreUserThreeDsChannel {
  Email = 'EMAIL',
  Phone = 'PHONE',
}

export enum CoreUserPermissionKey {
  Budget = 'budget',
  BudgetUser = 'budgetUser',
  Card = 'card',
  Company = 'company',
  CompanyAccount = 'companyAccount',
  CompanyAccountSpend = 'companyAccountSpend',
  CompanyUser = 'companyUser',
  Counterparty = 'counterparty',
  CounterpartyPaymentAccount = 'counterpartyPaymentAccount',
  CurrencyExchangeRate = 'currencyExchangeRate',
  Document = 'document',
  Invoice = 'invoice',
  InvoiceItem = 'invoiceItem',
  Partner = 'partner',
  ExternalAccount = 'plaidAccount',
  ExternalConnection = 'plaidConnection',
  ExternalTransaction = 'plaidTransaction',
  Report = 'report',
  Transaction = 'transaction',
  User = 'user',
}

export enum CoreForbiddenReason {
  AccessDenied = 'ACCESS_DENIED',
  CompanyUserBlocked = 'COMPANY_USER_BLOCKED',
  CompanyUserDeleted = 'COMPANY_USER_DELETED',
  CompanyBlocked = 'COMPANY_DECLINED',
  CompanyWaitingDocuments = 'NEED_UPLOAD_DOCUMENTS',
  CoreUserBlocked = 'USER_BLOCKED',
  SpecifyKartaOrgId = 'SPECIFY_KARTA_ORG_ID',
}

export enum CorePhoneVerificationMethod {
  Sms = 'SMS',
  Whatsapp = 'WHATSAPP',
  Call = 'CALL',
}

export enum CoreBalanceType {
  Main = 'MAIN',
  External = 'EXTERNAL',
}

export enum CoreUserAuthProvider {
  Auth0 = 'auth0',
  GoogleOauth2 = 'google-oauth2',
}

export enum CorePhoneChangeStep {
  Edit = 'EDIT',
  Verify = 'VERIFY',
}

export enum CoreWsClientEvent {
  AuthLogin = 'AUTH_LOGIN',
}

export enum CoreWsServerEvent {
  AuthExpire = 'AUTH_EXPIRE',
  AuthLoginSuccess = 'AUTH_LOGIN_SUCCESS',
  AuthLoginFailed = 'AUTH_LOGIN_FAILED',

  // Statement
  StatementCreatedSucceeded = 'STATEMENT_CREATED_SUCCEEDED',
  StatementCreateFailed = 'STATEMENT_CREATE_FAILED',
}

export enum CoreOrganizationsSortType {
  Name = 'name',
  Balance = 'balance',
  Spend = 'spend',
  Income = 'income',
}
