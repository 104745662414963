export function goToSupport() {
  const { Intercom } = window;

  if (!Intercom) {
    window.open('mailto:support@karta.io', '_self');
    return;
  }

  Intercom('showNewMessage');
}
