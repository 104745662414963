import type { ResponseError } from '@karta.io/utils';

import { Currency, type MoveMoneyInternalTransferStep } from '@/enums';
import type { CompanyAccount } from '@/interfaces';

interface State {
  initialTransferFromAccountId?: CompanyAccount['id'];

  transferFromAccount?: CompanyAccount;
  transferToAccount?: CompanyAccount;
  amountValue?: string;

  step: MoveMoneyInternalTransferStep | undefined;
  isLoading: boolean;
  isDataChanged: boolean;
  errors?: ResponseError['data'];
}

export const useMoveMoneyInternalTransferStore = defineStore(
  'moveMoneyInternalTransferStore',
  {
    state: (): State => ({
      initialTransferFromAccountId: undefined,

      transferFromAccount: undefined,
      transferToAccount: undefined,
      amountValue: undefined,

      step: undefined,
      isLoading: false,
      isDataChanged: false,
      errors: undefined,
    }),
    getters: {
      amountCurrency: (state: State): Currency =>
        state.transferFromAccount?.currency ||
        state.transferToAccount?.currency ||
        Currency.Usd,
    },
  },
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useMoveMoneyInternalTransferStore, import.meta.hot),
  );
}
