<script setup lang="ts">
import { useCoreStore } from '@/stores';
import { useAnalytics, useEnv } from '@/composables';

import { NavigationGroup, PermissionAction } from '@/enums';
import type { NavigationItem } from '@/interfaces';

import {
  ACCOUNTS_INDEX_PAGE,
  BUDGETS_INDEX_PAGE,
  CARDS_INDEX_PAGE,
  COMPANY_USERS_INDEX_PAGE,
  COUNTERPARTIES_INDEX_PAGE,
  ONBOARDING_GET_STARTED_PAGE,
  OVERVIEW_INDEX_PAGE,
  TRANSACTIONS_INDEX_PAGE,
} from '@/data';

defineOptions({ name: 'Navigation' });

const emit = defineEmits(['onItemClick']);

const coreStore = useCoreStore();
const route = useRoute();
const { track } = useAnalytics();
const { isApp } = useEnv();

const activeNavigationId = computed(() => {
  const { navigation } = route.meta;

  if (!navigation || !navigation.id) return '';
  return navigation.id;
});

const checkPermissions = (item?: NavigationItem): boolean => {
  if (!item?.permissions) return true;

  return item.permissions.some(itemPermission => {
    if (!coreStore.permissions) return false;

    const action = itemPermission.action || PermissionAction.Read;
    const userPermission = coreStore.permissions[itemPermission.key];

    // @ts-expect-error
    return userPermission?.[action];
  });
};

const checkEnvironments = (item?: NavigationItem): boolean => {
  if (!item?.environments) return true;

  return item.environments.includes(import.meta.env.APP_ENV);
};

const checkRole = (item?: NavigationItem): boolean => {
  if (!item?.role) return true;

  return item.role === coreStore.currentCompanyUser?.role;
};

const checkGetStarted = (item?: NavigationItem): boolean => {
  if (item?.id !== 'get-started') return true;

  return isApp && Boolean(coreStore.companyProviderInOnboarding);
};

const getGroupTitle = (key: NavigationGroup) => {
  if (key === NavigationGroup.Inactive) return 'Not activated';
  if (key === NavigationGroup.Soon) return 'Coming soon';
  if (key === NavigationGroup.Active) return '';

  return '';
};

const navigationOrder: Readonly<Record<NavigationItem['id'], number>> =
  Object.freeze({
    [ONBOARDING_GET_STARTED_PAGE.navigation!.id]: 1,
    [OVERVIEW_INDEX_PAGE.navigation!.id]: 2,
    [ACCOUNTS_INDEX_PAGE.navigation!.id]: 3,
    [BUDGETS_INDEX_PAGE.navigation!.id]: 4,
    [CARDS_INDEX_PAGE.navigation!.id]: 5,
    [TRANSACTIONS_INDEX_PAGE.navigation!.id]: 6,
    [COUNTERPARTIES_INDEX_PAGE.navigation!.id]: 7,
    [COMPANY_USERS_INDEX_PAGE.navigation!.id]: 8,
  });

const navigationAsMap = computed(() => {
  return [
    ONBOARDING_GET_STARTED_PAGE.navigation,
    OVERVIEW_INDEX_PAGE.navigation,
    ACCOUNTS_INDEX_PAGE.navigation,
    BUDGETS_INDEX_PAGE.navigation,
    CARDS_INDEX_PAGE.navigation,
    TRANSACTIONS_INDEX_PAGE.navigation,
    COUNTERPARTIES_INDEX_PAGE.navigation,
    COMPANY_USERS_INDEX_PAGE.navigation,
  ]
    .filter(checkGetStarted)
    .filter(checkEnvironments)
    .filter(checkPermissions)
    .filter(checkRole)
    .sort((a, b) => navigationOrder[a!.id] - navigationOrder[b!.id])
    .reduce((acc, item) => {
      const group = item!.group(
        coreStore.hasCompanyAccounts,
        coreStore.hasExternalAccounts,
        coreStore.isCompanyAccountFunded,
      );
      const prevData = acc.get(group) || [];
      acc.set(group, [...prevData, item]);

      return acc;
    }, new Map());
});

const navigation = computed(() =>
  [...navigationAsMap.value.keys()].map(key => ({
    key,
    title: getGroupTitle(key),
    list: navigationAsMap.value.get(key),
  })),
);

const handleLinkClick = (item: NavigationItem) => {
  track(`${item.title} Section`);
  emit('onItemClick');
};

const cn = useClassName('navigation');
</script>

<template>
  <div :class="cn.b()">
    <div v-for="group in navigation" :key="group.key" :class="cn.e('group')">
      <span v-if="group.title" :class="cn.e('group-title')">
        {{ group.title }}
      </span>

      <ul :class="cn.e('list')">
        <li v-for="item in group.list" :key="item.id" :class="cn.e('item')">
          <router-link
            :to="{
              path: item.to,
              query: route.path === item.to ? route.query : undefined,
              force: true,
            }"
            :disabled="item.disabled || undefined"
            :class="[
              cn.e('link'),
              cn.em('link', 'active', activeNavigationId === item.id),
              cn.em('link', 'disabled', item.disabled),
            ]"
            @click="() => handleLinkClick(item)"
          >
            <UiIcon
              :value="item.icon"
              size="20px"
              style="margin-right: var(--spacing-02)"
            />
            {{ item.title }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss">
.navigation {
  padding: 0 var(--spacing-04);

  &__list {
    margin: 0;
    margin-bottom: var(--spacing-06);
    padding: 0;
    list-style-type: none;
  }

  &__group-title {
    display: block;
    margin-bottom: var(--spacing-02);
    padding-left: var(--spacing-04);
    @include typography('text-xs-semibold');
    color: var(--color-secondary-500);
    text-transform: uppercase;
    pointer-events: none;
  }

  &__item {
    margin-bottom: var(--spacing-02);
  }

  &__link {
    display: flex;
    height: 40px;
    align-items: center;
    border-radius: var(--border-radius-sm);
    padding: var(--spacing-02) var(--spacing-03);
    color: var(--color-base-black);
    text-decoration: none;

    @include typography('text-md-regular');

    &_active {
      color: var(--color-primary-500);
      background: var(--color-primary-25);
    }

    &_disabled {
      color: var(--color-secondary-400);
    }
  }
}
</style>
