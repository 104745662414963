<script lang="ts" setup>
import {
  UiAvatarSize,
  UiColor,
  UiColorVar,
  UiTagSize,
  UiTagType,
  UiTypography,
} from '@karta.io/ui-components';

import { KARTA_PRIMARY_ACCOUNT_IMAGE, KARTA_SUB_ACCOUNT_IMAGE } from '@/data';

import { CompanyAccountType } from '@/enums';
import type { CompanyAccount } from '@/interfaces';

interface Props {
  name: CompanyAccount['name'];
  type: CompanyAccount['type'];
  balance?: CompanyAccount['balance'];
  currency: CompanyAccount['currency'];
  sourceAccount?: CompanyAccount['sourceAccount'];
  avatarSize?: UiAvatarSize;
  isDisabled?: boolean;
}

defineOptions({ name: 'CompanyAccountSelectTriggerValue' });

const props = withDefaults(defineProps<Props>(), {
  balance: undefined,
  sourceAccount: undefined,
  avatarSize: UiAvatarSize.Sm,
  isDisabled: false,
});

const cn = useClassName('company-account-select-trigger-value');

const imageSrc = computed(() =>
  props.type === CompanyAccountType.Primary
    ? KARTA_PRIMARY_ACCOUNT_IMAGE
    : KARTA_SUB_ACCOUNT_IMAGE,
);

const balance = computed(() => formatMoney(props.balance, props.currency));
</script>

<template>
  <div :class="[cn.b(), cn.m('is-disabled', props.isDisabled)]">
    <UiAvatar :src="imageSrc" :size="props.avatarSize" />

    <div>
      <div :class="cn.e('top-row')">
        <AppText
          tag="span"
          :typography="UiTypography.TextSmMedium"
          :color="UiColorVar.Secondary900"
          textEllipsis="100%"
        >
          {{ props.name }}
        </AppText>

        <UiTag
          v-if="props.sourceAccount"
          :label="props.sourceAccount.name"
          :color="UiColor.Secondary"
          :type="UiTagType.Outline"
          :size="UiTagSize.Xs"
          hasTextEllipsis
        />
      </div>
      <div :class="cn.e('bottom-row')">
        <AppText
          tag="div"
          :typography="UiTypography.TextXsMedium"
          :color="UiColorVar.Secondary500"
          textEllipsis="100%"
        >
          {{ balance }}
        </AppText>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.company-account-select-trigger-value {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: var(--spacing-03);
  border-radius: var(--border-radius-md);

  &_is-disabled {
    background-color: var(--base-color-white);
    opacity: 0.4;
    cursor: not-allowed;
  }

  &__top-row {
    display: grid;
    grid-template-columns: minmax(0, min-content) auto;
    align-items: center;
    gap: var(--spacing-02);
  }
}
</style>
