import { CARDS_INDEX_PAGE, CoreUserPermissionsMap } from '@/data';

import { permissionsGuard } from '@/router/guards';
import { useSaveQuery } from '@/composables';

const { setQuery } = useSaveQuery(CARDS_INDEX_PAGE.name);

export default [
  {
    path: '/cards',
    name: CARDS_INDEX_PAGE.name,
    component: () => import('@/modules/cards/pages/CardsIndexPage.vue'),
    beforeEnter: [permissionsGuard, setQuery],
    meta: {
      auth: true,
      navigation: CARDS_INDEX_PAGE.navigation,
      permissions: [CoreUserPermissionsMap.ReadCard],
    },
  },
];
