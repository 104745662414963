import type { RouteRecordRaw } from 'vue-router';

import { useEnv } from '@/composables';
import {
  CORE_COMPANY_BLOCKED_INDEX_PAGE,
  CORE_COMPANY_USER_BLOCKED_INDEX_PAGE,
  CORE_COMPANY_USER_DELETED_INDEX_PAGE,
  CORE_COMPANY_WAITING_DOCUMENTS_INDEX_PAGE,
  CORE_ERROR_INDEX_PAGE,
  CORE_FORBIDDEN_INDEX_PAGE,
  CORE_MAINTENANCE_INDEX_PAGE,
  CORE_NOT_FOUND_INDEX_PAGE,
  CORE_ORGANIZATION_SELECTION_PAGE,
  CORE_ROOT_PAGE,
  CORE_USER_BLOCKED_INDEX_PAGE,
} from '@/data';

import { PageType } from '@/enums';

const { isApp } = useEnv();

const coreRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: CORE_ROOT_PAGE.name,
    component: () => import('@/modules/core/pages/CoreRootPage.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/error',
    name: CORE_ERROR_INDEX_PAGE.name,
    component: () => import('@/modules/core/pages/CoreErrorIndexPage.vue'),
    meta: {
      auth: true,
      layout: 'empty',
      type: PageType.ErrorPage,
      props: {
        isSignOutHidden: true,
      },
    },
  },
  {
    path: '/forbidden',
    name: CORE_FORBIDDEN_INDEX_PAGE.name,
    component: () => import('@/modules/core/pages/CoreForbiddenIndexPage.vue'),
    meta: { auth: true },
  },
  {
    path: '/company-blocked',
    name: CORE_COMPANY_BLOCKED_INDEX_PAGE.name,
    component: () =>
      import('@/modules/core/pages/CoreCompanyBlockedIndexPage.vue'),
    meta: {
      auth: true,
      layout: 'empty',
      // type: PageType.CompanyBlockedPage,
    },
  },
  {
    path: '/company-waiting-documents',
    name: CORE_COMPANY_WAITING_DOCUMENTS_INDEX_PAGE.name,
    component: () =>
      import('@/modules/core/pages/CoreCompanyWaitingDocumentsIndexPage.vue'),
    meta: {
      auth: true,
      layout: 'empty',
      // type: PageType.CompanyWaitingDocumentsPage,
    },
  },
  {
    path: '/company-user-blocked',
    name: CORE_COMPANY_USER_BLOCKED_INDEX_PAGE.name,
    component: () =>
      import('@/modules/core/pages/CoreCompanyUserBlockedIndexPage.vue'),
    meta: {
      auth: true,
      layout: 'empty',
      type: PageType.CompanyUserBlockedPage,
    },
  },
  {
    path: '/company-user-deleted',
    name: CORE_COMPANY_USER_DELETED_INDEX_PAGE.name,
    component: () =>
      import('@/modules/core/pages/CoreCompanyUserDeletedIndexPage.vue'),
    meta: {
      auth: true,
      layout: 'empty',
      type: PageType.CompanyUserDeletedPage,
    },
  },
  {
    path: '/user-blocked',
    name: CORE_USER_BLOCKED_INDEX_PAGE.name,
    component: () =>
      import('@/modules/core/pages/CoreUserBlockedIndexPage.vue'),
    meta: {
      auth: true,
      layout: 'empty',
      // type: PageType.CoreUserBlockedPage,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: CORE_NOT_FOUND_INDEX_PAGE.name,
    component: () => import('@/modules/core/pages/CoreNotFoundIndexPage.vue'),
    meta: { auth: true },
  },
];

if (isApp) {
  coreRoutes.push(
    ...[
      {
        path: '/organizations',
        name: CORE_ORGANIZATION_SELECTION_PAGE.name,
        component: () =>
          import('@/modules/core/pages/CoreOrganizationSelectionPage.vue'),
        meta: {
          auth: true,
          layout: 'empty',
        },
      },
      {
        path: '/maintenance',
        name: CORE_MAINTENANCE_INDEX_PAGE.name,
        component: () =>
          import('@/modules/core/pages/CoreMaintenanceIndexPage.vue'),
        meta: {
          auth: false,
          layout: 'empty',
          type: PageType.MaintenancePage,
          props: {
            isSignOutHidden: true,
          },
        },
      },
    ],
  );
}

export default coreRoutes;
