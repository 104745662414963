import { CorePhoneChangeStep, CorePhoneVerificationMethod } from '@/enums';
import type { CorePhoneChangeRequest } from '@/interfaces';

interface State {
  phone?: CorePhoneChangeRequest['phone'];
  verificationMethod: CorePhoneChangeRequest['verificationMethod'];
  step: CorePhoneChangeStep;
  isLoading: boolean;
}

export const useCorePhoneChangeStore = defineStore('corePhoneChangeStore', {
  state: (): State => ({
    phone: undefined,
    verificationMethod: CorePhoneVerificationMethod.Sms,
    step: CorePhoneChangeStep.Edit,
    isLoading: false,
  }),
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useCorePhoneChangeStore, import.meta.hot),
  );
}
