<script lang="ts" setup>
import {
  UiAvatarSize,
  UiColor,
  UiColorVar,
  UiIconValue,
  UiSkeletonTextType,
  UiTagSize,
  UiTagType,
  UiTypography,
} from '@karta.io/ui-components';

/**
 * Принимает все параметры из UiSelect
 * @param {Object[]} options
 * @param {string} options[].value - Значение которое вернется.
 * @param {string} options[].label - Верхняя строка.
 * @param {string} options[].caption - Нижняя строка.
 * @param {string} options[].icon - Аватарка.
 */

interface Props {
  isSkeletonShown?: boolean;
  hasIcon?: boolean;
  iconColor?: UiColor;
}

defineOptions({
  name: 'BaseSelect',
  inheritAttrs: false,
});

const props = withDefaults(defineProps<Props>(), {
  hasIcon: true,
  isSkeletonShown: false,
  iconColor: UiColor.Secondary,
});

const cn = useClassName('base-select');
</script>

<template>
  <div :class="cn.b()">
    <div
      v-if="props.isSkeletonShown"
      :class="[cn.e('skeleton'), cn.em('skeleton', 'has-icon', props.hasIcon)]"
    >
      <UiSkeletonAvatar v-if="props.hasIcon" :size="UiAvatarSize.Sm" />

      <div :class="cn.e('skeleton-text-container')">
        <UiSkeletonText width="140px" :type="UiSkeletonTextType.TextSm" />
        <UiSkeletonText width="60px" :type="UiSkeletonTextType.TextXs" />
      </div>
    </div>

    <UiSelect v-else v-bind="$attrs">
      <template #selected-option="selectedOption">
        <div
          :class="[cn.e('option'), cn.em('option', 'has-icon', props.hasIcon)]"
        >
          <UiAvatar
            v-if="props.hasIcon"
            :size="UiAvatarSize.Sm"
            :src="selectedOption.icon"
            :color="props.iconColor"
          >
            <template #placeholder>
              <slot name="icon-placeholder" />
            </template>
          </UiAvatar>

          <div :class="cn.e('option-container')">
            <div :class="cn.e('option-label')">
              <AppText
                tag="span"
                :typography="UiTypography.TextSmMedium"
                :color="UiColorVar.Secondary900"
                textEllipsis="100%"
              >
                {{ selectedOption.label }}
              </AppText>

              <UiTag
                v-if="selectedOption.tag"
                :label="selectedOption.tag.label"
                :color="selectedOption.tag.color"
                :type="UiTagType.Outline"
                :size="UiTagSize.Xs"
                hasTextEllipsis
              />
            </div>

            <AppText
              v-if="selectedOption.caption"
              tag="div"
              :typography="UiTypography.TextXsMedium"
              :color="UiColorVar.Secondary500"
              textEllipsis="100%"
            >
              {{ selectedOption.caption }}
            </AppText>
          </div>
        </div>
      </template>

      <template #option="{ option, isActive, isFocused }">
        <div
          :class="[
            cn.e('option'),
            cn.em('option', 'list-item'),
            cn.em('option', 'is-active', isActive),
            cn.em('option', 'is-focused', isFocused),
            cn.em('option', 'has-icon', props.hasIcon),
            cn.em('option', 'is-disabled', Boolean(option.disabled)),
          ]"
        >
          <UiAvatar
            v-if="props.hasIcon"
            :size="UiAvatarSize.Sm"
            :src="option.icon"
            :color="props.iconColor"
          >
            <template #placeholder>
              <slot name="icon-placeholder" />
            </template>
          </UiAvatar>

          <div :class="cn.e('option-container')">
            <div :class="cn.e('option-label')">
              <AppText
                tag="span"
                :typography="UiTypography.TextSmMedium"
                :color="
                  Boolean(option.disabled)
                    ? UiColorVar.Secondary500
                    : UiColorVar.Secondary900
                "
                textEllipsis="100%"
              >
                {{ option.label }}
              </AppText>

              <UiTag
                v-if="option.tag"
                :label="option.tag.label"
                :color="option.tag.color"
                :type="UiTagType.Outline"
                :size="UiTagSize.Xs"
                hasTextEllipsis
              />
            </div>

            <div v-if="option.caption" :class="cn.e('option-label')">
              <AppText
                tag="div"
                :typography="UiTypography.TextXsMedium"
                :color="
                  Boolean(option.disabled)
                    ? UiColorVar.Secondary400
                    : UiColorVar.Secondary500
                "
                textEllipsis="100%"
              >
                {{ option.caption }}
              </AppText>

              <UiTag
                v-if="option.captionTag"
                :label="option.captionTag.label"
                :color="option.captionTag.color"
                :type="UiTagType.Outline"
                :size="UiTagSize.Xs"
                hasTextEllipsis
              />
            </div>
          </div>

          <div v-if="isActive" :class="cn.e('option-checkbox')">
            <UiIcon :value="UiIconValue.Check" size="20px" />
          </div>
        </div>
      </template>
    </UiSelect>
  </div>
</template>

<style lang="scss">
.base-select {
  --min-height: 56px;
  .ui-select {
    --ui-select-pseudo-input-min-height: var(--min-height);

    min-height: var(--min-height);

    &__ui-input {
      min-height: calc(var(--min-height) - 2px);
      color: var(--color-secondary-900);
      @include typography('text-sm-medium');
      @include text-ellipsis();
    }

    &__placeholder {
      display: flex;
      align-items: center;
      @include typography('text-sm-medium');
    }
  }

  &__skeleton {
    display: grid;
    width: 100%;
    min-height: var(--min-height);
    height: 100%;
    grid-gap: var(--spacing-03);
    grid-template-columns: 1fr;
    align-items: center;
    padding-left: 14px;
    border: 1px solid var(--color-secondary-300);
    border-radius: var(--border-radius-md);

    &_has-icon {
      grid-template-columns: 32px 1fr;
    }

    &__container {
      display: flex;
      flex-direction: column;
    }
  }

  &__option {
    position: relative;
    display: grid;
    grid-gap: var(--spacing-03);
    grid-template-columns: 1fr;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 8px;

    &_has-icon {
      grid-template-columns: 32px 1fr;
    }

    &_list-item {
      padding: var(--spacing-03) var(--spacing-08) var(--spacing-03)
        var(--spacing-03);
    }

    &_is-focused {
      background-color: var(--color-secondary-50);
    }

    &_is-active {
      background-color: var(--color-secondary-50);
    }

    &_is-disabled {
      cursor: not-allowed;
    }
  }

  &__option-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr 1fr;
  }

  &__option-label {
    display: grid;
    grid-template-columns: minmax(0, min-content) auto;
    align-items: center;
    column-gap: var(--spacing-02);
  }

  &__option-checkbox {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    color: var(--color-primary-600);
  }
}
</style>
