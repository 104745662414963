export enum OverviewStatsStep {
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
}

export enum OverviewStatsType {
  Balance = 'BALANCE',
  Income = 'INCOME',
  Spend = 'SPEND',
}

export enum OverviewFilterDatePeriodName {
  ThisWeek = 'THIS_WEEK',
  ThisMonth = 'THIS_MONTH',
  ThisQuarter = 'THIS_QUARTER',
  ThisYear = 'THIS_YEAR',
  LastSixMonth = 'LAST_SIX_MONTH',
}

export enum OverviewSpendType {
  User = 'USER',
  Card = 'CARD',
  Budget = 'BUDGET',
  Counterparty = 'COUNTERPARTY',
  CompanyAccount = 'COMPANY_ACCOUNT',
}
