import type { Ref } from 'vue';

import type { Nullable } from '@karta.io/ui-components';

import { useEnv } from '@/composables';
import { EnrichError } from '@/helpers';
import {
  CardCredentialsValueStylesDesktop,
  CardCredentialsValueStylesMobile,
} from '@/data';

import type { CardCredentialsToken } from '@/interfaces';

interface InitParams {
  accessToken?: CardCredentialsToken['accessToken'];
  cardPanDomId: string;
  copyCardPanDomId: string;
  cardExpDomId: string;
  copyCardExpDomId: string;
  cardCvvDomId: string;
  copyCardCvvDomId: string;
  onCopySuccess: (elementName: string) => void;
  onCopyFailure: () => void;
}

const { marqetaScriptUrl } = useEnv();

const SCRIPT_ID = 'marqeta-script';

export function useCardMarqetaSdk(
  isMobile: Readonly<Ref<boolean>> = ref(false),
) {
  const logger = useLogger('use-card-marqeta-sdk');

  let savedInitParams: Nullable<InitParams> = null;
  const marqetaScriptError = new EnrichError({
    message: 'Function not available!',
  });

  const isReady = ref(false);
  const isCredentialsLoading = ref(false);

  const valueStyles = computed(() =>
    isMobile.value
      ? CardCredentialsValueStylesMobile
      : CardCredentialsValueStylesDesktop,
  );

  const install = () => {
    if (!marqetaScriptUrl) return;

    const scriptFromDOM = document.getElementById(SCRIPT_ID);

    if (scriptFromDOM) {
      logger.log('Script is already installed');
      return;
    }

    const script = document.createElement('script');
    script.async = true;
    script.src = marqetaScriptUrl;
    script.id = SCRIPT_ID;

    script.onload = () => {
      logger.log('Script installed');
    };

    script.onerror = () => {
      logger.error('Script did not install');
    };

    document.head.appendChild(script);
  };

  const init = (params: InitParams) => {
    try {
      if (!window.marqeta || !params.accessToken) throw marqetaScriptError;

      isCredentialsLoading.value = true;
      savedInitParams = params;

      isReady.value = false;

      window.marqeta?.bootstrap({
        clientAccessToken: params.accessToken,
        component: {
          showPan: {
            cardPan: {
              domId: params.cardPanDomId,
              format: true,
              styles: {
                span: valueStyles.value,
              },
            },
            copyCardPan: {
              domId: params.copyCardPanDomId,
              mode: 'transparent',
              onCopySuccess: () => params.onCopySuccess('Number'),
              onCopyFailure: params.onCopyFailure,
            },
            cardExp: {
              domId: params.cardExpDomId,
              format: true,
              styles: {
                span: valueStyles.value,
              },
            },
            copyCardExp: {
              domId: params.copyCardExpDomId,
              mode: 'transparent',
              onCopySuccess: () => params.onCopySuccess('Expire date'),
              onCopyFailure: params.onCopyFailure,
            },
            cardCvv: {
              domId: params.cardCvvDomId,
              styles: {
                span: valueStyles.value,
              },
            },
            copyCardCvv: {
              domId: params.copyCardCvvDomId,
              mode: 'transparent',
              onCopySuccess: () => params.onCopySuccess('CVV'),
              onCopyFailure: params.onCopyFailure,
            },
          },
        },
        callbackEvents: {
          onSuccess: () => {
            isReady.value = true;
            isCredentialsLoading.value = false;
            logger.log('marqeta.bootstrap success');
          },
          onFailure: (error: any) => {
            isReady.value = false;
            isCredentialsLoading.value = false;
            logger.error('marqeta.bootstrap error', error);
          },
        },
      });
    } catch (error) {
      logger.error('init error', error);
      isCredentialsLoading.value = false;
    }
  };

  watch(isMobile, () => {
    if (!savedInitParams) return;

    isReady.value = false;
    isCredentialsLoading.value = true;
    init(savedInitParams);
  });

  onBeforeUnmount(() => {
    if (!window.marqeta) return;

    savedInitParams = null;
    window.marqeta.destroy();
  });

  return {
    valueStyles,
    isReady,
    isCredentialsLoading,

    init,
    install,
  };
}
