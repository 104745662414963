/**
 * @todo пернести в karta.io/utils
 */
import { snakeCase } from 'lodash-es';

type SnakelizeKeysProp = Record<string | number, any>;

export function snakelizeKeys(prop: SnakelizeKeysProp): SnakelizeKeysProp {
  if (Array.isArray(prop)) {
    return prop.map(item => snakelizeKeys(item));
  }

  if (prop !== null && prop !== undefined && prop.constructor === Object) {
    return Object.keys(prop).reduce(
      (accum, current) => ({
        ...accum,
        [snakeCase(current)]: snakelizeKeys(prop[current]),
      }),
      {},
    );
  }

  return prop;
}
