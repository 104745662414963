import { useLogger } from '@karta.io/utils';

import { useCoreStore } from '@/stores';
import { updateCoreUiSettings } from '@/api';

import type { CryptoNetwork, CryptoToken } from '@/enums';
import type { UiSettings } from '@/interfaces';

const logger = useLogger('use-core-ui-settings');

export function useCoreUiSettings() {
  const isLoading = ref(false);

  const update = async (newData: UiSettings) => {
    const coreStore = useCoreStore();

    isLoading.value = true;

    try {
      const data = await updateCoreUiSettings(newData);
      coreStore.uiSettings = data;
    } catch (e) {
      logger.error('update', e);
    } finally {
      isLoading.value = false;
    }
  };

  const updatePageFilters = async ({
    name,
    id,
    value,
  }: {
    name: string;
    id: string | number;
    value: string;
  }): Promise<void> => {
    const coreStore = useCoreStore();

    const newUiSettings: UiSettings = {
      ...coreStore.uiSettings,
      filters: {
        ...coreStore.uiSettings?.filters,
        [name]: { ...coreStore.uiSettings?.filters?.[name], [id]: value },
      },
    };

    coreStore.uiSettings = newUiSettings;

    await update(newUiSettings);
  };

  const updateLastUsedCryptoWallet = async (data: {
    network: CryptoNetwork;
    tokenCode: CryptoToken;
  }): Promise<void> => {
    const coreStore = useCoreStore();

    const newUiSettings: UiSettings = {
      ...coreStore.uiSettings,
      lastUsedCryptoWallet: data,
    };

    coreStore.uiSettings = newUiSettings;

    await update(newUiSettings);
  };

  const updateSubAccountCreatePanelAlert = async (
    { isHidden }: { isHidden: boolean } = { isHidden: true },
  ) => {
    const coreStore = useCoreStore();

    const newUiSettings: UiSettings = {
      ...coreStore.uiSettings,
      isSubAccountCreatePanelAlertHidden: isHidden,
    };

    coreStore.uiSettings = newUiSettings;

    await update(newUiSettings);
  };

  return {
    isLoading,

    updatePageFilters,
    updateLastUsedCryptoWallet,
    updateSubAccountCreatePanelAlert,
  };
}
