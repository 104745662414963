<script lang="ts" setup>
import { UiSkeletonTextType } from '@karta.io/ui-components';

interface Props {
  align: 'left' | 'right';
}

defineOptions({ name: 'TableCellSkeletonDefault' });

const props = defineProps<Props>();

const cn = useClassName('table-cell-skeleton-default');
</script>

<template>
  <div :class="[cn.b(), cn.mv('align', props.align)]">
    <UiSkeletonText :type="UiSkeletonTextType.TextXs" width="70%" />
  </div>
</template>

<style lang="scss">
.table-cell-skeleton-default {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;

  &_align_left {
    justify-content: flex-start;
  }

  &_align_right {
    justify-content: flex-end;
  }
}
</style>
