import { CompanyBusinessType, CompanyScope } from '@/enums';

export const ONBOARDING_COMPANY_SCOPE = [
  {
    label: 'Ecommerce',
    value: CompanyScope.Ecommerce,
  },
  {
    label: 'Affiliate marketing',
    value: CompanyScope.Affiliate,
  },
  {
    label: 'Dropshipping',
    value: CompanyScope.Dropshipping,
  },
  {
    label: 'Marketing agency',
    value: CompanyScope.Marketing,
  },
  {
    label: 'Startup',
    value: CompanyScope.Startup,
  },
  {
    label: 'Other',
    value: CompanyScope.Other,
  },
];

export const ONBOARDING_COMPANY_BUSINESS_TYPES = [
  {
    label: 'Company',
    value: CompanyBusinessType.Company,
  },
  /**
   * Временно убираем Individual тип для предотвращения фрода
   */
  // {
  //   label: 'Individual',
  //   value: CompanyBusinessType.Individual,
  // },
];
