import { useEnv } from '@/composables';

const { websiteBaseUrl } = useEnv();

export const getAppLegalLink = (slug: string, version?: number) => {
  const origin = websiteBaseUrl;
  const pathname = `/legals/${slug}`;
  const search = version ? `?version=${version}` : '';

  return `${origin}${pathname}${search}`;
};
