import SmartySDK from 'smartystreets-javascript-sdk';

import { useEnv } from './index';

const { smartyKey } = useEnv();

const joinAddress = (
  suggestion: SmartySDK.usAutocompletePro.Suggestion,
  forSelected = false,
): string => {
  const secondary = suggestion.secondary ? ` ${suggestion.secondary}` : '';
  const entries =
    suggestion.entries > 1 ? ` (${suggestion.entries} entries)` : '';
  const addressForView = `${suggestion.streetLine + secondary + entries} ${
    suggestion.city
  }, ${suggestion.state} ${suggestion.zipcode}`;

  const addressForSelected = `${suggestion.streetLine + secondary} (${
    suggestion.entries
  }) ${suggestion.city} ${suggestion.state} ${suggestion.zipcode}`;

  return forSelected ? addressForSelected : addressForView;
};

export function useAddressAutocompleteUs() {
  const SmartyCore = SmartySDK.core;
  const Lookup = SmartySDK.usAutocompletePro.Lookup;

  const credentials = new SmartyCore.SharedCredentials(smartyKey);

  const clientBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses([
    'us-autocomplete-pro-cloud',
  ]);

  const loading = ref(false);
  const suggestions = ref<SmartySDK.usAutocompletePro.Suggestion[]>();
  const options = computed<{ label: string; value: number }[]>(
    () =>
      suggestions.value?.map((suggestion, index) => {
        return {
          label: joinAddress(suggestion),
          value: index,
        };
      }) ?? [],
  );

  const client = clientBuilder.buildUsAutocompleteProClient();

  const searchSuggestions = async (
    query: string,
    selectedSuggestion?: SmartySDK.usAutocompletePro.Suggestion,
  ) => {
    loading.value = true;
    try {
      const lookup = new Lookup(query);

      if (selectedSuggestion) {
        lookup.selected = joinAddress(selectedSuggestion, true);
      }

      const response = await client.send(lookup);
      suggestions.value = response.result;
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  };

  const searchSuggestionsWithDebounce = useDebounceFn(searchSuggestions, 300);

  return {
    loading,
    suggestions,
    options,
    searchSuggestions,
    searchSuggestionsWithDebounce,
  };
}
