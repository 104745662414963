import { type HttpRequestConfig, convertParams, http } from '@/api';
import type {
  AccountsBalances,
  CompanyAccount,
  CompanyAccountCreateRequest,
  CompanyAccountEditableData,
  CompanyAccountReadRequest,
  CompanyAccountStatementCreateRequest,
  CompanyAccountsReadRequest,
  ResponsePaginated,
} from '@/interfaces';

const BASE_PATH = 'karta/company_account/';

export const createCompanyAccount = (
  data: CompanyAccountCreateRequest,
): Promise<CompanyAccount> => http.post(`${BASE_PATH}`, data);

/**
 * readCompanyAccounts
 */
export const readCompanyAccounts = (
  params: CompanyAccountsReadRequest,
  config?: HttpRequestConfig,
): Promise<ResponsePaginated<CompanyAccount>> =>
  http.get(BASE_PATH, {
    params: convertParams(params, undefined, { name: 'readCompanyAccounts' }),
    ...config,
  });

/**
 * readCompanyAccountById
 */
export const readCompanyAccountById = (
  id: number,
  params?: CompanyAccountReadRequest,
): Promise<CompanyAccount> =>
  http.get(`${BASE_PATH}${id}/`, {
    params: convertParams(
      params,
      {
        isSearchable: false,
        isPaginatable: false,
        isFilterable: false,
        isSortable: false,
      },
      { name: 'readCompanyAccountById' },
    ),
  });

/**
 * updateCompanyAccount
 */
export const updateCompanyAccount = (
  id: number,
  /**
   * @todo create new interface CompanyAccountUpdateRequest
   */
  data: CompanyAccountEditableData,
): Promise<Partial<CompanyAccount>> => http.patch(`${BASE_PATH}${id}/`, data);

/**
 * statement
 */
export const createCompanyAccountStatement = (
  id: CompanyAccount['id'],
  data: CompanyAccountStatementCreateRequest,
): Promise<void> => http.post(`${BASE_PATH}${id}/statement/`, data);

/**
 * Balances
 */
export const readCompanyAccountsBalancesAvailable = (
  config?: HttpRequestConfig,
): Promise<AccountsBalances> =>
  http.get(`${BASE_PATH}balance_available/`, config);

export const readCompanyAccountsBalancesPending = (
  config?: HttpRequestConfig,
): Promise<AccountsBalances> =>
  http.get(`${BASE_PATH}balance_pending/`, config);
