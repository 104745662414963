<script setup lang="ts">
defineOptions({
  name: 'LayoutEmpty',
});

const props = defineProps<{
  isSignOutHidden?: boolean;
}>();

const cn = useClassName('layout-empty');
</script>

<template>
  <div :class="cn.b()">
    <div :class="cn.e('banners')">
      <Banners />
    </div>

    <div :class="cn.e('header')">
      <TheHeaderAlternative :isSignOutHidden="props.isSignOutHidden" />
    </div>

    <div :class="cn.e('main')">
      <RouterView />
    </div>
  </div>
</template>

<style lang="scss">
.layout-empty {
  position: relative;

  &__banners {
    position: sticky;
    top: 0;
    left: 0;
    z-index: var(--banners-z-index);
  }

  &__header {
    position: sticky;
    top: var(--banners-height);
    background-color: transparent;
    z-index: var(--the-header-z-index);
  }
}
</style>
