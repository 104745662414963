import { CompanyIncorporationType } from '@/enums';

export const CompanyIncorporationTypesMap = {
  [CompanyIncorporationType.Llc]: {
    title: 'LLC',
  },
  [CompanyIncorporationType.Corporation]: {
    title: 'Corporation',
  },
  [CompanyIncorporationType.SoleProprietorship]: {
    title: 'Sole Proprietorship',
  },
  [CompanyIncorporationType.Partnership]: {
    title: 'Partnership',
  },
  [CompanyIncorporationType.Cooperative]: {
    title: 'Cooperative',
  },
  [CompanyIncorporationType.Other]: {
    title: 'Other',
  },
} as const;

export const COMPANY_INDUSTRIES = [
  {
    label: 'Accounting and Bookkeeping',
    value: '8931',
  },
  {
    label: 'Advertising',
    value: '7311',
  },
  {
    label: 'Airlines',
    value: '4511',
  },
  {
    label: 'Art',
    value: '5971',
  },
  {
    label: 'B2B Software',
    value: '7372',
  },
  {
    label: 'Beauty and Cosmetic',
    value: '5977',
  },
  {
    label: 'Camera and Photographic Stores',
    value: '5946',
  },
  {
    label: 'Cleaning',
    value: '7349',
  },
  {
    label: 'Consulting',
    value: '7392',
  },
  {
    label: 'Ecommerce',
    value: '7278',
  },
  {
    label: 'Education',
    value: '8299',
  },
  {
    label: 'Financial Services',
    value: '6012',
  },
  {
    label: 'Fitness',
    value: '7941',
  },
  {
    label: 'Hardware',
    value: '5251',
  },
  {
    label: 'Health and Beauty',
    value: '7298',
  },
  {
    label: 'Legal',
    value: '8111',
  },
  {
    label: 'Marketplace',
    value: '7278',
  },
  {
    label: 'Media',
    value: '5815',
  },
  {
    label: 'Professional Services',
    value: '8999',
  },
  {
    label: 'Real Estate',
    value: '6513',
  },
  {
    label: 'Restaurant',
    value: '5812',
  },
  {
    label: 'Software',
    value: '7372',
  },
  {
    label: 'Transportation Services',
    value: '4789',
  },
  {
    label: 'Travel',
    value: '4722',
  },
  {
    label: 'Video Production',
    value: '7829',
  },
];
