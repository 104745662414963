<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue';
import {
  UiButtonSize,
  UiButtonTheme,
  UiDrawerDirection,
  UiIconValue,
} from '@karta.io/ui-components';

import { useStagingManagement } from '@/composables';

interface Props {
  modelValue: boolean;
}

defineOptions({ name: 'TheMobileMenu' });
const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
});
const emit = defineEmits<{ 'update:modelValue': [value: boolean] }>();

const cn = useClassName('the-mobile-menu');
const {
  openModal: openStagingManagementModal,
  isButtonShown: isStagingManagementShown,
  isUrlCustom: isApiBaseUrlCustom,
} = useStagingManagement();

const handleApiButtonClick = () => {
  closeMenu();
  openStagingManagementModal();
};

const handleLogoutButtonClick = () => closeMenu();

const visible = computed({
  get: () => props.modelValue,
  set: newValue => {
    emit('update:modelValue', newValue);
  },
});

const closeMenu = () => {
  emit('update:modelValue', false);
};

const { getAccessTokenSilently } = useAuth0();
const handleTokenButtonClick = async () => {
  try {
    const token = await getAccessTokenSilently();
    await navigator.clipboard.writeText(token);

    UiToastNotify({
      title: 'Bearer token copied to clipboard!',
      isUpdateTimerByMouseAction: false,
      duration: 2000,
    });
  } catch (error) {
    console.error(error);
  }
};
</script>

<template>
  <UiDrawer
    v-model="visible"
    :direction="UiDrawerDirection.Left"
    uniqueModifier="the-mobile-menu"
    :hasCloseButton="false"
  >
    <template #header>
      <div :class="cn.e('header')">
        <router-link to="/" :class="cn.e('logo')">
          <IconLogo />
        </router-link>

        <UiButton
          :theme="UiButtonTheme.SecondaryTertiary"
          :size="UiButtonSize.Sm"
          :iconOnly="UiIconValue.ChevronLeft"
          @click="closeMenu"
        />
      </div>
    </template>

    <template #body>
      <div :class="cn.e('navigation')">
        <Navigation @onItemClick="closeMenu" />
      </div>
    </template>

    <template #footer>
      <div :class="cn.e('exchange-rate')">
        <CoreExchangeRate popoverMaxWidth="280px" />
      </div>

      <div :class="cn.e('footer')">
        <div v-if="isStagingManagementShown" :class="cn.e('service-buttons')">
          <UiButton
            :theme="UiButtonTheme.SecondaryOutline"
            :size="UiButtonSize.Xs"
            prefixIcon="oui:token-key"
            isFullWidth
            @click="handleTokenButtonClick"
          >
            Token
          </UiButton>

          <UiButton
            :theme="
              isApiBaseUrlCustom
                ? UiButtonTheme.PrimaryOutline
                : UiButtonTheme.SecondaryOutline
            "
            :size="UiButtonSize.Xs"
            :prefixIcon="UiIconValue.Chip"
            isFullWidth
            @click="handleApiButtonClick"
          >
            API
          </UiButton>
        </div>

        <div :class="cn.e('footer-inner')">
          <UserLogout @click="handleLogoutButtonClick" />
        </div>
      </div>
    </template>
  </UiDrawer>
</template>

<style lang="scss">
.the-mobile-menu {
  width: 100%;
  height: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    padding: 0 var(--spacing-02) 0 var(--spacing-06);
    background-color: var(--color-secondary-50);
  }

  &__logo {
    display: inline-block;
  }

  &__navigation {
    background-color: var(--color-secondary-50);
  }

  &__footer {
    padding: 0 var(--spacing-06);
    background-color: var(--color-secondary-50);
  }

  &__exchange-rate {
    padding: var(--spacing-04);
    background-color: var(--color-secondary-50);
  }

  &__service-buttons {
    display: flex;
    gap: var(--spacing-04);
    padding-bottom: var(--spacing-04);
    background-color: transparent;
  }

  &__footer-inner {
    padding: var(--spacing-06) 0;
    border-top: 1px solid var(--color-secondary-200);
  }
}

.ui-drawer_the-mobile-menu {
  --ui-drawer-width: 312px;
  --ui-drawer-header-padding-y: 0;
  --ui-drawer-header-padding-x: 0;
  --ui-drawer-header-background-color: transparent;
  --ui-drawer-header-border-bottom-color: var(--color-secondary-50);

  --ui-drawer-body-padding-y: 0;
  --ui-drawer-body-padding-x: 0;

  --ui-drawer-footer-padding-y: 0;
  --ui-drawer-footer-padding-x: 0;
  --ui-drawer-footer-background-color: transparent;
  --ui-drawer-footer-border-top-color: var(--color-secondary-50);

  .ui-scrollbar__content {
    height: 100%;
    background-color: var(--color-secondary-50);
  }
}
</style>
