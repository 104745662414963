import type { RouteRecordRaw } from 'vue-router';

import { permissionsGuard } from '@/router/guards';
import { CoreUserPermissionsMap } from '@/data';
import {
  EXTERNAL_ACCOUNT_INDEX_PAGE,
  EXTERNAL_ACCOUNT_TRANSACTIONS_PAGE,
} from './data';

export const externalAccountsRoutes: RouteRecordRaw[] = [
  {
    path: '/accounts/external/:id',
    name: EXTERNAL_ACCOUNT_INDEX_PAGE.name,
    component: () =>
      import('@/modules/external-accounts/pages/ExternalAccountIndexPage.vue'),
    beforeEnter: permissionsGuard,
    meta: {
      auth: true,
      navigation: EXTERNAL_ACCOUNT_INDEX_PAGE.navigation,
      permissions: [CoreUserPermissionsMap.ReadExternalAccount],
    },
    children: [
      {
        path: 'transactions',
        name: EXTERNAL_ACCOUNT_TRANSACTIONS_PAGE.name,
        component: () =>
          import(
            '@/modules/external-accounts/pages/ExternalAccountTransactionsPage.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          auth: true,
          navigation: EXTERNAL_ACCOUNT_INDEX_PAGE.navigation,
          permissions: [CoreUserPermissionsMap.ReadExternalTransaction],
        },
      },
    ],
  },
];

export default externalAccountsRoutes;
