<script setup lang="ts">
import { useResizeObserver } from '@vueuse/core';

import { useCoreStore } from '@/stores';
import { useStagingManagement } from '@/composables';
import { CoreLocalStorage } from '@/enums';

defineOptions({ name: 'Banners' });

const coreStore = useCoreStore();

const root = document.querySelector(':root');

const banners = ref(null);

const { isUrlCustom: isApiBaseUrlCustom } = useStagingManagement();

const isBannedCountryBannerHidden = useStorage<boolean>(
  CoreLocalStorage.IsBannedCountryBannerHidden,
  false,
);

useResizeObserver(banners, entries => {
  const entry = entries[0];
  const { height } = entry.contentRect;
  root?.style?.setProperty('--banners-height', `${height}px`);
});
</script>

<template>
  <div ref="banners" class="banners">
    <StagingManagementBanner v-if="isApiBaseUrlCustom" />

    <CoreBannedCountryBanner
      v-if="coreStore.isCountryBanned && !isBannedCountryBannerHidden"
    />
  </div>
</template>
