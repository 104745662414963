import { UiIconValue } from '@karta.io/ui-components';

import { NavigationGroup } from '@/enums';

import { CoreUserPermissionsMap } from '@/data';
import type { NavigationItem, Page } from '@/interfaces';

export const CARDS_NAVIGATION: NavigationItem = {
  id: 'cards',
  title: 'Virtual cards',
  to: '/cards',
  icon: UiIconValue.CreditCard,
  permissions: [CoreUserPermissionsMap.ReadCard],
  disabled: false,
  group: (hasCompanyAccounts, hasExternalAccounts, isCompanyAccountFunded) =>
    hasCompanyAccounts && isCompanyAccountFunded
      ? NavigationGroup.Active
      : NavigationGroup.Inactive,
};

export const CARDS_INDEX_PAGE: Page = {
  name: 'cards-index-page',
  metaInfo: {
    title: 'Virtual cards',
  },
  navigation: CARDS_NAVIGATION,
};
