import { UiColorVar } from '@karta.io/ui-components';
import type { AppFilterTagDatePreset } from '@karta.io/app-components';

import quarterOfYear from 'dayjs/plugin/quarterOfYear';

import {
  ACCOUNTS_COMPANY_PAGE,
  BUDGETS_INDEX_PAGE,
  CARDS_INDEX_PAGE,
  COMPANY_USERS_INDEX_PAGE,
  COUNTERPARTIES_INDEX_PAGE,
} from '@/data';
import {
  FilterType,
  OverviewFilterDatePeriodName,
  OverviewSpendType,
} from '@/enums';
import type { FiltersConfig, OverviewStatsFilters } from '@/interfaces';

const dayjs = useDayjs();
dayjs.extend(quarterOfYear);

export const OverviewTopListColorMap = [
  UiColorVar.Primary500,
  // var(--Chart-Red)
  '#F86754',
  // var(--Chart-Orange)
  '#F9A252',
  // var(--Chart-Yellow)
  '#FFC568',
  UiColorVar.Secondary200,
];

export const OverviewStatsFiltersConfig: FiltersConfig<OverviewStatsFilters> = {
  step: {
    type: FilterType.String,
  },
  statsType: {
    type: FilterType.String,
  },
  periodName: {
    type: FilterType.String,
  },
  periodDates: {
    type: FilterType.Array,
  },
} as const;

export const getOverviewFilterDateDefaultPeriod = () => ({
  start: dayjs().startOf('month').toDate(),
  end: dayjs().endOf('month').toDate(),
});

export const getOverviewFilterDatePresets = (): AppFilterTagDatePreset[] => [
  {
    start: dayjs().startOf('week').toDate(),
    end: dayjs().endOf('week').toDate(),
    listTitle: 'This week',
    clearable: true,
    triggerValueData: 'This week',
    periodName: OverviewFilterDatePeriodName.ThisWeek,
    group: 1,
  },
  {
    start: dayjs().startOf('month').toDate(),
    end: dayjs().endOf('month').toDate(),
    listTitle: 'This month',
    triggerValueData: dayjs().startOf('month').format('MMMM'),
    clearable: false,
    triggerValueColor: UiColorVar.Secondary500,
    periodName: OverviewFilterDatePeriodName.ThisMonth,
    group: 1,
  },
  {
    start: dayjs().startOf('quarter').toDate(),
    end: dayjs().endOf('quarter').toDate(),
    listTitle: 'This quarter',
    clearable: true,
    periodName: OverviewFilterDatePeriodName.ThisQuarter,
    group: 1,
  },
  {
    start: dayjs().startOf('year').toDate(),
    end: dayjs().endOf('year').toDate(),
    listTitle: 'This year',
    clearable: true,
    periodName: OverviewFilterDatePeriodName.ThisYear,
    group: 1,
  },
  {
    start: dayjs().subtract(6, 'month').startOf('month').toDate(),
    end: dayjs().subtract(1, 'month').endOf('month').toDate(),
    listTitle: 'Last six month',
    clearable: true,
    periodName: OverviewFilterDatePeriodName.LastSixMonth,
    group: 2,
  },
];

export const OverviewSpendTypeMap = {
  [OverviewSpendType.User]: {
    label: 'Users',
    pageName: COMPANY_USERS_INDEX_PAGE.name,
    ordering: '-spend',
    hasQuery: true,
  },
  [OverviewSpendType.Card]: {
    label: 'Virtual cards',
    pageName: CARDS_INDEX_PAGE.name,
    ordering: '-spend',
    hasQuery: true,
  },
  [OverviewSpendType.Budget]: {
    label: 'Budgets',
    pageName: BUDGETS_INDEX_PAGE.name,
    ordering: '-spend',
    hasQuery: true,
  },
  [OverviewSpendType.Counterparty]: {
    label: 'Counterparties',
    pageName: COUNTERPARTIES_INDEX_PAGE.name,
    ordering: 'transactions_sum',
    hasQuery: true,
  },
  [OverviewSpendType.CompanyAccount]: {
    label: 'Accounts',
    pageName: ACCOUNTS_COMPANY_PAGE.name,
    ordering: '',
    hasQuery: false,
  },
};
