import { UiIconValue } from '@karta.io/ui-components';

import { NavigationGroup } from '@/enums';
import type { NavigationItem, Page } from '@/interfaces';
import { CoreUserPermissionsMap } from './core.data';

export const CORE_ROOT_PAGE = {
  name: 'core-root-page',
};

export const CORE_ACCOUNT_NAVIGATION: NavigationItem = {
  id: 'accounts',
  title: 'Accounts',
  to: '/accounts',
  icon: UiIconValue.Library,
  permissions: [CoreUserPermissionsMap.ReadCompanyAccount],
  disabled: false,
  group: () => NavigationGroup.Active,
};

export const CORE_COMPANY_ACCOUNTS_PAGE: Page = {
  name: 'core-company-accounts-page',
  metaInfo: {
    title: 'Company accounts',
  },
  navigation: CORE_ACCOUNT_NAVIGATION,
};

export const CORE_EXTERNAL_ACCOUNTS_PAGE: Page = {
  name: 'core-external-accounts-page',
  metaInfo: {
    title: 'External accounts',
  },
  navigation: CORE_ACCOUNT_NAVIGATION,
};

export const CORE_ACCOUNTS_INDEX_PAGE: Page = {
  name: 'core-accounts-index-page',
  metaInfo: {
    title: 'Accounts',
  },
  navigation: CORE_ACCOUNT_NAVIGATION,
  tabs: [
    {
      label: 'Karta accounts',
      value: CORE_COMPANY_ACCOUNTS_PAGE.name,
      disabled: false,
    },
    {
      label: 'External accounts',
      value: CORE_EXTERNAL_ACCOUNTS_PAGE.name,
      disabled: false,
    },
  ],
};

export const CORE_COMPANY_DECLINED_INDEX_PAGE: Page = {
  name: 'core-company-declined-index-page',
  metaInfo: {
    title: 'Sorry, we can’t let you in',
  },
};

export const CORE_ERROR_INDEX_PAGE: Page = {
  name: 'core-error-index-page',
  metaInfo: {
    title: 'Something went wrong',
  },
};

export const CORE_MAINTENANCE_INDEX_PAGE: Page = {
  name: 'core-maintenance-index-page',
  metaInfo: {
    title: 'Under maintenance',
  },
};

export const CORE_FORBIDDEN_INDEX_PAGE = {
  name: 'core-forbidden-index-page',
  metaInfo: {
    title: 'Access denied',
  },
};

export const CORE_COMPANY_BLOCKED_INDEX_PAGE: Page = {
  name: 'core-company-blocked-index-page',
  metaInfo: {
    title: 'Company blocked',
  },
};

export const CORE_COMPANY_WAITING_DOCUMENTS_INDEX_PAGE: Page = {
  name: 'core-company-waiting-documents-index-page',
  metaInfo: {
    title: 'Waiting documents',
  },
};

export const CORE_COMPANY_USER_BLOCKED_INDEX_PAGE: Page = {
  name: 'core-company-user-blocked-index-page',
  metaInfo: {
    title: 'User blocked',
  },
};

export const CORE_COMPANY_USER_DELETED_INDEX_PAGE: Page = {
  name: 'core-company-user-deleted-index-page',
  metaInfo: {
    title: 'User deleted',
  },
};

export const CORE_USER_BLOCKED_INDEX_PAGE: Page = {
  name: 'core-user-blocked-index-page',
  metaInfo: {
    title: 'User blocked',
  },
};

export const CORE_ORGANIZATION_SELECTION_PAGE: Page = {
  name: 'core-organization-selection-page',
  metaInfo: {
    title: 'Organizations',
  },
};

export const CORE_SETTINGS_TOKENS_PAGE: Page = {
  name: 'core-settings-tokens-page',
  metaInfo: {
    title: 'API tokens',
  },
};

export const CORE_SETTINGS_BILLING_PAGE: Page = {
  name: 'core-settings-billing-page',
  metaInfo: {
    title: 'Billing',
  },
};

export const CORE_SETTINGS_INDEX_PAGE: Page = {
  name: 'core-settings-index-page',
  metaInfo: {
    title: 'Settings',
  },
  tabs: [
    {
      label: 'Personal',
      value: '',
      disabled: true,
    },
    {
      label: 'Company',
      value: '',
      disabled: true,
    },
    {
      label: 'Billing',
      value: CORE_SETTINGS_BILLING_PAGE.name,
      disabled: false,
    },
    {
      label: 'API Token',
      value: CORE_SETTINGS_TOKENS_PAGE.name,
      disabled: false,
    },
  ],
};

export const CORE_NOT_FOUND_INDEX_PAGE: Page = {
  name: 'core-not-found-index-page',
  metaInfo: {
    title: 'Not found',
  },
};
