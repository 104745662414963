<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue';

import {
  UiButtonSize,
  UiButtonTheme,
  UiIconValue,
} from '@karta.io/ui-components';

import { useCoreStore } from '@/stores';
import { useEnv } from '@/composables';
import { CORE_ROOT_PAGE } from '@/data';

defineOptions({
  name: 'TheHeaderAlternative',
});

const props = defineProps<{
  isSignOutHidden?: boolean;
}>();

const { isAuthenticated, logout } = useAuth0();
const router = useRouter();
const route = useRoute();
const { isDemo, isApp } = useEnv();
const coreStore = useCoreStore();
const cn = useClassName('the-header-alternative');

const isCloseButtonShow = computed(
  () => Boolean(route.query?.returnToName) || Boolean(route.query?.returnToUrl),
);

const isLogoutButtonShow = computed(
  () => !props.isSignOutHidden && isApp && isAuthenticated.value,
);

const handleBack = () => {
  if (!isCloseButtonShow.value) return;

  const returnToName = route.query?.returnToName as string;
  const returnToUrl = route.query?.returnToUrl as string;

  if (returnToName) {
    router.push({ name: returnToName });
    return;
  }

  router.push(returnToUrl ?? { name: CORE_ROOT_PAGE.name });
};

const handleLogout = () => {
  logout({
    logoutParams: {
      returnTo: `${window.location.origin}/auth/logout`,
    },
  });
};

const handleLinkClick = () => {
  if (route.meta.auth) return;
  coreStore.$patch({ isReadyForWork: false, isGlobalLoaderShow: true });
};
</script>

<template>
  <header :class="cn.b()">
    <router-link to="/" :class="cn.e('logo')" @click="handleLinkClick">
      <IconLogo />
    </router-link>

    <UiButton
      v-if="isCloseButtonShow"
      :size="UiButtonSize.Xxl"
      :theme="UiButtonTheme.SecondaryLink"
      :iconOnly="UiIconValue.X"
      @click="handleBack"
    />

    <UiButton
      v-else-if="isLogoutButtonShow"
      :size="UiButtonSize.Xl"
      :theme="UiButtonTheme.PrimaryTertiary"
      :disabled="isDemo"
      @click="handleLogout"
    >
      Log Out
    </UiButton>
  </header>
</template>

<style lang="scss">
.the-header-alternative {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--the-header-alternative-height);
  padding: 0 var(--spacing-04);
  background-color: var(--color-base-white);

  &__logo {
    display: inline-block;
    margin-left: var(--spacing-03);
  }
}
</style>
