import { UiColor } from '@karta.io/ui-components';

import {
  BudgetLimitType,
  BudgetStatus,
  BudgetUserRole,
  Currency,
  FilterType,
} from '@/enums';

import type {
  BudgetLimitTypeOption,
  BudgetUsersFilters,
  BudgetsFilters,
  FiltersConfig,
} from '@/interfaces';

export const BudgetLimitsMap = {
  [BudgetLimitType.NoLimit]: {
    title: 'No limit',
    titleShort: 'No limit',
    titleLong: 'Unlimited budget',
    titlePersonal: 'No personal limit',
    titleMyPersonal: 'My unlimited budget',
    description:
      'The current budget will have no limit. The only limitation is the account balance.',
    descriptionPersonal:
      'The current member will have no limit. The only limitation is the Budget balance.',
  },
  [BudgetLimitType.Daily]: {
    title: 'Daily limit',
    titleShort: 'Daily',
    titleLong: 'Daily budget limit',
    titlePersonal: 'Daily personal limit',
    titleMyPersonal: 'My daily limit',
    description: 'Limit applies to a day, starting at midnight.',
    descriptionPersonal: 'Limit applies to a day, starting at midnight.',
  },
  [BudgetLimitType.Weekly]: {
    title: 'Weekly limit',
    titleShort: 'Weekly',
    titleLong: 'Weekly budget limit',
    titlePersonal: 'Weekly personal limit',
    titleMyPersonal: 'My weekly limit',
    description: 'Limit applies to a week, starting on Sunday midnight.',
    descriptionPersonal:
      'Limit applies to a week, starting on Sunday midnight.',
  },
  [BudgetLimitType.Monthly]: {
    title: 'Monthly limit',
    titleShort: 'Monthly',
    titleLong: 'Monthly budget limit',
    titlePersonal: 'Monthly personal limit',
    titleMyPersonal: 'My monthly limit',
    description: 'Limit applies to a month, starting on 1st.',
    descriptionPersonal: 'Limit applies to a month, starting on 1st.',
  },
  [BudgetLimitType.Year]: {
    title: 'Yearly limit',
    titleShort: 'Yearly',
    titleLong: 'Yearly budget limit',
    titlePersonal: 'Year personal limit',
    titleMyPersonal: 'My yearly limit',
    description: 'Limit applies to a year, starting on January 1st.',
    descriptionPersonal: 'Limit applies to a year, starting on January 1st.',
  },
  [BudgetLimitType.Lifetime]: {
    title: 'Lifetime limit',
    titleShort: 'Lifetime',
    titleLong: 'Lifetime budget limit',
    titlePersonal: 'Lifetime personal limit',
    titleMyPersonal: 'My lifetime limit',
    description: 'Limit applies to all transactions.',
    descriptionPersonal: 'Limit applies to all transactions.',
  },
  [BudgetLimitType.Transaction]: {
    title: 'Transaction limit',
    titleShort: 'Per transaction',
    titleLong: 'Per-transaction budget limit',
    titlePersonal: 'Transaction personal limit',
    titleMyPersonal: 'My per-transaction budget limit',
    description: 'Limit applies to each transaction.',
    descriptionPersonal: 'Limit applies to each transaction.',
  },
} as const;

export const BudgetStatusesMap = {
  [BudgetStatus.Active]: {
    tagLabel: 'Active',
    tagColor: UiColor.Success,
  },
  [BudgetStatus.Locked]: {
    tagLabel: 'Locked',
    tagColor: UiColor.Primary,
  },
  [BudgetStatus.Closed]: {
    tagLabel: 'Closed',
    tagColor: UiColor.Error,
  },
} as const;

export const BudgetUserRolesMap = {
  [BudgetUserRole.Admin]: {
    title: 'Budget admin',
    titleShort: 'Admin',
    description:
      'Budget admin can change all Budget settings such as name, purpose, limit, members.',
  },
  [BudgetUserRole.Manager]: {
    title: 'Budget manager',
    titleShort: 'Manager',
    description: 'Has full access to budget and members settings.',
  },
  [BudgetUserRole.Member]: {
    title: 'Budget member',
    titleShort: 'Member',
    description:
      'Budget member can create his own virtual cards, view his transaction history.',
  },
} as const;

export const BudgetUsersFiltersConfig: FiltersConfig<BudgetUsersFilters> = {
  roles: {
    type: FilterType.Array,
    possibleValues: Object.values(BudgetUserRole),
  },
  limitTypes: {
    type: FilterType.Array,
    possibleValues: Object.values(BudgetLimitType),
  },
  pending: {
    type: FilterType.String,
  },
  periodName: {
    type: FilterType.String,
  },
  periodDates: {
    type: FilterType.Array,
  },
};

export const BudgetFiltersConfig: FiltersConfig<BudgetsFilters> = {
  companyAccountIds: {
    type: FilterType.Array,
  },
  userIds: {
    type: FilterType.Array,
  },
  statuses: {
    type: FilterType.Array,
    possibleValues: Object.values(BudgetStatus),
  },
  pending: {
    type: FilterType.String,
  },
  currencies: {
    type: FilterType.Array,
    possibleValues: Object.values(Currency),
  },
  periodName: {
    type: FilterType.String,
  },
  periodDates: {
    type: FilterType.Array,
  },
};

export const BUDGET_WIDGET_VISIBLE_USERS_LIMIT = 3;

export const BUDGET_EXTRA_FIELDS_DEFAULT = {
  extraFields: 'limits,card_issuing_reason',
};

export const BUDGET_LIMIT_TYPE_OPTIONS: BudgetLimitTypeOption[] =
  Object.entries(BudgetLimitsMap).map(([key, value]) => ({
    label: value.titleShort,
    value: key as BudgetLimitType,
  }));
