import { permissionsGuard } from '@/router/guards';
import { useSaveQuery } from '@/composables';
import { CoreUserPermissionsMap } from '@/data';
import {
  BUDGETS_INDEX_PAGE,
  BUDGET_CARDS_PAGE,
  BUDGET_INDEX_PAGE,
  BUDGET_MEMBERS_PAGE,
  BUDGET_TRANSACTIONS_PAGE,
} from './data';

const { setQuery: setBudgetsQuery } = useSaveQuery(BUDGETS_INDEX_PAGE.name);
const { setQuery: setBudgetTransactionsQuery } = useSaveQuery(
  BUDGET_TRANSACTIONS_PAGE.name,
);
const { setQuery: setBudgetCardsQuery } = useSaveQuery(BUDGET_CARDS_PAGE.name);
const { setQuery: setBudgetMembersQuery } = useSaveQuery(
  BUDGET_MEMBERS_PAGE.name,
);

export default [
  {
    path: '/budgets',
    name: BUDGETS_INDEX_PAGE.name,
    component: () => import('@/modules/budgets/pages/BudgetsIndexPage.vue'),
    beforeEnter: [permissionsGuard, setBudgetsQuery],
    meta: {
      auth: true,
      navigation: BUDGETS_INDEX_PAGE.navigation,
      permissions: [CoreUserPermissionsMap.ReadBudget],
    },
  },
  {
    path: '/budgets/:id',
    name: BUDGET_INDEX_PAGE.name,
    component: () => import('@/modules/budgets/pages/BudgetIndexPage.vue'),
    beforeEnter: permissionsGuard,
    meta: {
      auth: true,
      navigation: BUDGET_INDEX_PAGE.navigation,
      permissions: [CoreUserPermissionsMap.ReadBudget],
    },
    children: [
      {
        path: 'transactions',
        name: BUDGET_TRANSACTIONS_PAGE.name,
        component: () =>
          import('@/modules/budgets/pages/BudgetTransactionsPage.vue'),
        beforeEnter: [permissionsGuard, setBudgetTransactionsQuery],
        meta: {
          auth: true,
          navigation: BUDGET_TRANSACTIONS_PAGE.navigation,
          permissions: [CoreUserPermissionsMap.ReadBudget],
        },
      },
      {
        path: 'cards',
        name: BUDGET_CARDS_PAGE.name,
        component: () => import('@/modules/budgets/pages/BudgetCardsPage.vue'),
        beforeEnter: [permissionsGuard, setBudgetCardsQuery],
        meta: {
          auth: true,
          navigation: BUDGET_CARDS_PAGE.navigation,
          permissions: [CoreUserPermissionsMap.ReadBudget],
        },
      },
      {
        path: 'members',
        name: BUDGET_MEMBERS_PAGE.name,
        component: () =>
          import('@/modules/budgets/pages/BudgetMembersPage.vue'),
        beforeEnter: [permissionsGuard, setBudgetMembersQuery],
        meta: {
          auth: true,
          navigation: BUDGET_MEMBERS_PAGE.navigation,
          permissions: [CoreUserPermissionsMap.ReadBudget],
        },
      },
    ],
  },
];
