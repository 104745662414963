import { createRouter, createWebHistory } from 'vue-router';
import * as Sentry from '@sentry/vue';
import { StorageSerializers, useStorage } from '@vueuse/core';
import { useLogger } from '@karta.io/utils';

// MODULES
import accountsRoutes from '@/modules/accounts/accounts.route';
import authRoutes from '@/modules/auth/auth.route';
import coreRoutes from '@/modules/core/core.route';
import companyAccountsRoutes from '@/modules/company-accounts/companyAccounts.route';
import externalAccountsRoute from '@/modules/external-accounts/externalAccounts.route';
import budgetsRoutes from '@/modules/budgets/budgets.route';
import companiesRoutes from '@/modules/companies/companies.route';
import cardsRoutes from '@/modules/cards/cards.route';
import onboardingRoutes from '@/modules/onboarding/onboarding.route';
import transactionsRoutes from '@/modules/transactions/transactions.route';
import counterpartiesRoutes from '@/modules/counterparties/counterparties.route';
import overviewRoutes from '@/modules/overview/overview.route';
import settingsRoutes from '@/modules/settings/settings.route';

import { useCompanyAccountsStore, useCoreStore } from '@/stores';
import { routeGuard } from '@/modules/auth/auth.service';
import {
  useAnalytics,
  useCorePermissions,
  useEnv,
  useRouterBaseChecks,
} from '@/composables';
import { EnrichError, prettifyJson } from '@/helpers';

import { CORE_ERROR_INDEX_PAGE, CoreUserPermissionsMap } from '@/data';

import { CoreSessionStorage } from '@/enums';

const { identify, page } = useAnalytics();
const logger = useLogger('router', {
  color: '#ffd800',
});

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    ...accountsRoutes,
    ...authRoutes,
    ...coreRoutes,
    ...companyAccountsRoutes,
    ...externalAccountsRoute,
    ...budgetsRoutes,
    ...cardsRoutes,
    ...transactionsRoutes,
    ...companiesRoutes,
    ...onboardingRoutes,
    ...settingsRoutes,
    ...counterpartiesRoutes,
    ...overviewRoutes,
  ],
});

router.beforeEach(async to => {
  const coreStore = useCoreStore();
  const { isProduction } = useEnv();

  if (coreStore.isReadyForWork) return;

  const {
    updateCoreUserStore,
    updateAppConfigStores,
    checkUserStatuses,
    checkPageAccess,
  } = useRouterBaseChecks();
  const { meta, query } = to;
  const { twk }: { twk?: string } = query;

  /**
   * @description Делаем запрос за конфигом в cms, наполняем сторы, проверям на тех работы.
   */
  try {
    const redirectTo = await updateAppConfigStores(twk);

    if (redirectTo) {
      logger.log(
        '*beforeEach()* =>',
        '\nredirectTo:',
        prettifyJson(redirectTo),
      );
      coreStore.$patch({ isReadyForWork: true });
      return redirectTo;
    }
  } catch (error) {
    logger.error(
      '*beforeEach()* => updateAppConfigStores() =>',
      error?.message ?? error,
    );
    Sentry.captureException(
      new EnrichError({
        message: error.message,
        status: error.status,
        statusMessage: error.statusMessage,
      }),
      { level: 'error', tags: { customException: 'appConfig' } },
    );
  }

  /**
   * @description Все роуты неавторизованной зоны. Должны содержать в себе собственную логику работы.
   * Проверяем на доступ по прямой ссылке на страницу.
   */

  if (!meta.auth) {
    const redirectTo = checkPageAccess(to);
    if (!redirectTo) {
      coreStore.$patch({ isReadyForWork: true });
    }

    logger.log('*beforeEach()* =>', '\n!meta.auth', prettifyJson(redirectTo));

    return redirectTo;
  }

  try {
    await routeGuard(to);

    logger.log('*beforeEach()* => Auth.routeGuard(to) =>', 'success');

    /**
     * @description Забираем данные по юзеру с auth0 и userMe
     * + по праймари компани аккаунтам и наполняем сторы.
     */

    await updateCoreUserStore();

    if (isProduction) {
      coreStore.loadIpCountryInfo();
    }

    logger.log(
      '*beforeEach()* => updateCoreUserStore() =>',
      `\ncoreUserId: ${coreStore.id}`,
    );

    identify({
      ...(coreStore.firstName && { firstName: coreStore.firstName }),
      ...(coreStore.lastName && { lastName: coreStore.lastName }),
      emailVerified: coreStore.emailVerified,
      createdAt: coreStore.createdAt,
      ...(coreStore.currentCompany?.id && {
        companyId: String(coreStore.currentCompany?.id),
        currentCompany: coreStore.currentCompany?.name,
        company: {
          id: coreStore.currentCompany.id,
          name: coreStore.currentCompany.name,
        },
      }),
      ...(coreStore.currentCompanyUser?.role && {
        currentRole: coreStore.currentCompanyUser.role,
      }),
    });

    Sentry.setUser({
      id: coreStore.id,
      username: coreStore.fullName,
      email: coreStore.email,
      status: coreStore.status,
      ...(coreStore.currentCompany?.id && {
        companyId: String(coreStore.currentCompany?.id),
        currentCompany: coreStore.currentCompany?.name,
        company: {
          id: coreStore.currentCompany.id,
          name: coreStore.currentCompany.name,
        },
      }),
      ...(coreStore.currentCompanyUser?.role && {
        role: coreStore.currentCompanyUser.role,
      }),
    });

    const userStatusesResult = await checkUserStatuses();
    logger.log(
      '*beforeEach()* => checkUserStatuses() =>',
      prettifyJson(userStatusesResult) ?? 'success',
    );

    /**
     * Внутри checkUserStatuses может быть перезапрошен юзер,
     * а значит пермишены могут обновиться. Поэтому делаем запросы именно тут
     */
    const { can } = useCorePermissions();

    /**
     * @description permissions
     * @see {@link https://docs.google.com/spreadsheets/d/1BDTJ-lMGP00IDrjdpLVkBnDEDKcDDwGMrppYbKOH0F8/edit#gid=1730676892&range=43:43|Docs}
     */
    if (can(CoreUserPermissionsMap.ReadCompanyAccount)) {
      const { loadPrimaryCompanyAccounts } = useCompanyAccountsStore();
      loadPrimaryCompanyAccounts();
    }

    if (can(CoreUserPermissionsMap.ReadCurrencyExchangeRate)) {
      coreStore.loadExchangeRates();
    }

    const pageAccessResult = checkPageAccess(to);
    logger.log(
      '*beforeEach()* => checkPageAccess() =>',
      prettifyJson(pageAccessResult) ?? 'success',
    );

    return userStatusesResult || pageAccessResult;
  } catch (error) {
    if (error?.data?.reason) {
      logger.error(
        '*beforeEach()* =>',
        '\nerror.data.reason:',
        error?.data?.reason,
      );
      return;
    } else {
      logger.error(
        '*beforeEach()* =>',
        '\n!error.data.reason:',
        error?.message ?? error,
      );
      Sentry.captureException(
        new EnrichError({
          message: error.message,
          status: error.status,
          statusMessage: error.statusMessage,
        }),
        { level: 'error', tags: { customException: 'router' } },
      );

      return { name: CORE_ERROR_INDEX_PAGE.name };
    }
  } finally {
    coreStore.$patch({ isReadyForWork: true, isGlobalLoaderShow: false });
  }
});

router.afterEach(() => {
  page();
});

/**
 * Объект errorsByRouter нужен для подсчета количества ошибок.
 * Это предотвратит бесконечную перезагрузку приложения в случае, если какой-то файл не доступен вообще
 */
const errorsByRouter = useStorage(
  CoreSessionStorage.ErrorsByRouter,
  null,
  sessionStorage,
  {
    serializer: StorageSerializers.object,
  },
);

/**
 * Обработка ошибок роутера при недоступности каких-то файлов. Доработанная версия
 * @see https://stackoverflow.com/questions/69300341
 */
router.onError((error, to) => {
  logger.log('*onError()* =>', '\nerror:', error, '\nto:', to);
  const errorMessage = error.message as string;

  const errorCounter = errorsByRouter.value?.[errorMessage]
    ? errorsByRouter.value[errorMessage] + 1
    : 1;

  errorsByRouter.value = {
    ...errorsByRouter.value,
    [errorMessage]: errorCounter,
  };

  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed') ||
    error.message.includes('Unable to preload CSS')
  ) {
    /**
     * @todo помониторить ошибки, если errorCounter будет равен только 1, то можно добавлять в игнор
     */
    Sentry.captureException(
      new EnrichError({
        message: errorMessage,
        errorCounter,
      }),
      {
        level: errorCounter > 1 ? 'error' : 'warning',
        tags: { customException: 'ErrorsByRouter' },
      },
    );

    if (errorCounter > 1) return;

    if (!to?.fullPath) {
      window.location.reload();
    } else {
      window.location.href = to.fullPath;
    }
  }
});

export default router;
