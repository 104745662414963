import { CompanyAccountStatus, CompanyAccountType } from '@/enums';

export const CompanyAccountTypesMap = {
  [CompanyAccountType.Primary]: {
    title: 'Primary account',
    titleShort: 'Primary',
  },
  [CompanyAccountType.Treasury]: {
    title: 'Treasury account',
    titleShort: 'Treasury',
  },
  [CompanyAccountType.SubAccount]: {
    title: 'Sub-account',
    titleShort: 'Sub-account',
  },
} as const;

export const CompanyAccountStatusesMap = {
  [CompanyAccountStatus.Active]: {
    title: null,
    description: null,
  },
  [CompanyAccountStatus.Locked]: {
    title: 'Blocked',
    description:
      'This account has been blocked.<br>All activities have been suspended.<br>Please contact support.',
  },
  [CompanyAccountStatus.Closed]: {
    title: 'Closed',
    description:
      'This account has been closed.<br>All activities have been blocked.',
  },
} as const;
