interface EnrichErrorParams {
  message: string;
  [key: string]: any;
}

export class EnrichError extends Error {
  constructor(data: EnrichErrorParams) {
    super(data.message);
    Object.assign(this, data);
  }
}
