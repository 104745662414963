import type { Page } from '@/interfaces';

export const AUTH_CALLBACK_INDEX_PAGE: Page = {
  name: 'auth-callback-index-page',
  metaInfo: {
    title: 'Auth',
  },
};

export const AUTH_LOGOUT_INDEX_PAGE: Page = {
  name: 'auth-logout-index-page',
  metaInfo: {
    title: 'Auth',
  },
};

export const AUTH_ERROR_INDEX_PAGE: Page = {
  name: 'auth-error-index-page',
  metaInfo: {
    title: 'Error',
  },
};

export const AUTH_SIGNUP_INDEX_PAGE: Page = {
  name: 'auth-signup-index-page',
  metaInfo: {
    title: 'Signup',
  },
};

export const AUTH_SESSION_END_INDEX_PAGE: Page = {
  name: 'auth-session-end-index-page',
  metaInfo: {
    title: 'Signup',
  },
};
