import { auth0 } from '@/modules/auth/auth.service';
import { useEnv, useWebsocket } from '@/composables';

import { CoreWsClientEvent, CoreWsServerEvent } from '@/enums';

import type {
  CoreWsClientToServerEvents,
  CoreWsServerToClientEvents,
} from '@/interfaces';

const { apiBaseUrl } = useEnv();

const websocket = useWebsocket<
  CoreWsServerToClientEvents,
  CoreWsClientToServerEvents
>({
  url: apiBaseUrl,
  loggerName: 'api',
});

export function useWebsocketApi() {
  /**
   * @description вызывается один раз в приложении
   */
  const createConnection = () => {
    const isConnected = ref(false);
    const isAuthorized = ref(false);

    websocket.init();

    websocket.on('connect', () => {
      isConnected.value = true;
    });
    websocket.on('disconnect', () => {
      isConnected.value = false;
      isAuthorized.value = false;
    });
    websocket.on(CoreWsServerEvent.AuthLoginSuccess, () => {
      isAuthorized.value = true;
      websocket.logger.log('received', CoreWsServerEvent.AuthLoginSuccess);
    });
    websocket.on(CoreWsServerEvent.AuthLoginFailed, (error: any) => {
      isAuthorized.value = false;
      websocket.logger.error(
        'received',
        CoreWsServerEvent.AuthLoginFailed,
        error,
      );
    });
    websocket.on(CoreWsServerEvent.AuthExpire, () => {
      isAuthorized.value = false;
      websocket.logger.log('received', CoreWsServerEvent.AuthExpire);
    });

    const { isAuthenticated, getAccessTokenSilently } = auth0;

    watch(
      [isConnected, isAuthorized, isAuthenticated],
      async ([isConnectedValue, isAuthorizedValue, isAuthenticatedValue]) => {
        if (!isConnectedValue || isAuthorizedValue || !isAuthenticatedValue) {
          return;
        }

        try {
          const accessToken = await getAccessTokenSilently();
          websocket.emit(CoreWsClientEvent.AuthLogin, {
            access_token: accessToken,
          });
        } catch (error) {
          websocket.logger.error('getTokenSilently', error);
        }
      },
      { immediate: true },
    );
  };

  return {
    on: websocket.on,
    off: websocket.off,
    emit: websocket.emit,
    createConnection,
    disconnect: websocket.disconnect,
    logger: websocket.logger,
  };
}
