import { useEnv } from './index';

const { iovationScriptUrl } = useEnv();
const SCRIPT_ID = 'iovation-script';

export function useIovationSdk() {
  const isReady = ref(false);
  const blackboxString = ref('');

  const getBlackboxStringManual = (): Promise<string> =>
    new Promise((resolve, reject) => {
      if (typeof window.ioGetBlackbox !== 'function') {
        reject(new Error('Service is unavailable'));
        return;
      }

      const { pause } = useIntervalFn(
        () => {
          const { finished, blackbox } = window.ioGetBlackbox();

          if (!finished) return;

          resolve(blackbox);
          pause();
        },
        300,
        { immediateCallback: true },
      );
    });

  const init = async () => {
    if (!iovationScriptUrl) return;
    const scriptFromDOM = document.getElementById(SCRIPT_ID);
    if (scriptFromDOM) {
      try {
        const bbString = await getBlackboxStringManual();
        if (bbString) blackboxString.value = bbString;
        isReady.value = true;
      } catch (error) {
        console.error(error);
      }

      return;
    }

    const script = document.createElement('script');
    script.async = true;
    script.src = iovationScriptUrl;
    script.id = SCRIPT_ID;

    script.onload = () => {
      isReady.value = true;
    };

    window.io_bb_callback = (newBlackBoxString, isComplete) => {
      if (!isComplete) return;
      blackboxString.value = newBlackBoxString;
    };

    document.head.appendChild(script);
  };

  init();

  return {
    isReady,
    blackboxString,

    getBlackboxStringManual,
  };
}
