import type { CoreAppConfig } from '@/interfaces';

interface State {
  declineReasonsMap?: CoreAppConfig['data']['transactionDeclineReason'];
  useDeclineReasonsMap?: CoreAppConfig['data']['useTransactionDeclineReason'];
}

export const useTransactionsStore = defineStore('transactionsStore', {
  state: (): State => ({
    declineReasonsMap: undefined,
    useDeclineReasonsMap: false,
  }),
  getters: {
    declineReasonByKey: state => (key: string) =>
      state.declineReasonsMap?.[key] ||
      'Transaction was declined due to technical reasons. Please, try again',
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useTransactionsStore, import.meta.hot),
  );
}
