<script setup lang="ts">
import { UiColor } from '@karta.io/ui-components';

interface Props {
  color?: UiColor;
}
defineOptions({ name: 'Banner' });

const props = withDefaults(defineProps<Props>(), {
  color: UiColor.Secondary,
});

const cn = useClassName('banner');
</script>

<template>
  <div :class="[cn.b(), cn.mv('color', props.color)]">
    <slot />
  </div>
</template>

<style lang="scss">
@mixin gen-banner-color() {
  $colors: 'secondary', 'primary', 'error', 'warning', 'success';

  @each $item in $colors {
    $color-weight: 400;

    @if ($item == 'secondary') {
      $color-weight: 800;
    }

    @if ($item == 'warning') {
      $color-weight: 500;
    }

    .banner_color_#{$item} {
      background-color: var(--color-#{$item}-#{$color-weight});
    }
  }
}

.banner {
  --ui-tag-color: var(--color-base-white);
  --ui-tag-background-color: transparent;
  --ui-tag-border-color: var(--color-base-white);

  padding: var(--spacing-02) var(--spacing-04);
  width: 100%;
  min-height: 40px;

  .ui-button {
    --ui-button-color: var(--color-base-white);
    --ui-button-color-hover: var(--color-base-white);
    --ui-button-color-focus: var(--color-base-white);
    --ui-button-opacity-hover: 0.9;
    --ui-button-opacity-focus: 0.9;
  }
}

@include breakpoint('sm') {
  .banner {
    padding: var(--spacing-02) var(--spacing-06);
  }
}

@include breakpoint('md') {
  .banner {
    padding-left: var(--spacing-04);
    padding-right: var(--spacing-08);
  }
}

@include gen-banner-color();
</style>
