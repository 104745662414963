export enum CompanyAccountType {
  Primary = 'PRIMARY',
  Treasury = 'TREASURY',
  SubAccount = 'SUB_ACCOUNT',
}

export enum CompanyAccountStatus {
  Active = 'ACTIVE',
  Locked = 'LOCKED',
  Closed = 'CLOSED',
}

export enum CompanyAccountStatementFormat {
  Pdf = 'PDF',
  Csv = 'CSV',
}
