<script setup lang="ts">
import {
  UiColorVar,
  UiDropdownPlacement,
  UiTypography,
} from '@karta.io/ui-components';

import { useCoreStore } from '@/stores';
import { Currency } from '@/enums';

interface Props {
  popoverMaxWidth?: string;
}

defineOptions({ name: 'CoreExchangeRate' });

const props = withDefaults(defineProps<Props>(), {
  popoverMaxWidth: '224px',
});

/**
 * @todo Вынести иконку в ДС
 * И поменять компонент на UiTag, когда будут токены
 */
const ICON = 'ic:round-currency-exchange';

const cn = useClassName('core-exchange-rate');

const popoverTarget = ref<HTMLDivElement>();

const coreStore = useCoreStore();

const items = computed(
  () =>
    coreStore.exchangeRate?.filter(item =>
      coreStore.currentCompanyCurrencies.includes(item.toCurrency),
    ) || [],
);

const hasItems = computed(() => items.value.length > 0);
</script>

<template>
  <div v-if="hasItems" ref="popoverTarget" :class="cn.b()">
    <UiIcon :value="ICON" size="12px" />

    <AppText
      :typography="UiTypography.TextSmMedium"
      :color="UiColorVar.Secondary700"
    >
      Exchange rates
    </AppText>
  </div>

  <UiPopover
    v-if="hasItems"
    :target="popoverTarget"
    :maxWidth="props.popoverMaxWidth"
    :placement="UiDropdownPlacement.Top"
  >
    <CoreExchangeRatePopoverContent
      :items="items"
      :fromCurrency="Currency.Usd"
    />
  </UiPopover>
</template>

<style lang="scss">
.core-exchange-rate {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-01);
  padding: 3px 12px;
  background-color: var(--color-secondary-100);
  border-radius: var(--border-radius-xl);
  cursor: pointer;

  &__tag-label {
    display: flex;
    align-items: center;
    gap: var(--spacing-01);
  }
}
</style>
