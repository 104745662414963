import { UiColor, UiToastNotify } from '@karta.io/ui-components';

import { useEnv } from '@/composables';
import type { CardCredentialsToken } from '@/interfaces';

enum MessageEventError {
  CannotRetrieveDetails = 'cannot_retrieve_details',
  UnableToParseHash = 'unable_to_parse_hash',
  UnknownHashFormat = 'unknown_hash_format',
  InvalidHashFormat = 'invalid_hash_format',
}

const MessageEventErrorMap = {
  [MessageEventError.CannotRetrieveDetails]:
    'Could not find the information. Try again later or contact support.',
  [MessageEventError.UnableToParseHash]:
    'Hash processing error. Try again later or contact support.',
  [MessageEventError.UnknownHashFormat]:
    'Unsupported hash format. Try again later or contact support.',
  [MessageEventError.InvalidHashFormat]:
    'Incorrect hash format. Try again later or contact support.',
};

const { airwallexScriptUrl } = useEnv();

/**
 * @see {@link https://www.airwallex.com/docs/issuing__retrieve-sensitive-card-details__secure-iframes|Docs}
 */
export function useCardAirwallexSdk(
  isMobile: Readonly<Ref<boolean>> = ref(false),
) {
  const { hostname: airwallexHostname } = new URL(airwallexScriptUrl);
  const isCredentialsLoading = ref(false);
  const iframeUrl = ref();

  const wrapperStyles = computed(() =>
    isMobile.value
      ? {
          fontSize: '14px',
          lineHeight: '20px',
        }
      : {
          fontSize: '18px',
          lineHeight: '28px',
        },
  );
  const rowStyles = computed(() =>
    isMobile.value
      ? {
          padding: '12px 8px 12px 16px',
        }
      : {
          padding: '8px 8px 8px 32px',
        },
  );

  let savedInitParams: CardCredentialsToken;

  const handleMessage = (event: MessageEvent) => {
    if (!event.origin.includes(airwallexHostname) || !savedInitParams) return;

    if (event?.data.type === `${savedInitParams.externalId}:details:loaded`) {
      isCredentialsLoading.value = false;
    }

    if (event?.data.type === `${savedInitParams.externalId}:details:error`) {
      const message = event?.data.error
        ? MessageEventErrorMap[event?.data.error as MessageEventError]
        : MessageEventErrorMap[MessageEventError.CannotRetrieveDetails];

      UiToastNotify({
        color: UiColor.Error,
        title: 'Request error',
        message,
      });
    }
  };

  const init = (params: CardCredentialsToken) => {
    savedInitParams = params;
    isCredentialsLoading.value = true;

    /**
     * Хаки с позиционирование типо position: absolute не работают,
     * этот стиль запрещен судя по валидации.
     * .details__tooltip borderRadius работает через жопу.
     * Он задает бордер дочернему элемент, а родитель со своим бордером и с черным бекграунтом.
     * css переменные не доступны.
     */
    const hash = {
      token: params.accessToken,
      langKey: 'en',
      rules: {
        '.details': {
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          margin: '0',
          backgroundColor: 'transparent',
          color: '#101828',
          fontFamily: 'Courier New, monaco, monospace',
          fontWeight: '700',
          letterSpacing: 'normal',
          ...wrapperStyles.value,
        },
        '.details__row': {
          display: 'flex',
          alignItems: 'center',
          ...rowStyles.value,
        },
        '.details__row--card-number': {
          borderBottom: '1px solid #eaecf0',
        },
        '.details__row--expiry-date': {
          borderBottom: '1px solid #eaecf0',
        },
        '.details__content': {
          marginRight: '8px',
        },
        '.details__label': {
          display: 'none',
        },
        '.details__value': {
          margin: '0',
        },
        '.details__button': {
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        },
        '.details__tooltip': {
          padding: '8px 12px 8px 12px',
          borderRadius: '8px',
          backgroundColor: '#1d2939',
          fontFamily: 'Arial, monaco, monospace',
          fontSize: '12px',
          fontWeight: '700',
          lineHeight: '18px',
          letterSpacing: 'normal',
          color: '#ffffff',
        },
      },
    };

    iframeUrl.value = `${airwallexScriptUrl}/${params.externalId}/details#${encodeURIComponent(JSON.stringify(hash))}`;
  };

  watch(isMobile, () => {
    if (!savedInitParams) return;

    iframeUrl.value = '';
    isCredentialsLoading.value = true;
    init(savedInitParams);
  });

  window.addEventListener('message', handleMessage);

  onBeforeUnmount(() => {
    window.removeEventListener('message', handleMessage);
  });

  return {
    isCredentialsLoading,
    iframeUrl,

    init,
  };
}
