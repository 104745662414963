<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue';
import {
  UiButtonSize,
  UiButtonTheme,
  UiIconValue,
} from '@karta.io/ui-components';

import { useStagingManagement } from '@/composables';

defineOptions({
  name: 'TheSidebar',
});

const cn = useClassName('the-sidebar');
const {
  openModal: openStagingManagementModal,
  isButtonShown: isStagingManagementShown,
  isUrlCustom: isApiBaseUrlCustom,
} = useStagingManagement();

const serviceButtonsHeight = computed(() =>
  isStagingManagementShown.value ? '48px' : '0px',
);

const { getAccessTokenSilently } = useAuth0();
const handleTokenButtonClick = async () => {
  try {
    const token = await getAccessTokenSilently();
    await navigator.clipboard.writeText(token);

    UiToastNotify({
      title: 'Bearer token copied to clipboard!',
      isUpdateTimerByMouseAction: false,
      duration: 2000,
    });
  } catch (error) {
    console.error(error);
  }
};
</script>

<template>
  <div :class="cn.b()">
    <div :class="cn.e('header')">
      <router-link to="/" :class="cn.e('logo')">
        <IconLogo />
      </router-link>
    </div>

    <UiScrollbar>
      <div :class="cn.e('navigation')">
        <Navigation />
      </div>
    </UiScrollbar>

    <div :class="cn.e('exchange-rate')">
      <CoreExchangeRate />
    </div>

    <div v-if="isStagingManagementShown" :class="cn.e('service-buttons')">
      <UiButton
        :theme="UiButtonTheme.SecondaryOutline"
        :size="UiButtonSize.Xs"
        prefixIcon="oui:token-key"
        isFullWidth
        @click="handleTokenButtonClick"
      >
        Token
      </UiButton>

      <UiButton
        :theme="
          isApiBaseUrlCustom
            ? UiButtonTheme.PrimaryOutline
            : UiButtonTheme.SecondaryOutline
        "
        :size="UiButtonSize.Xs"
        :prefixIcon="UiIconValue.Chip"
        isFullWidth
        @click="openStagingManagementModal"
      >
        API
      </UiButton>
    </div>

    <div :class="cn.e('footer')">
      <div :class="cn.e('footer-inner')">
        <UserLogout />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.the-sidebar {
  --sidebar-header-height: 80px;
  --sidebar-footer-height: 98px;
  --sidebar-exchange-rate-height: 58px;

  width: 100%;
  height: 100%;
  background-color: var(--color-secondary-50);
  border-right: 1px solid var(--color-secondary-200);

  .ui-scrollbar {
    height: calc(
      100% -
        (
          var(--sidebar-header-height) + var(--sidebar-footer-height) +
            var(--sidebar-exchange-rate-height) + v-bind('serviceButtonsHeight')
        )
    );
  }

  &__header {
    height: var(--sidebar-header-height);
    padding: var(--spacing-05) var(--spacing-07);
  }

  &__logo {
    display: inline-block;
  }

  &__footer {
    height: var(--sidebar-footer-height);
    padding: 0 var(--spacing-06);
  }

  &__service-buttons {
    display: flex;
    gap: var(--spacing-04);
    padding: var(--spacing-04);
    padding-top: 0;
    background-color: transparent;
  }

  &__footer-inner {
    padding: var(--spacing-06) 0 var(--spacing-08);
    border-top: 1px solid var(--color-secondary-200);
  }

  &__exchange-rate {
    height: var(--sidebar-exchange-rate-height);
    padding: var(--spacing-04);
  }
}
</style>
