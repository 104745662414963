import type { RouteLocationNormalized } from 'vue-router';
import { useCorePermissions } from '@/composables';
import { CORE_FORBIDDEN_INDEX_PAGE } from '@/data';

const permissionsGuard = (to: RouteLocationNormalized) => {
  const { can } = useCorePermissions();
  const metaPermissions = to.meta?.permissions;

  if (metaPermissions?.length) {
    if (metaPermissions.some(metaPermission => can(metaPermission))) {
      return;
    }
    return { name: CORE_FORBIDDEN_INDEX_PAGE.name };
  }
};

const environmentsGuard = (to: RouteLocationNormalized) => {
  const metaEnvironments = to.meta?.environments || [];

  if (metaEnvironments?.length) {
    if (metaEnvironments?.includes(import.meta.env.APP_ENV)) {
      return;
    }
    return { name: CORE_FORBIDDEN_INDEX_PAGE.name };
  }
};

export { permissionsGuard, environmentsGuard };
