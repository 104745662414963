<script lang="ts" setup>
import { Currency } from '@/enums';

interface Props {
  align: 'left' | 'right';
  value: number | string;
  currency: Currency;
  color?: string;
}

defineOptions({ name: 'TableCellCurrency' });

const props = withDefaults(defineProps<Props>(), {
  align: 'left',
  value: 0,
  currency: Currency.Usd,
  color: '--color-secondary-900',
});

const cellText = computed(() =>
  formatMoney(props.value, props.currency || Currency.Usd),
);

const cn = useClassName('table-cell-currency');
</script>

<template>
  <div
    :class="[cn.b(), cn.mv('align', props.align)]"
    :style="{ color: `var(${props.color})` }"
  >
    <span :class="cn.e('value')">{{ cellText }}</span>
  </div>
</template>

<style lang="scss">
.table-cell-currency {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;

  @include typography('text-sm-medium');

  &_align_left {
    justify-content: flex-start;
  }

  &_align_right {
    justify-content: flex-end;
  }

  &__value {
    @include text-ellipsis(100%);
  }
}
</style>
