import { sliceString } from '@/helpers';
import { SEPA_COUNTRIES } from '@/data';
import {
  CompanyProviderOutcomePaymentMethod,
  CounterpartyBusinessType,
  CounterpartyPaymentAccountTransferType,
  FilterType,
} from '@/enums';
import type {
  CounterpartiesFilters,
  CounterpartyPaymentAccount,
  FiltersConfig,
} from '@/interfaces';

export const COUNTERPARTY_DEFAULT_NAME = 'Unknown name';

export const CounterpartyBusinessTypesMap = {
  [CounterpartyBusinessType.Business]: {
    title: 'Business',
  },
  [CounterpartyBusinessType.Individual]: {
    title: 'Individual',
  },
} as const;

export const CounterpartiesFiltersConfig: FiltersConfig<CounterpartiesFilters> =
  {
    businessTypes: {
      type: FilterType.Array,
      possibleValues: Object.values(CounterpartyBusinessType),
    },
    countries: {
      type: FilterType.Array,
    },
    periodName: {
      type: FilterType.String,
    },
    periodDates: {
      type: FilterType.Array,
    },
  } as const;

export const CounterpartyPaymentAccountTransferTypesMap = {
  [CounterpartyPaymentAccountTransferType.Swift]: {
    label: 'SWIFT',
    description: 'Multicurrency',
    countries: undefined,
    companyProviderOutcomePaymentMethod:
      CompanyProviderOutcomePaymentMethod.OutboundSwift,
    isHidden: false,
    getSlicedAccountNumber: (paymentAccount: CounterpartyPaymentAccount) =>
      paymentAccount.accountNumber
        ? sliceString(paymentAccount.accountNumber)
        : '',
  },
  [CounterpartyPaymentAccountTransferType.Ach]: {
    label: 'ACH',
    description: 'USD',
    countries: ['US'] as string[],
    companyProviderOutcomePaymentMethod:
      CompanyProviderOutcomePaymentMethod.OutboundAch,
    isHidden: false,
    getSlicedAccountNumber: (paymentAccount: CounterpartyPaymentAccount) =>
      paymentAccount.accountNumber
        ? sliceString(paymentAccount.accountNumber)
        : '',
  },
  [CounterpartyPaymentAccountTransferType.Fedwire]: {
    label: 'Fedwire',
    description: 'USD',
    countries: ['US'] as string[],
    companyProviderOutcomePaymentMethod:
      CompanyProviderOutcomePaymentMethod.OutboundWire,
    isHidden: false,
    getSlicedAccountNumber: (paymentAccount: CounterpartyPaymentAccount) =>
      paymentAccount.accountNumber
        ? sliceString(paymentAccount.accountNumber)
        : '',
  },
  [CounterpartyPaymentAccountTransferType.Sepa]: {
    label: 'SEPA',
    description: 'EUR',
    countries: SEPA_COUNTRIES,
    companyProviderOutcomePaymentMethod: undefined,
    isHidden: false,
    getSlicedAccountNumber: (paymentAccount: CounterpartyPaymentAccount) =>
      paymentAccount.accountNumber
        ? sliceString(paymentAccount.accountNumber)
        : '',
  },
  [CounterpartyPaymentAccountTransferType.Karta]: {
    label: 'Karta',
    description: 'USD, EUR',
    countries: undefined,
    isHidden: true,
    companyProviderOutcomePaymentMethod:
      CompanyProviderOutcomePaymentMethod.OutboundKarta,
    getSlicedAccountNumber: (paymentAccount: CounterpartyPaymentAccount) =>
      paymentAccount.kartaId ? sliceString(paymentAccount.kartaId) : '',
  },
} as const;
