<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue';
import {
  UiAvatarSize,
  UiButtonDropdownActionSize,
  UiButtonSize,
  UiButtonTheme,
  UiColor,
  UiDropdownPlacement,
  UiIconValue,
} from '@karta.io/ui-components';

import { useCoreStore } from '@/stores';

// import { useCorePermissions, useEnv } from '@/composables';
import { useEnv } from '@/composables';
import { goToSupport } from '@/helpers';
import {
  CORE_ORGANIZATION_SELECTION_PAGE,
  // CoreUserPermissionsMap,
  SETTINGS_INDEX_PAGE,
} from '@/data';

import CompanyAccountStatementModal from '@/modules/company-accounts/components/CompanyAccountStatementModal.vue';

defineOptions({ name: 'UserLogout' });

const emit = defineEmits<{
  click: [];
}>();

const { logout } = useAuth0();
const coreStore = useCoreStore();
const router = useRouter();
const route = useRoute();
const { isDemo } = useEnv();
const cn = useClassName('user-logout');
// const { can } = useCorePermissions();
const { open } = useDialog();

/**
 * @description permissions
 * @see {@link https://docs.google.com/spreadsheets/d/1BDTJ-lMGP00IDrjdpLVkBnDEDKcDDwGMrppYbKOH0F8/edit#gid=1730676892&range=43:43|Docs}
 */
// const canCreateStatement = computed(
//   () =>
//     can(CoreUserPermissionsMap.ReadCompanyAccount) &&
//     !coreStore.isCompanyFriendly,
// );
/**
 * @todo заменить при релизе
 */
const canCreateStatement = false;

const handleSwitchOrganizationClick = () => {
  router.push({
    name: CORE_ORGANIZATION_SELECTION_PAGE.name,
    query: { returnToUrl: route.fullPath },
  });
  emit('click');
};

const handleSettingsClick = () => {
  router.push({ name: SETTINGS_INDEX_PAGE.name });
  emit('click');
};

const handleLogout = () => {
  logout({
    logoutParams: {
      returnTo: `${window.location.origin}/auth/logout`,
    },
  });
};

const handleSupportClick = () => {
  goToSupport();
  emit('click');
};

const handleStatementClick = () => {
  open({
    component: CompanyAccountStatementModal,
    props: {
      'onSubmit:success': () => {
        UiToastNotify({
          title: 'We have sent the statement to you by email.',
          color: UiColor.Success,
        });
      },
    },
  });
};
</script>

<template>
  <div :class="cn.b()">
    <UiAvatar
      :key="coreStore.avatar"
      :src="coreStore.avatar"
      :size="UiAvatarSize.Sm"
    />

    <div :class="cn.e('info')">
      <span
        v-if="coreStore.fullName"
        :class="cn.e('name')"
        :title="coreStore.fullName"
      >
        {{ coreStore.fullName }}
      </span>

      <span
        :class="cn.e('company-name')"
        :title="coreStore.currentCompany?.name"
      >
        {{ coreStore.currentCompany?.name }}
      </span>
    </div>

    <div :class="cn.e('button-dropdown')">
      <UiButtonDropdown
        title=""
        :disabled="isDemo"
        :theme="UiButtonTheme.SecondaryTertiary"
        :size="UiButtonSize.Sm"
        :actionSize="UiButtonDropdownActionSize.Sm"
        :dropdownPlacement="UiDropdownPlacement.TopEnd"
        :dropdownZIndex="301"
        dropdownWidth="225px"
      >
        <UiButtonDropdownAction
          :prefixIcon="UiIconValue.ChatAlt"
          @click="handleSupportClick"
        >
          Support
        </UiButtonDropdownAction>

        <UiButtonDropdownAction
          :prefixIcon="UiIconValue.Cog"
          @click="handleSettingsClick"
        >
          Settings
        </UiButtonDropdownAction>

        <UiButtonDropdownAction
          v-if="canCreateStatement"
          :prefixIcon="UiIconValue.DocumentDownload"
          @click="handleStatementClick"
        >
          Statement
        </UiButtonDropdownAction>

        <UiDivider style="margin: 2px auto" />

        <UiButtonDropdownAction
          :prefixIcon="UiIconValue.Refresh"
          @click="handleSwitchOrganizationClick"
        >
          Switch organization
        </UiButtonDropdownAction>

        <UiButtonDropdownAction
          :prefixIcon="UiIconValue.Logout"
          :color="UiColor.Error"
          @click="handleLogout"
        >
          Log Out
        </UiButtonDropdownAction>
      </UiButtonDropdown>
    </div>
  </div>
</template>

<style lang="scss">
.user-logout {
  --ui-avatar-background-color: var(--color-secondary-100);

  position: relative;
  display: grid;
  grid-template-columns: 32px minmax(100px, 1fr) 16px;
  grid-column-gap: var(--spacing-03);
  align-items: center;
  max-width: 100%;
  height: 40px;

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__name {
    color: var(--color-secondary-900);

    @include typography('text-sm-medium');
    @include text-ellipsis();
  }

  &__company-name {
    color: var(--color-secondary-500);

    @include typography('text-xs-regular');
    @include text-ellipsis();
  }

  &__button-dropdown {
    position: absolute;
    top: -8px;
    right: -14px;
  }

  &__button-dropdown-icon {
    transition: transform var(--transition-duration);

    &_up {
      transform: rotate(180deg);
    }
  }
}
</style>
