<script setup lang="ts">
defineOptions({ name: 'LayoutDefault' });

const cn = useClassName('layout-default');

const isMobileMenuOpen = ref(false);
const { isDesktop } = useBreakpoint();

watch(isDesktop, newValue => {
  if (newValue && isMobileMenuOpen.value) {
    isMobileMenuOpen.value = false;
  }
});
</script>

<template>
  <div :class="cn.b()">
    <div :class="cn.e('banners')">
      <Banners />
    </div>

    <div :class="cn.e('container')">
      <div :class="cn.e('header')">
        <TheHeader @onMobileMenuOpen="isMobileMenuOpen = true" />
      </div>

      <div :class="cn.e('sidebar')">
        <TheSidebar />
      </div>

      <div :class="cn.e('main')">
        <router-view />
      </div>
    </div>

    <TheMobileMenu v-model="isMobileMenuOpen" />
  </div>
</template>

<style lang="scss">
.layout-default {
  display: block;

  &__banners {
    position: sticky;
    top: 0;
    left: 0;
    z-index: var(--banners-z-index);
  }

  &__header {
    position: sticky;
    top: var(--banners-height);
    background-color: var(--color-base-white);
    box-shadow: var(--shadow-xs);
    z-index: var(--the-header-z-index);
  }

  &__sidebar {
    display: none;
  }
}

@include breakpoint('md') {
  .layout-default {
    &__container {
      display: grid;
      grid-template-columns: var(--sidebar-width) minmax(320px, 100%);
    }

    &__header {
      display: none;
    }

    &__sidebar {
      position: sticky;
      top: var(--banners-height);
      display: block;
      width: 100%;
      height: calc(100vh - var(--banners-height));
    }
  }
}
</style>
