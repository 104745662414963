import { useCoreStore } from '@/stores';
import { readCompanyAccounts } from '@/api';

import {
  CompanyAccountType,
  CompanyProviderPaymentService,
  Currency,
} from '@/enums';
import type { CompanyAccount } from '@/interfaces';

interface State {
  currentCompanyAccount: CompanyAccount | null;
  primaryCompanyAccounts: CompanyAccount[];
  isLoading: boolean;
}

const logger = useLogger('use-company-accounts-store');

export const useCompanyAccountsStore = defineStore('companyAccountsStore', {
  state: (): State => ({
    currentCompanyAccount: null,
    primaryCompanyAccounts: [],
    isLoading: false,
  }),
  getters: {
    currentCompanyAccountBalance: state =>
      formatMoney(
        state.currentCompanyAccount?.balance || 0,
        state.currentCompanyAccount?.currency || Currency.Usd,
      ),
    /**
     * @todo stripePrimaryCompanyAccount и airwallexPrimaryCompanyAccount в новом move money скорее всего не понадобятся
     */
    stripePrimaryCompanyAccount: state => {
      const coreStore = useCoreStore();

      const companyStripeProviderId = coreStore.currentCompany?.providers?.find(
        provider =>
          provider.paymentService === CompanyProviderPaymentService.Stripe,
      )?.providerId;

      if (!companyStripeProviderId) return null;

      return state.primaryCompanyAccounts.find(
        companyAccount => companyAccount.providerId === companyStripeProviderId,
      );
    },
    airwallexPrimaryCompanyAccount: state => {
      const coreStore = useCoreStore();

      const companyAirwallexProviderId =
        coreStore.currentCompany?.providers?.find(
          provider =>
            provider.paymentService === CompanyProviderPaymentService.Airwallex,
        )?.providerId;

      if (!companyAirwallexProviderId) return null;

      return state.primaryCompanyAccounts.find(
        companyAccount =>
          companyAccount.providerId === companyAirwallexProviderId,
      );
    },
  },
  actions: {
    async loadPrimaryCompanyAccounts() {
      const coreStore = useCoreStore();
      this.isLoading = true;
      try {
        const { results } = await readCompanyAccounts({
          filters: {
            types: [CompanyAccountType.Primary],
          },
          extraFields:
            'beneficiary_name,beneficiary_address,bank_name,bank_address,wire_international_details,bins',
        });

        /**
         * @description Находим страйп аккаунт и ставим его на первое место
         */
        const stripeAccountIndex = results.findIndex(
          account =>
            coreStore.companyProviderByProviderId(account.providerId)
              ?.paymentService === CompanyProviderPaymentService.Stripe,
        );

        if (stripeAccountIndex !== -1) {
          const [stripeAccount] = results.splice(stripeAccountIndex, 1);
          results.unshift(stripeAccount);
        }

        this.primaryCompanyAccounts = results ?? [];
      } catch (error) {
        logger.error('loadPrimaryCompanyAccounts', error);
        return error;
      } finally {
        this.isLoading = false;
      }
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useCompanyAccountsStore, import.meta.hot),
  );
}
