import { createAuth0 } from '@auth0/auth0-vue';
import { watchEffect } from 'vue';
import type { RouteLocationNormalized } from 'vue-router';

import { useEnv } from '@/composables';

const { authAudience, authClientId, authDomain } = useEnv();

export const auth0 = createAuth0(
  {
    domain: authDomain,
    clientId: authClientId,
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
    authorizationParams: {
      redirect_uri: `${window.location.origin}/auth/callback`,
      audience: authAudience,
      scope: 'openid profile email offline_access',
    },
  },
  { skipRedirectCallback: true },
);

/**
 * Authorization guard to protect routes in our app from unauthorized users
 *
 * @param {RouteLocationNormalized} to
 */
export const routeGuard = (to: RouteLocationNormalized) => {
  return new Promise(resolve => {
    const isAuthRequired = to.matched.some(record => record.meta.auth);
    const { isAuthenticated, isLoading, loginWithRedirect, user } = auth0;

    if (!isAuthRequired) return resolve(user);

    const verify = () => {
      // If the user is authenticated, continue with the route
      if (isAuthenticated.value) {
        return resolve(user);
      }

      // Otherwise, log in
      loginWithRedirect({
        appState: {
          target: window.location.href,
        },
      });
    };

    // If loading has already finished, check our auth state using `fn()`
    if (!isLoading.value) {
      return verify();
    }

    // Watch for the loading property to change before we check isAuthenticated
    watchEffect(() => {
      if (isLoading.value === false) {
        return verify();
      }
    });
  });
};
