export enum CompanyStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Blocked = 'BLOCKED',
  Waitlist = 'WAITLIST',
}

export enum CompanyKybStatus {
  Initial = 'INITIAL',
  Approved = 'APPROVED',
  Review = 'REVIEW',
  WaitingDocuments = 'WAITING_DOCUMENTS',
  Rejected = 'REJECTED',
}

export enum CompanyKycDocType {
  Ssn = 'SSN',
  Passport = 'PASSPORT',
}

export enum CompanyKycIdVerificationStatus {
  Waiting = 'WAITING',
  Review = 'REVIEW',
  Approved = 'APPROVED',
  Failed = 'FAILED',
}

export enum CompanyScope {
  Ecommerce = 'ECOMMERCE',
  Affiliate = 'AFFILIATE',
  Dropshipping = 'DROPSHIPPING',
  Marketing = 'MARKETING',
  Startup = 'STARTUP',
  Other = 'OTHER',
}

export enum CompanyBusinessType {
  Company = 'COMPANY',
  Individual = 'INDIVIDUAL',
}

export enum CompanyIncorporationType {
  Llc = 'LLC',
  Corporation = 'CORPORATION',
  SoleProprietorship = 'SOLE_PROPRIETORSHIP',
  Partnership = 'PARTNERSHIP',
  Cooperative = 'COOPERATIVE',
  Other = 'OTHER',
}

export enum CompanyComplianceStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Declined = 'DECLINED',
}

export enum CompanyUserRole {
  Owner = 'OWNER',
  Admin = 'ADMIN',
  Bookkeeper = 'BOOKKEEPER',
  Employee = 'EMPLOYEE',
}

export enum CompanyUserStatus {
  Invited = 'INVITED',
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  InviteExpired = 'INVITE_EXPIRED',
  InviteRejected = 'INVITE_REJECTED',
}

export enum CompanyPlanKey {
  // STRIPE
  StripeCardIssuingFee = 'stripeCardIssuingFee',
  StripeMonthlyCardIssueLimit = 'stripeMonthlyCardIssueLimit',
  StripeActiveCardsLimit = 'stripeActiveCardsLimit',
  StripeInternationalPaymentFee = 'stripeInternationalPaymentFee',
  StripeCurrencyExchangeFee = 'stripeCurrencyExchangeFee',
  StripeInboundAchFee = 'stripeInboundAchFee',
  StripeInboundWireFee = 'stripeInboundWireFee',
  StripeOutboundAchFee = 'stripeOutboundAchFee',
  StripeOutboundWireFee = 'stripeOutboundWireFee',
  StripeInboundCbFee = 'stripeInboundCbFee',
  StripeInvoicePaidFee = 'stripeInvoicePaidFee',

  // MARQETA
  MarqetaCardIssuingFee = 'marqetaCardIssuingFee',
  MarqetaMonthlyCardIssueLimit = 'marqetaMonthlyCardIssueLimit',
  MarqetaActiveCardsLimit = 'marqetaActiveCardsLimit',
  MarqetaInboundCbFee = 'marqetaInboundCbFee',
  MarqetaInternationalPaymentFee = 'marqetaInternationalPaymentFee',
  MarqetaCurrencyExchangeFee = 'marqetaCurrencyExchangeFee',

  // AMAIZ
  AmaizEurCardIssuingFee = 'amaizEurCardIssuingFee',
  AmaizEurMonthlyCardIssueLimit = 'amaizEurMonthlyCardIssueLimit',
  AmaizEurActiveCardsLimit = 'amaizEurActiveCardsLimit',
  AmaizEurInternationalPaymentFee = 'amaizEurInternationalPaymentFee',
  AmaizEurExchangeFee = 'amaizEurExchangeFee',
  AmaizEurInboundCbFee = 'amaizEurInboundCbFee',

  // SPENXY
  SpenxyCardIssuingFee = 'spenxyCardIssuingFee',
  SpenxyMonthlyCardIssueLimit = 'spenxyMonthlyCardIssueLimit',
  SpenxyActiveCardsLimit = 'spenxyActiveCardsLimit',
  SpenxyCurrencyExchangeFee = 'spenxyCurrencyExchangeFee',
  SpenxyInternationalPaymentFee = 'spenxyInternationalPaymentFee',
  SpenxyInboundCbFee = 'spenxyInboundCbFee',

  // AIRWALLEX
  AirwallexCardIssuingFee = 'airwallexCardIssuingFee',
  AirwallexMonthlyCardIssueLimit = 'airwallexMonthlyCardIssueLimit',
  AirwallexActiveCardsLimit = 'airwallexActiveCardsLimit',
  AirwallexCurrencyExchangeFee = 'airwallexCurrencyExchangeFee',
  AirwallexSmallDomesticFee = 'airwallexSmallDomesticFee',
  AirwallexSmallInternationalFee = 'airwallexSmallInternationalFee',
  AirwallexInboundAchFee = 'airwallexInboundAchFee',
  AirwallexInboundCbFee = 'airwallexInboundCbFee',
  AirwallexInboundSwiftFee = 'airwallexInboundSwiftFee',
  AirwallexInboundWireFee = 'airwallexInboundWireFee',
  AirwallexInternationalPaymentFee = 'airwallexInternationalPaymentFee',

  CryptoInboundEuroConversionFee = 'euroConversionFee',
}

export enum CompanyPlanGroup {
  Treasury = 'TREASURY',
  Cards = 'CARDS',
  Invoices = 'INVOICES',
}

export enum CompanyInviteCheckAction {
  Login = 'login',
  Signup = 'signup',
}

export enum CompanyProviderPaymentService {
  Stripe = 'STRIPE',
  Amaiz = 'AMAIZ',
  Marqeta = 'MARQETA',
  Spenxy = 'SPENXY',
  Airwallex = 'AIRWALLEX',
}

export enum CompanyProviderStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Locked = 'LOCKED',
  LockedByProvider = 'LOCKED_BY_PROVIDER',
  ClosedByProvider = 'CLOSED_BY_PROVIDER',
}

export enum CompanyProviderIncomePaymentMethod {
  InboundAch = 'INBOUND_ACH',
  InboundWire = 'INBOUND_WIRE',
  InboundSwift = 'INBOUND_SWIFT',
  InboundCrypto = 'INBOUND_CRYPTO',
  InboundKarta = 'INBOUND_KARTA',
}

export enum CompanyProviderOutcomePaymentMethod {
  OutboundAch = 'OUTBOUND_ACH',
  OutboundWire = 'OUTBOUND_WIRE',
  OutboundKarta = 'OUTBOUND_KARTA',
  OutboundSwift = 'OUTBOUND_SWIFT',
}

export enum CompanyProviderOnboardingStatus {
  WaitingKyb = 'WAITING_KYB',
  WaitingKyc = 'WAITING_KYC',
  ApplicationReview = 'APPLICATION_REVIEW',
  ApplicationFailed = 'APPLICATION_FAILED',
  WaitingIdVerification = 'WAITING_ID_VERIFICATION',
  IdVerificationFailed = 'ID_VERIFICATION_FAILED',
  ProviderReview = 'PROVIDER_REVIEW',
  ProviderFailed = 'PROVIDER_FAILED',
  WaitingFirstFund = 'WAITING_FIRST_FUND',
  Approved = 'APPROVED',
}

export enum CompanyProviderOnboardingType {
  Full = 'FULL',
  ExternalLink = 'EXTERNAL_LINK',
  NoKyb = 'NO_KYB',
}
