<script lang="ts" setup>
import { UiAvatarSize } from '@karta.io/ui-components';

import type { CompanyAccount } from '@/interfaces';

interface Props {
  modelValue?: CompanyAccount;
  searchValue?: string;
  options?: CompanyAccount[];
  triggerMinHeight?: string;
  triggerAvatarSize?: UiAvatarSize;
  isOptionsLoading?: boolean;
  isTriggerLoading?: boolean;
  isDisabled?: boolean;
  hasNextPage?: boolean;
}

defineOptions({ name: 'CompanyAccountSelect' });

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  searchValue: '',
  options: () => [],
  triggerMinHeight: '56px',
  triggerAvatarSize: UiAvatarSize.Sm,
  isOptionsLoading: false,
  isTriggerLoading: false,
  isDisabled: false,
  hasNextPage: false,
});

const emit = defineEmits<{
  'update:modelValue': [value?: CompanyAccount];
  'update:searchValue': [value: string];
  'load:nextPage': [];
  'click:create': [value?: string];
}>();

const handleModelValueUpdate = (value?: CompanyAccount) =>
  emit('update:modelValue', value);
const handleSearchValueUpdate = (value: string) =>
  emit('update:searchValue', value);
const handleActionClick = (value?: string) => emit('click:create', value);
</script>

<template>
  <UiSelectNext
    :modelValue="props.modelValue"
    :searchValue="props.searchValue"
    :options="props.options"
    :optionsSkeletonCount="4"
    :hasNextPage="props.hasNextPage"
    :isOptionsLoading="props.isOptionsLoading"
    :isTriggerLoading="props.isTriggerLoading"
    :hasTriggerSpinner="false"
    optionLabel="name"
    optionValue="id"
    searchable
    dropdownMaxHeight="280px"
    :dropdownZIndex="301"
    placeholder="Select account"
    :disabled="props.isDisabled"
    classModifier="company-account-select"
    @load:nextPage="emit('load:nextPage')"
    @update:searchValue="handleSearchValueUpdate"
    @update:modelValue="handleModelValueUpdate"
    @click:action="handleActionClick"
  >
    <template #trigger-value="triggerValue">
      <CompanyAccountSelectTriggerValue
        :name="triggerValue.name"
        :type="triggerValue.type"
        :balance="triggerValue.balance"
        :currency="triggerValue.currency"
        :sourceAccount="triggerValue.sourceAccount"
        :avatarSize="props.triggerAvatarSize"
      />
    </template>

    <template #trigger-skeleton>
      <CompanyAccountSelectTriggerSkeleton
        :avatarSize="props.triggerAvatarSize"
      />
    </template>

    <template #option="{ option, isSelected, isFocused }">
      <CompanyAccountSelectOption
        :name="option.name"
        :type="option.type"
        :balance="option.balance"
        :currency="option.currency"
        :status="option.status"
        :sourceAccount="option.sourceAccount"
        :isDisabled="option.isDisabled"
        :isSelected="isSelected"
        :isFocused="isFocused"
      />
    </template>

    <template #option-skeleton>
      <CompanyAccountSelectOptionSkeleton />
    </template>

    <template #no-options><BaseNoOptions /></template>
  </UiSelectNext>
</template>

<style lang="scss">
.ui-select-next_company-account-select {
  --ui-select-next-trigger-min-height: v-bind('props.triggerMinHeight');
}
</style>
