<script lang="ts" setup>
import {
  UiButtonSize,
  UiButtonTheme,
  UiColorVar,
  UiIconValue,
  UiLinkTheme,
  UiTypography,
} from '@karta.io/ui-components';

import { updateStaging } from '@/api';
import { useErrorNotify } from '@/composables';

import { StagingAction, StagingStatus } from '@/enums';
import type { Staging, StagingClickupStatus } from '@/interfaces';

interface Props {
  id: string;
  isActive: boolean;
  isCollapsed: boolean;
  isDisabled: boolean;
  url: string;
  clickupName?: string;
  clickupCustomId?: string;
  clickupUrl?: string;
  status: StagingStatus;
  clickupStatus?: StagingClickupStatus;
}

defineOptions({ name: 'StagingManagementCard' });

const props = withDefaults(defineProps<Props>(), {
  isActive: false,
  isCollapsed: false,
  isDisabled: false,
  url: '',
  clickupCustomId: '',
  clickupUrl: '',
});

const emit = defineEmits<{
  'click:switch': [value: string];
  updated: [value: Staging];
}>();

const cn = useClassName('staging-management-card');

const isSwitchButtonLoading = ref(false);
const handleSwitchClick = () => {
  emit('click:switch', props.url);
  isSwitchButtonLoading.value = true;
};

const loading = ref(false);

const isCollapsed = ref(props.isCollapsed);
const collapsedIcon = computed(() =>
  isCollapsed.value
    ? UiIconValue.ArrowsPointingOut
    : UiIconValue.ArrowsPointingIn,
);
const clickupStatusColor = computed(() => props.clickupStatus?.color);

const statusIcon = computed(() => {
  switch (props.status) {
    case StagingStatus.Stopped:
      return {
        value: UiIconValue.XCircle,
        color: UiColorVar.Error500,
      };

    case StagingStatus.Running:
      return {
        value: UiIconValue.CheckCircle,
        color: UiColorVar.Success500,
      };

    case StagingStatus.Updating:
      return {
        value: UiIconValue.LoadingLoop,
        color: UiColorVar.Warning500,
      };

    default:
      return {
        value: UiIconValue.EmojiSad,
        color: UiColorVar.Secondary500,
      };
  }
});

const handleCollapseClick = () => (isCollapsed.value = !isCollapsed.value);

const handleStagingRun = async () => {
  loading.value = true;

  try {
    const data = await updateStaging(props.id, {
      status: StagingAction.Run,
    });
    emit('updated', data);
  } catch (error) {
    useErrorNotify(error);
  } finally {
    loading.value = false;
  }
};

const handleStagingStop = async () => {
  loading.value = true;

  try {
    const data = await updateStaging(props.id, {
      status: StagingAction.Stop,
    });
    emit('updated', data);
  } catch (error) {
    useErrorNotify(error);
  } finally {
    loading.value = false;
  }
};

watch(
  () => props.isCollapsed,
  newValue => (isCollapsed.value = newValue),
);
onMounted(() => {
  if (props.isActive) {
    isCollapsed.value = false;
  }
});
</script>

<template>
  <div
    :class="[
      cn.b(),
      cn.m('is-active', props.isActive),
      cn.m('is-collapsed', isCollapsed),
      cn.mv('status-color', props.status.toLowerCase()),
    ]"
  >
    <div :class="cn.e('header')">
      <div :class="cn.e('header-left-container')">
        <UiButton
          v-if="!props.isActive"
          :theme="UiButtonTheme.PrimaryLink"
          :disabled="
            props.isDisabled ||
            loading ||
            props.status !== StagingStatus.Running
          "
          :loading="isSwitchButtonLoading"
          @click="handleSwitchClick"
        >
          Switch
        </UiButton>

        <AppText v-if="isActive" :typography="UiTypography.TextSmSemibold">
          Active
        </AppText>

        <UiButton
          :class="cn.e('collapse-button')"
          :theme="UiButtonTheme.SecondaryLink"
          :size="UiButtonSize.Sm"
          :iconOnly="collapsedIcon"
          @click="handleCollapseClick"
        />
      </div>

      <div :class="cn.e('header-link')">
        <AppText
          :typography="UiTypography.TextMdMedium"
          :color="UiColorVar.Secondary900"
          canCopy
          tooltipTextSuccess="Custom url copied!"
        >
          {{ props.url }}
        </AppText>
      </div>

      <div :class="cn.e('header-right-container')">
        <UiButton
          :theme="UiButtonTheme.SecondaryLink"
          :size="UiButtonSize.Sm"
          :iconOnly="collapsedIcon"
          @click="handleCollapseClick"
        />
      </div>
    </div>

    <div :class="cn.e('main')">
      <div v-if="props.status" :class="cn.e('row')">
        <div :class="cn.e('label')">Status:</div>
        <div :class="cn.e('value')" style="text-transform: capitalize">
          <UiIcon
            :value="statusIcon.value"
            size="16px"
            :color="statusIcon.color"
          />
          {{ props.status.toLowerCase() }}
        </div>
      </div>

      <div :class="cn.e('row')">
        <div :class="cn.e('label')">Actions:</div>
        <div :class="cn.e('value')">
          <UiButton
            v-if="props.status !== StagingStatus.Stopped"
            :theme="UiButtonTheme.PrimaryLink"
            destructive
            :size="UiButtonSize.Xs"
            :loading="loading"
            :disabled="props.isDisabled || loading"
            @click="handleStagingStop"
          >
            Stop staging
          </UiButton>

          <UiButton
            v-if="props.status === StagingStatus.Stopped"
            :theme="UiButtonTheme.PrimaryLink"
            :size="UiButtonSize.Xs"
            :loading="loading"
            :disabled="props.isDisabled || loading"
            @click="handleStagingRun"
          >
            Run staging
          </UiButton>
        </div>
      </div>

      <div v-if="props.url" :class="cn.e('row')">
        <div :class="cn.e('label')">Links:</div>
        <div :class="cn.e('value')">
          <UiLink
            :theme="UiLinkTheme.PrimaryLink"
            :href="`${props.url}/swagger?urls.primaryName=schema-app-internal`"
            :suffixIcon="UiIconValue.ExternalLink"
          >
            Swagger
          </UiLink>

          <UiLink
            :theme="UiLinkTheme.SecondaryLink"
            :href="`${props.url}/admin`"
            :suffixIcon="UiIconValue.ExternalLink"
          >
            Admin
          </UiLink>
        </div>
      </div>

      <template v-if="props.clickupUrl">
        <UiDivider label="Clickup" style="margin-bottom: var(--spacing-02)" />

        <div v-if="props.clickupName" :class="cn.e('row')">
          <div :class="cn.e('label')">Name:</div>
          <div :class="cn.e('value')">
            {{ props.clickupName }}
          </div>
        </div>

        <div v-if="props.clickupStatus?.status" :class="cn.e('row')">
          <div :class="cn.e('label')">Status:</div>
          <div :class="cn.e('value')">
            <div :class="cn.e('color-label')" />
            {{ props.clickupStatus?.status }}
          </div>
        </div>

        <div v-if="props.url" :class="cn.e('row')">
          <div :class="cn.e('label')">Links:</div>
          <div :class="cn.e('value')">
            <UiLink
              v-if="props.clickupUrl"
              :theme="UiLinkTheme.PrimaryLink"
              :href="props.clickupUrl"
              :suffixIcon="UiIconValue.ExternalLink"
            >
              {{ props.clickupCustomId }}
            </UiLink>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
.staging-management-card {
  position: relative;
  border: 1px solid var(--color-secondary-200);
  border-radius: var(--border-radius-lg);

  &_is-active {
    background-color: var(--color-primary-25);
    border-color: var(--color-primary-200);
  }

  &_status-color {
    &_stopped {
      background-color: var(--color-error-25);
      border-color: var(--color-error-200);
    }

    &_updating {
      background-color: var(--color-warning-25);
      border-color: var(--color-warning-200);
    }
  }

  &_is-collapsed {
    .staging-management-card__main {
      display: none;
    }

    .staging-management-card__header {
      border-bottom: none;
    }
  }

  &__header {
    display: grid;
    grid-template-columns: 80px 1fr;
    height: 100%;
    border-bottom: 1px solid var(--color-secondary-200);
  }

  &__header-link {
    padding: var(--spacing-04);
  }

  &__header-left-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    border-right: 1px solid var(--color-secondary-200);
    padding: var(--spacing-04);
    padding-top: 18px;
  }

  &__collapse-button {
    margin-top: var(--spacing-02);
    transform: translateX(-12px);
  }

  &__header-right-container {
    display: none;
  }

  &__main {
    padding: var(--spacing-04);
  }

  &__switch {
    position: absolute;
    top: -1px;
    right: -1px;
  }

  &__row:not(:last-child) {
    margin-bottom: var(--spacing-02);
  }

  &__label {
    margin-bottom: 2px;
    @include typography('text-sm-semibold');
  }

  &__value {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    gap: var(--spacing-02);
    @include typography('text-sm-regular');
  }

  &__color-label {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    background-color: v-bind(clickupStatusColor);
  }
}

@include breakpoint('xs') {
  .staging-management-card {
    &__header {
      grid-template-columns: 80px 1fr 70px;
    }

    &__collapse-button {
      display: none;
    }

    &__header-right-container {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 10px var(--spacing-04) 0;
      border-left: 1px solid var(--color-secondary-200);
    }

    &__row {
      display: flex;
    }

    &__label {
      width: 80px;
    }
  }
}
</style>
