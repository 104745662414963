import type { ResponseError } from '@karta.io/utils';

import { CounterpartyPaymentAccountTransferTypesMap } from '@/data';
import {
  type CompanyProviderOutcomePaymentMethod,
  Currency,
  MoveMoneySendFundsStep,
} from '@/enums';
import type {
  BaseVerticalTab,
  CompanyAccount,
  Counterparty,
  CounterpartyPaymentAccount,
} from '@/interfaces';

interface State {
  counterparty?: Counterparty;
  counterpartyPaymentAccount?: CounterpartyPaymentAccount;
  companyAccount?: CompanyAccount;

  amount?: string;
  description?: string;

  step: MoveMoneySendFundsStep;
  isLoading: boolean;
  responseError?: ResponseError;
}

export const useMoveMoneySendFundsStore = defineStore(
  'moveMoneySendFundsStore',
  {
    state: (): State => ({
      counterparty: undefined,
      counterpartyPaymentAccount: undefined,
      companyAccount: undefined,

      amount: undefined,
      description: undefined,

      step: MoveMoneySendFundsStep.Details,
      isLoading: false,
      responseError: undefined,
    }),
    getters: {
      amountCurrency: (state: State): Currency =>
        state.companyAccount?.currency || Currency.Usd,
      companyProviderOutcomePaymentMethod(
        state: State,
      ): CompanyProviderOutcomePaymentMethod | undefined {
        const { transferType } = state.counterpartyPaymentAccount || {};

        if (!transferType) return;

        return CounterpartyPaymentAccountTransferTypesMap[transferType]
          .companyProviderOutcomePaymentMethod;
      },
      steps(state: State): BaseVerticalTab<MoveMoneySendFundsStep>[] {
        return [
          {
            value: MoveMoneySendFundsStep.Details,
            label: 'Details',
            isDisabled: state.isLoading,
          },
          {
            value: MoveMoneySendFundsStep.Amount,
            label: 'Amount',
            isDisabled:
              Boolean(!state.counterparty) ||
              Boolean(!state.counterpartyPaymentAccount) ||
              Boolean(!state.companyAccount) ||
              state.isLoading,
          },
          {
            value: MoveMoneySendFundsStep.Review,
            label: 'Review',
            isDisabled:
              Boolean(!state.counterparty) ||
              Boolean(!state.counterpartyPaymentAccount) ||
              Boolean(!state.companyAccount) ||
              Boolean(!state.amount) ||
              state.isLoading,
          },
        ];
      },
    },
  },
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useMoveMoneySendFundsStore, import.meta.hot),
  );
}
