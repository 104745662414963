import type { RouteRecordRaw } from 'vue-router';

import {
  AUTH_CALLBACK_INDEX_PAGE,
  AUTH_ERROR_INDEX_PAGE,
  AUTH_LOGOUT_INDEX_PAGE,
  AUTH_SESSION_END_INDEX_PAGE,
  AUTH_SIGNUP_INDEX_PAGE,
} from '@/data';

const authRoutes: RouteRecordRaw[] = [
  {
    path: '/auth/callback',
    name: AUTH_CALLBACK_INDEX_PAGE.name,
    component: () => import('@/modules/auth/pages/AuthCallbackIndexPage.vue'),
    meta: {
      auth: false,
      layout: 'empty',
    },
  },
  {
    path: '/auth/logout',
    name: AUTH_LOGOUT_INDEX_PAGE.name,
    component: () => import('@/modules/auth/pages/AuthLogoutIndexPage.vue'),
    meta: {
      auth: false,
      layout: 'empty',
    },
  },
  {
    path: '/auth/error',
    name: AUTH_ERROR_INDEX_PAGE.name,
    component: () => import('@/modules/auth/pages/AuthErrorIndexPage.vue'),
    meta: {
      auth: false,
      layout: 'empty',
    },
  },
  {
    path: '/auth/signup',
    name: AUTH_SIGNUP_INDEX_PAGE.name,
    component: () => import('@/modules/auth/pages/AuthSignupIndexPage.vue'),
    meta: {
      auth: false,
      layout: 'empty',
    },
  },
  {
    path: '/auth/session-end',
    name: AUTH_SESSION_END_INDEX_PAGE.name,
    component: () => import('@/modules/auth/pages/AuthSessionEndIndexPage.vue'),
    meta: {
      auth: false,
      layout: 'empty',
    },
  },
];

export default authRoutes;
