<script lang="ts" setup>
import { UiAvatarSize, UiColorVar } from '@karta.io/ui-components';

import { CompanyAccountType } from '@/enums';

interface Props {
  size?: UiAvatarSize;
  type?: CompanyAccountType;
}

defineOptions({ name: 'CompanyAccountAvatar' });

const props = withDefaults(defineProps<Props>(), {
  size: UiAvatarSize.Md,
  type: CompanyAccountType.Primary,
});

const cn = useClassName('company-account-avatar');

const color = computed(() =>
  props.type === CompanyAccountType.Primary
    ? UiColorVar.Secondary900
    : UiColorVar.Primary500,
);
</script>

<template>
  <UiAvatar :class="cn.b()" :size="props.size">
    <template #svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
        <path :fill="color" d="M0 0h32v32H0z" />
        <g clip-path="url(#company-account-avatar)">
          <path
            fill="#fff"
            d="M23.178 12.267h-5.496l-3.73 3.73v-3.73H8v3.73h5.951l3.731 3.732h5.496l-3.731-3.731 3.73-3.73Z"
          />
        </g>
        <defs>
          <clipPath id="company-account-avatar">
            <path fill="#fff" d="M8 6.5h15.303v15.303H8z" />
          </clipPath>
        </defs>
      </svg>
    </template>
  </UiAvatar>
</template>

<style lang="scss">
.company-account-avatar {
  .ui-avatar__svg {
    width: 100%;
    height: 100%;
  }

  svg {
    border-radius: 50%;
  }
}
</style>
