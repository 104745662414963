import { LIMIT_DEFAULT, PAGE_DEFAULT } from '@/data';

function validateNumber(value: any, defaultValue: number): number {
  if (value === '') return defaultValue;

  return Number.isNaN(Number(value)) ? defaultValue : Number(value);
}

export function getPaginationOffset(page: any, limit: any): number {
  const validatedPage = validateNumber(page, PAGE_DEFAULT);
  const validatedLimit = validateNumber(limit, LIMIT_DEFAULT);

  return validatedPage * validatedLimit - validatedLimit;
}

interface Config {
  isExtractable?: boolean;
  isPaginatable?: boolean;
  isSearchable?: boolean;
  isSortable?: boolean;
  isFilterable?: boolean;
}

interface LoggerOptions {
  name?: string;
  color?: string;
}

const CONFIG_DEFAULT: Config = {
  isExtractable: false,
  isPaginatable: true,
  isSearchable: true,
  isSortable: true,
  isFilterable: true,
};

/**
 * @todo по идеи нужно доработать эту функцию так,
 * чтобы в случае отрицательного значения для какого-либо флага
 * проверялся весь объект params и оттуда вырезались запрещенные значения
 */

interface ConvertParams extends Record<string, any> {
  includedIds?: any[];
  excludedIds?: any[];
}

/**
 * @todo Подумать как убить этот конфиг
 */

/**
 * @todo для запроса loadBudget(budgetId) все валится,
 * так как нет доп запросов.
 * Поэтому пока добавил доп проверки на существование параметров
 */
export function convertParams(
  params: ConvertParams = {},
  config?: Config,
  loggerOptions?: LoggerOptions,
) {
  const logger = useLogger('convert-params', {
    tag: loggerOptions?.name,
    color: loggerOptions?.color || '#24A148',
  });

  let newParams = { ...params };
  const newConfig = {
    ...CONFIG_DEFAULT,
    ...config,
  };

  const {
    isExtractable,
    isPaginatable,
    isSearchable,
    isSortable,
    isFilterable,
  } = newConfig;

  if (isSearchable) {
    newParams = {
      ...newParams,
      search: params?.search || undefined,
    };
  }

  if (isPaginatable) {
    newParams = {
      ...newParams,
      limit: params?.limit || LIMIT_DEFAULT,
      offset: getPaginationOffset(params?.page, params?.limit),
    };
  }

  if (isExtractable) {
    newParams = {
      ...newParams,
      extraFields: params?.extraFields || undefined,
    };
  }

  if (isSortable) {
    newParams = {
      ...newParams,
      ordering: params?.ordering || undefined,
    };
  }

  if (isFilterable && params?.filters) {
    newParams = {
      ...newParams,
      ...params.filters,
    };
  }

  /**
   * Очищаем от ненужных полей в запросе (чтобы избежать возможных багов)
   */
  const techParams = [
    'filters',
    'period',
    'page',
    'pending',
    'timestamp',
    'periodName',
    'periodDates',
  ];
  newParams = Object.entries(newParams).reduce((accum, [key, value]) => {
    if (typeof key === 'string' && techParams.includes(key)) return accum;

    return {
      ...accum,
      [key]: Array.isArray(value) ? value.join(',') : value,
    };
  }, {});

  logger.info(newParams);

  return newParams;
}
