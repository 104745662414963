import { UiIconValue } from '@karta.io/ui-components';

import { NavigationGroup } from '@/enums';
import { CoreUserPermissionsMap } from '@/data';
import type { NavigationItem, Page } from '@/interfaces';

export const ACCOUNTS_NAVIGATION: NavigationItem = {
  id: 'accounts',
  title: 'Accounts',
  to: '/accounts',
  icon: UiIconValue.Library,
  permissions: [CoreUserPermissionsMap.ReadCompanyAccount],
  disabled: false,
  group: (hasCompanyAccounts, hasExternalAccounts) =>
    hasCompanyAccounts || hasExternalAccounts
      ? NavigationGroup.Active
      : NavigationGroup.Inactive,
};

export const ACCOUNTS_COMPANY_PAGE: Page = {
  name: 'accounts-company-page',
  metaInfo: {
    title: 'Company accounts',
  },
  navigation: ACCOUNTS_NAVIGATION,
};

export const ACCOUNTS_EXTERNAL_PAGE: Page = {
  name: 'accounts-external-page',
  metaInfo: {
    title: 'External accounts',
  },
  navigation: ACCOUNTS_NAVIGATION,
};

export const ACCOUNTS_INDEX_PAGE: Page = {
  name: 'accounts-index-page',
  metaInfo: {
    title: 'Accounts',
  },
  navigation: ACCOUNTS_NAVIGATION,
  tabs: [],
};
