import { NavigationGroup } from '@/enums';

import { CoreUserPermissionsMap } from '@/data';
import type { NavigationItem, Page } from '@/interfaces';

export const OVERVIEW_NAVIGATION: NavigationItem = {
  id: 'overview',
  title: 'Overview',
  to: '/overview',
  icon: 'heroicons-outline:view-grid',
  permissions: [CoreUserPermissionsMap.ReadCompanyAccount],
  disabled: false,
  group: (hasCompanyAccounts, hasExternalAccounts, isCompanyAccountFunded) =>
    hasCompanyAccounts && isCompanyAccountFunded
      ? NavigationGroup.Active
      : NavigationGroup.Inactive,
};

export const OVERVIEW_INDEX_PAGE: Page = {
  name: 'overview-index-page',
  metaInfo: {
    title: 'Overview',
  },
  navigation: OVERVIEW_NAVIGATION,
};
