<script setup lang="ts">
import { Currency } from '@/enums';
import type { InvoiceItem } from '@/interfaces';

import TableCellCurrency from '@/components/table/TableCellCurrency.vue';

interface Props {
  isLoading?: boolean;
  invoiceItems?: InvoiceItem[];
  totalPrice?: string;
  currency?: Currency;
}

defineOptions({
  name: 'InvoiceItemsPanelTable',
});

const props = withDefaults(defineProps<Props>(), {
  isLoading: false,
  invoiceItems: () => [],
  totalPrice: '0',
  currency: Currency.Usd,
});

const { isMobile } = useBreakpoint();
const cn = useClassName('invoice-items-panel-table');

const columns = computed(() => [
  {
    key: 'name',
    name: 'Item',
    styles: {
      minWidth: '50px',
    },
  },
  {
    key: 'quantity',
    name: isMobile.value ? 'Qty' : 'Quantity',
    styles: {
      minWidth: '50px',
      maxWidth: '80px',
    },
  },
  {
    key: 'price',
    name: 'Item price',
    cell: {
      component: TableCellCurrency,
      props: {
        value: 'row.price',
        currency: props.currency,
        align: 'right',
      },
    },
    styles: {
      align: 'right',
      minWidth: '50px',
      maxWidth: '230px',
      flexGrow: 1,
    },
  },
]);

const totalPrice = computed(() =>
  formatMoney(props.totalPrice, props.currency),
);
</script>

<template>
  <div :class="cn.b()">
    <PanelTableSkeleton v-if="props.isLoading" />

    <Table
      v-else
      :columns="columns"
      :rows="props.invoiceItems"
      :loading="props.isLoading"
    />

    <div :class="cn.e('total-price')">
      Total
      <span :class="cn.e('total-price-value')">
        {{ totalPrice }}
      </span>
    </div>
  </div>
</template>

<style lang="scss">
.invoice-items-panel-table {
  display: flex;
  flex-direction: column;

  & .ui-table,
  .ui-table-head-cell {
    --ui-table-cell-padding-x: var(--spacing-02);
    --ui-table-row-padding-x: var(--spacing-02);
  }

  &__total-price {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    gap: var(--spacing-02);
    padding: var(--spacing-06) var(--spacing-04);
    color: var(--color-secondary-500);
    @include typography('text-xs-medium');
  }

  &__total-price-value {
    color: var(--color-secondary-900);
    @include typography('text-md-medium');
    @include text-ellipsis();
  }
}

@include breakpoint('sm') {
  .invoice-items-panel-table {
    & .ui-table,
    .ui-table-head-cell {
      --ui-table-cell-padding-x: var(--spacing-02);
      --ui-table-row-padding-x: var(--spacing-06);
    }

    &__total-price {
      padding: var(--spacing-06) var(--spacing-08);
    }
  }
}
</style>
