import { UiColor } from '@karta.io/ui-components';
import type { ResponseError } from '@karta.io/utils';

import { CoreForbiddenReason } from '@/enums';

export function useErrorNotify(
  error: ResponseError | Error | any,
  showAll?: boolean,
): void {
  if (!error) return;
  const { data, statusMessage, message, status } = error;

  /**
   * @todo Проверить работу в случае 400 и 422 ошибок,
   * они не должны выводиться через нотификации
   */

  if (!data || status >= 500) {
    UiToastNotify({
      title: 'Error',
      message: statusMessage || message,
      color: UiColor.Error,
    });
    return;
  }

  /**
   * TODO исправить типы!
   */
  if (status === 403 && data.reason === CoreForbiddenReason.AccessDenied) {
    UiToastNotify({
      title: 'Error',
      message: "You don't have permission for this action",
      color: UiColor.Error,
    });
    return;
  }

  if (data.detail) {
    const message =
      typeof data.detail === 'string' ? data.detail : data.detail[0];

    UiToastNotify({
      title: 'Error',
      message,
      color: UiColor.Error,
    });

    return;
  }

  if (showAll) {
    if (typeof data === 'object' && Object.keys(data)) {
      Object.values(data).forEach(errorText => {
        UiToastNotify({
          title: 'Error',
          message: errorText,
          color: UiColor.Error,
        });
      });

      return;
    }

    UiToastNotify({
      title: status,
      message,
      color: UiColor.Error,
    });
  }
}
