<script setup lang="ts">
import {
  UiButtonSize,
  UiButtonTheme,
  UiIconValue,
} from '@karta.io/ui-components';

defineOptions({
  name: 'TheHeader',
});

const emit = defineEmits(['onMobileMenuOpen']);

const cn = useClassName('the-header');
</script>

<template>
  <header :class="cn.b()">
    <div :class="cn.e('burger')">
      <UiButton
        :theme="UiButtonTheme.SecondaryTertiary"
        :size="UiButtonSize.Sm"
        :iconOnly="UiIconValue.Menu"
        @click="emit('onMobileMenuOpen')"
      />
    </div>
    <router-link to="/" :class="cn.e('logo')">
      <IconLogo />
    </router-link>
  </header>
</template>

<style lang="scss">
.the-header {
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--the-header-height);
  padding: 0 var(--spacing-04);
  background-color: var(--color-base-white);

  &__logo {
    display: inline-block;
    margin-left: var(--spacing-03);
    margin-bottom: var(--spacing-01);
  }
}
</style>
