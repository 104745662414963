import { permissionsGuard } from '@/router/guards';
import { useSaveQuery } from '@/composables';

import {
  ACCOUNTS_COMPANY_PAGE,
  ACCOUNTS_EXTERNAL_PAGE,
  ACCOUNTS_INDEX_PAGE,
  CoreUserPermissionsMap,
} from '@/data';

const { setQuery } = useSaveQuery(ACCOUNTS_COMPANY_PAGE.name);

export default [
  {
    path: '/accounts',
    name: ACCOUNTS_INDEX_PAGE.name,
    component: () => import('@/modules/accounts/pages/AccountsIndexPage.vue'),
    beforeEnter: permissionsGuard,
    meta: {
      auth: true,
      navigation: ACCOUNTS_INDEX_PAGE.navigation,
      permissions: [CoreUserPermissionsMap.ReadCompanyAccount],
    },
    children: [
      {
        path: 'company',
        name: ACCOUNTS_COMPANY_PAGE.name,
        component: () =>
          import('@/modules/accounts/pages/AccountsCompanyPage.vue'),
        beforeEnter: [permissionsGuard, setQuery],
        meta: {
          auth: true,
          navigation: ACCOUNTS_COMPANY_PAGE.navigation,
          permissions: [CoreUserPermissionsMap.ReadCompanyAccount],
        },
      },
      {
        path: 'external',
        name: ACCOUNTS_EXTERNAL_PAGE.name,
        component: () =>
          import('@/modules/accounts/pages/AccountsExternalPage.vue'),
        beforeEnter: permissionsGuard,
        meta: {
          auth: true,
          navigation: ACCOUNTS_EXTERNAL_PAGE.navigation,
          permissions: [CoreUserPermissionsMap.ReadExternalAccount],
        },
      },
    ],
  },
];
