import { UiIconValue } from '@karta.io/ui-components';

import { NavigationGroup } from '@/enums';

import { CoreUserPermissionsMap } from '@/data';
import type { NavigationItem, Page } from '@/interfaces';

export const TRANSACTIONS_NAVIGATION: NavigationItem = {
  id: 'transactions',
  title: 'Transactions',
  to: '/transactions',
  icon: UiIconValue.SwitchHorizontal,
  permissions: [CoreUserPermissionsMap.ReadTransaction],
  disabled: false,
  group: (hasCompanyAccounts, hasExternalAccounts) =>
    hasCompanyAccounts || hasExternalAccounts
      ? NavigationGroup.Active
      : NavigationGroup.Inactive,
};

export const TRANSACTIONS_COMPANY_PAGE: Page = {
  name: 'transactions-company-page',
  metaInfo: {
    title: 'Company transactions',
  },
  navigation: TRANSACTIONS_NAVIGATION,
};

export const TRANSACTIONS_EXTERNAL_PAGE: Page = {
  name: 'transactions-external-page',
  metaInfo: {
    title: 'External transactions',
  },
  navigation: TRANSACTIONS_NAVIGATION,
};

export const TRANSACTIONS_INDEX_PAGE: Page = {
  name: 'transactions-index-page',
  metaInfo: {
    title: 'Transactions',
  },
  navigation: TRANSACTIONS_NAVIGATION,
  tabs: [
    {
      label: 'Company',
      value: TRANSACTIONS_COMPANY_PAGE.name,
    },
    {
      label: 'External',
      value: TRANSACTIONS_EXTERNAL_PAGE.name,
    },
  ],
};
