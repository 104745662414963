import SmartySDK from 'smartystreets-javascript-sdk';

import { useEnv } from './index';

const { smartyKey } = useEnv();

interface Suggestion
  extends Partial<SmartySDK.internationalAddressAutocomplete.Suggestion> {
  entries?: number;
  addressText?: string;
  addressId?: string;
}

const joinAddress = (suggestion: Suggestion): string => {
  const street = `${suggestion.street}`;
  const city = suggestion.locality ? `, ${suggestion.locality}` : '';
  const administrativeArea =
    suggestion.locality === suggestion.administrativeArea
      ? ''
      : `, ${suggestion.administrativeArea}`;
  const zip = suggestion.postalCode ? `, ${suggestion.postalCode}` : '';

  return street + city + administrativeArea + zip;
};

/**
 * Библиотеку типов до сих пор не обновили.
 * @see {@link https://get.smarty.com/international-autocomplete-api-v2-migration | Migration guide}
 * @see {@link https://www.smarty.com/docs/cloud/international-address-autocomplete-api | Docs}
 */

export function useAddressAutocompleteInternational() {
  const SmartyCore = SmartySDK.core;
  const Lookup = SmartySDK.internationalAddressAutocomplete.Lookup;

  const credentials = new SmartyCore.SharedCredentials(smartyKey);

  const clientBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses([
    'international-autocomplete-v2-cloud',
  ]);

  const loading = ref(false);
  const suggestions = ref<Suggestion[]>();
  const options = computed<{ label: string; value: number }[]>(
    () =>
      suggestions.value?.map((suggestion, index) => {
        return {
          label: suggestion.addressText || joinAddress(suggestion),
          value: index,
        };
      }) ?? [],
  );

  const client = clientBuilder.buildInternationalAddressAutocompleteClient();

  const searchSuggestions = async ({
    search,
    country,
    addressId,
  }: {
    search?: string;
    country: string;
    addressId?: string;
  }) => {
    loading.value = true;
    try {
      let lookup;
      if (addressId) {
        // @ts-ignore
        lookup = new Lookup({ addressId, country });
      } else {
        // @ts-ignore
        lookup = new Lookup({ search, country });
      }

      const { result }: { result: Suggestion[] } = await client.send(lookup);

      if (result.length === 1 && !result[0]?.addressId) {
        return result[0];
      }
      suggestions.value = result;
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  };

  const searchSuggestionsWithDebounce = useDebounceFn(searchSuggestions, 300);

  return {
    loading,
    suggestions,
    options,
    searchSuggestions,
    searchSuggestionsWithDebounce,
  };
}
