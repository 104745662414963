import { UiColor } from '@karta.io/ui-components';

import { ExternalAccountStatus, ExternalAccountType } from '@/enums';

export const ExternalAccountTypesMap = {
  [ExternalAccountType.Credit]: {
    title: 'Credit account',
    titleShort: 'Credit',
  },
  [ExternalAccountType.Depository]: {
    title: 'Depository account',
    titleShort: 'Depository',
  },
  [ExternalAccountType.Loan]: {
    title: 'Loan account',
    titleShort: 'Loan',
  },
  [ExternalAccountType.Other]: {
    title: 'Other account',
    titleShort: 'Other',
  },
} as const;

export const ExternalAccountStatusesMap = {
  [ExternalAccountStatus.Waiting]: {
    tagLabel: 'Waiting',
    tagColor: UiColor.Secondary,
  },
  [ExternalAccountStatus.Connected]: {
    tagLabel: 'Connected',
    tagColor: UiColor.Primary,
  },
  [ExternalAccountStatus.Disconnected]: {
    tagLabel: 'Disconnected',
    tagColor: UiColor.Error,
  },
} as const;
