export enum BudgetLimitType {
  NoLimit = 'NO_LIMIT',
  Daily = 'DAILY_LIMIT',
  Weekly = 'WEEKLY_LIMIT',
  Monthly = 'MONTHLY_LIMIT',
  Year = 'YEAR_LIMIT',
  Lifetime = 'LIFETIME_LIMIT',
  Transaction = 'TRANSACTION_LIMIT',
}

export enum BudgetUserLimitType {
  NoLimit = 'NO_LIMIT',
  Daily = 'DAILY_LIMIT',
  Weekly = 'WEEKLY_LIMIT',
  Monthly = 'MONTHLY_LIMIT',
  Year = 'YEAR_LIMIT',
  Lifetime = 'LIFETIME_LIMIT',
  Transaction = 'TRANSACTION_LIMIT',
}

export enum BudgetStatus {
  Active = 'ACTIVE',
  Locked = 'PAUSED',
  Closed = 'CLOSED',
}

export enum BudgetUserRole {
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  Member = 'MEMBER',
}

export enum BudgetMemberCallbackType {
  Delete = 'DELETE',
  Update = 'UPDATE',
}

export enum BudgetDetailsCallbackEventName {
  Budget = 'onBudgetUpdate',
  BudgetUsers = 'onBudgetUsersUpdate',
}

export enum BudgetUserEvent {
  BudgetUserCreated = 'create:budgetUser',
  BudgetUserDeleted = 'delete:budgetUser',
  BudgetUserLimitUpdated = 'update:budgetUserLimit',
  BudgetUserRoleUpdated = 'update:budgetUserRole',
}
