<script setup lang="ts">
import { UiColorVar, UiTypography } from '@karta.io/ui-components';

defineOptions({ name: 'BaseNoOptions' });

const cn = useClassName('base-no-options');
</script>

<template>
  <div :class="cn.b()">
    <UiIcon
      value="material-symbols:search-off"
      :color="UiColorVar.Secondary500"
      width="48"
      height="48"
    />

    <AppText :typography="UiTypography.TextSmMedium">
      No results were found
    </AppText>

    <slot />
  </div>
</template>

<style lang="scss">
.base-no-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-08) var(--spacing-03);
}
</style>
