import { CORE_ACCOUNT_NAVIGATION } from '@/data';
import type { Page } from '@/interfaces';

export const EXTERNAL_ACCOUNT_TRANSACTIONS_PAGE: Page = {
  name: 'external-account-transactions-page',
  metaInfo: {
    title: 'External account transactions',
  },
  navigation: CORE_ACCOUNT_NAVIGATION,
};

export const EXTERNAL_ACCOUNT_INDEX_PAGE: Page = {
  name: 'external-account-index-page',
  metaInfo: {
    title: 'External account',
  },
  navigation: CORE_ACCOUNT_NAVIGATION,
  tabs: [
    {
      label: 'Transactions',
      value: EXTERNAL_ACCOUNT_TRANSACTIONS_PAGE.name,
    },
  ],
};
