<script lang="ts" setup>
import {
  UiColorVar,
  UiIconValue,
  type UiTableColumn,
  UiTableSortingOrder,
  UiTypography,
} from '@karta.io/ui-components';

interface Props {
  column: UiTableColumn;
  sorting?: string;
  isLoading?: boolean;
}

defineOptions({ name: 'UiTableHeadCell' });

const props = withDefaults(defineProps<Props>(), {
  sorting: undefined,
  isLoading: undefined,
});

const emit = defineEmits<{
  sort: [value?: string];
}>();

enum Sorting {
  None = 'NONE',
  Asc = 'ASC',
  Desc = 'DESC',
}

const sortingOrder = computed(
  () => props.column.sortingOrder ?? UiTableSortingOrder.NoneAscDesc,
);

const nextOrderMap = computed(() => {
  const array = sortingOrder.value.split('_');
  return {
    [array[1]]: array[2],
    [array[2]]: array[0],
    [array[0]]: array[1],
  };
});

const isSortable = computed(() => {
  return props.column.sortable || false;
});

const align = computed(() => {
  return props.column?.styles?.align ?? 'left';
});

const order = computed(() => {
  if (!props.sorting) return Sorting.None;

  const columnKey = props.sorting.replace('-', '');
  if (columnKey !== props.column.key) return Sorting.None;

  return props.sorting.includes('-') ? Sorting.Asc : Sorting.Desc;
});

const iconValue = computed(() => {
  return order.value === Sorting.None
    ? UiIconValue.SwitchVertical
    : UiIconValue.ArrowSmDown;
});

const handleClick = () => {
  if (!isSortable.value) return;
  const nextOrder = nextOrderMap.value[order.value];

  if (nextOrder === Sorting.None) {
    emit('sort', undefined);
    return;
  }

  emit(
    'sort',
    nextOrder === Sorting.Asc ? `-${props.column.key}` : props.column.key,
  );
};

const cn = useClassName('table-head-cell');
</script>

<template>
  <div
    :class="[cn.b(), cn.m('is-sortable', isSortable), cn.mv('align', align)]"
    @click="handleClick"
  >
    <AppText
      :typography="UiTypography.TextXsMedium"
      :loading="props.isLoading"
      :color="UiColorVar.Secondary500"
      skeletonWidth="40px"
      textEllipsis="100%"
    >
      {{ props.column.name }}
    </AppText>

    <div v-if="isSortable && !props.isLoading" :class="cn.e('icon-container')">
      <UiIcon
        :value="iconValue"
        :class="[cn.e('icon'), cn.em('icon', `order_${order.toLowerCase()}`)]"
        width="16px"
        height="16px"
      />
    </div>
  </div>
</template>

<style lang="scss">
.table-head-cell {
  $this: &;

  display: grid;
  grid-template-columns: minmax(0, 1fr);
  align-items: center;
  gap: var(--spacing-01);
  height: 100%;

  &_is-sortable {
    grid-template-columns: minmax(0, 1fr) auto;
    user-select: none;

    &:hover {
      cursor: pointer;
      color: var(--color-secondary-700);

      #{$this}__icon_order_none {
        opacity: 1;
      }

      .app-text__value {
        color: var(--color-secondary-700);
      }
    }
  }

  &_align_left {
    grid-template-columns: minmax(0, 1fr) auto;
  }

  &_align_right {
    grid-template-columns: auto minmax(0, 1fr);
    grid-auto-flow: dense;
    direction: rtl;
  }

  &__icon-container {
    display: inline-flex;
    width: 16px;
    height: 16px;
  }

  &__icon {
    &_order_none {
      opacity: 0;
    }

    &_order_desc {
      transition: transform var(--transition-duration);
      transform: rotate(180deg);
    }
  }
}
</style>
