import { type HttpRequestConfig, http, httpCMS } from '@/api';

import type { Currency } from '@/enums';
import type {
  CoreApiTokens,
  CoreAppConfig,
  CoreAppLegals,
  CoreExchangeRateItem,
  CorePhoneChangeRequest,
  CorePhoneChangeRequestCreateRequest,
  CoreUserChangeEmailCreateRequest,
  CoreUserChangePasswordCreateRequest,
  CoreUserEditableData,
  CoreUserKybLink,
  CoreUserMe,
  UiSettings,
} from '@/interfaces';

const BASE_PATH = 'core/';
const BASE_USER_PATH = 'core/user/';

export const readCoreAppVersion = (): Promise<{ version: string }> =>
  http.get(`${BASE_PATH}version/`);

export const readCoreAppConfig = (
  config?: HttpRequestConfig,
): Promise<CoreAppConfig> =>
  httpCMS.get('/app-config', {
    ...config,
    params: {
      populate: '*',
    },
  });

export const readCoreAppLegals = (
  config?: HttpRequestConfig,
): Promise<{ data: CoreAppLegals[] }> => httpCMS.get('/legals/latest', config);

/**
 * coreUsers
 */

export const readCoreUser = (config?: HttpRequestConfig): Promise<CoreUserMe> =>
  http.get(BASE_USER_PATH, config);

export const readCoreUserKybLink = (): Promise<CoreUserKybLink> =>
  http.get(`${BASE_USER_PATH}kyb_link/`);

export const readCoreApiTokens = (
  code: string,
  redirectUri: string,
): Promise<CoreApiTokens> =>
  http.get(`${BASE_USER_PATH}public_api_callback/`, {
    params: { code, redirectUri },
  });

/**
 * update core user data
 */

export const createCoreUserResendEmailVerification = (): Promise<void> =>
  http.post(`${BASE_USER_PATH}resend_email_verification/`);

export const readCoreAbilityResendEmailVerification = (): Promise<{
  timeout: number;
}> => http.get(`${BASE_USER_PATH}ability_resend_email_verification/`);

export const createCoreUserChangeEmail = (
  data: CoreUserChangeEmailCreateRequest,
): Promise<void> => http.post(`${BASE_USER_PATH}change_email/`, data);

export const createCoreUserDeclineNewEmail = (): Promise<void> =>
  http.post(`${BASE_USER_PATH}decline_new_email/`);

export const createCoreUserResendCodeForNewEmail = (): Promise<void> =>
  http.post(`${BASE_USER_PATH}resend_code_for_new_email/`);

export const createCoreUserVerifyCodeForNewEmail = (
  code: string,
): Promise<void> =>
  http.post(`${BASE_USER_PATH}verify_code_for_new_email/`, { code });

export const readCoreAbilityResendCodeForNewEmail = (): Promise<{
  timeout: number;
}> => http.get(`${BASE_USER_PATH}ability_resend_code_for_new_email/`);

export const createCoreUserChangePassword = (
  data: CoreUserChangePasswordCreateRequest,
): Promise<void> => http.post(`${BASE_USER_PATH}change_password/`, data);

export const updateCoreUser = (
  data: CoreUserEditableData,
): Promise<CoreUserEditableData> => http.patch(BASE_USER_PATH, data);

/**
 * CHANGE PHONE FLOW
 */

export const createCorePhoneChangeRequest = (
  data: CorePhoneChangeRequestCreateRequest,
): Promise<CorePhoneChangeRequest> =>
  http.post(`${BASE_USER_PATH}change_phone/`, data);

export const createCoreSendCodeForPhone = (): Promise<void> =>
  http.post(`${BASE_USER_PATH}send_code_for_phone/`);

export const createCoreVerifyCodeForPhone = (data: {
  code: string;
}): Promise<void> => http.post(`${BASE_USER_PATH}verify_code_for_phone/`, data);

export const readCorePhoneChangeRequests = (): Promise<
  CorePhoneChangeRequest[]
> => http.get(`${BASE_USER_PATH}change_phone/`);

/**
 * EXCHANGE RATE
 */

export const readCoreExchangeRate = (
  params: {
    toCurrency?: Currency;
    fromCurrency?: Currency;
  } = {},
): Promise<CoreExchangeRateItem[]> =>
  http.get('karta/currency_exchange_rate/', {
    params,
  });

/**
 * UI SETTINGS
 */

export const readCoreUiSettings = (
  config?: HttpRequestConfig,
): Promise<UiSettings> => http.get(`${BASE_PATH}ui_settings/`, config);

export const updateCoreUiSettings = (data: UiSettings): Promise<UiSettings> =>
  http.put(`${BASE_PATH}ui_settings/`, data);
