<script lang="ts" setup>
import {
  useEnv,
  useStagingManagement,
  useStagingManagementWebsocket,
} from '@/composables';

import { CoreLocalStorage, StagingManagementWsServerEvent } from '@/enums';

import StagingManagementModal from '@/modules/staging-management/components/StagingManagementModal.vue';

defineOptions({ name: 'StagingManagementRoot' });

const route = useRoute();
const router = useRouter();
const apiBaseUrlStore = useStorage<string>(CoreLocalStorage.ApiBaseUrl, '');
const { isProduction, isDemo } = useEnv();
const { open: openDialog } = useDialog();

const {
  hash,
  openModal,
  closeModal,

  urls,
  isUrlCustomExist,

  isLoading,
  isError,

  refetch,
  updateStaging,
  updateStagingClickup,
} = useStagingManagement();

const ws = useStagingManagementWebsocket();

ws.createConnection();
ws.on(StagingManagementWsServerEvent.StagingCreated, refetch);
ws.on(StagingManagementWsServerEvent.StagingUpdated, updateStaging);
ws.on(StagingManagementWsServerEvent.StagingDeleted, refetch);
ws.on(StagingManagementWsServerEvent.TaskUpdated, updateStagingClickup);

onBeforeUnmount(() => {
  ws.off(StagingManagementWsServerEvent.StagingCreated, refetch);
  ws.off(StagingManagementWsServerEvent.StagingUpdated, updateStaging);
  ws.off(StagingManagementWsServerEvent.StagingDeleted, refetch);
  ws.off(StagingManagementWsServerEvent.TaskUpdated, updateStagingClickup);
});

watch(
  [isLoading, isError, urls],
  ([isLoadingValue, isErrorValue, urlsValue]) => {
    if (!apiBaseUrlStore.value || route.hash === hash) return;

    if (isErrorValue) {
      UiToastNotify({
        title: 'Error',
        message: 'Failed to get stage list',
        color: 'error',
      });
      return;
    }

    if (isLoadingValue || !urlsValue.length) {
      return;
    }
    if (isUrlCustomExist.value) {
      return;
    }

    UiToastNotify({
      title: 'Error',
      message: 'Current base api is not exist',
      color: 'error',
      position: 'bottom-left',
      duration: 0,
      buttons: {
        first: {
          title: 'Change base url',
          click: openModal,
        },
      },
    });
  },
  { immediate: true },
);

watch(
  () => route.hash,
  async (newHash, oldHash) => {
    if (isProduction && isDemo) return;

    if (!newHash && oldHash) {
      closeModal();
      return;
    }

    if (newHash === hash) {
      await nextTick();
      openDialog({
        component: StagingManagementModal,
        props: {
          'onUpdate:modelValue': (value: boolean) => {
            if (value) return;
            router.push({
              ...route,
              hash: undefined,
            });
          },
        },
      });
    }
  },
  { immediate: true },
);
</script>

<template>
  <div class="staging-management-root" />
</template>
