import {
  type ManagerOptions,
  type Socket,
  type SocketOptions,
  io,
} from 'socket.io-client';

import { useEnv } from '@/composables';

const { isDemo } = useEnv();

interface ServerToClientEventsDefault {
  [key: string]: any;
}
interface ClientToServerEventsDefault {
  [key: string]: any;
}

const socketsMap = new Map<string, Socket>();

export function useWebsocket<
  SE extends ServerToClientEventsDefault,
  CE extends ClientToServerEventsDefault,
>({
  url,
  loggerName,
  socketParams,
}: {
  url: string;
  loggerName?: string;
  socketParams?: Partial<ManagerOptions & SocketOptions>;
}) {
  const wsUrl = url.replace(/^https/, 'wss');

  const logger = useLogger('use-websocket', {
    tag: loggerName ?? wsUrl,
    color: '#36c4a4',
  });

  let socket: Socket<SE, CE>;

  const init = () => {
    if (isDemo) return;

    if (socketsMap.has(wsUrl)) {
      socket = socketsMap.get(wsUrl)!;
    } else {
      socket = io(wsUrl, socketParams);

      socket.on('connect', () => {
        logger.log('connect', wsUrl);
      });
      socket.on('disconnect', () => {
        logger.log('disconnect', wsUrl);
      });
      socketsMap.set(wsUrl, socket);

      logger.log('init');
    }
  };

  const on = (...args: Parameters<Socket<SE, CE>['on']>) => {
    if (!socket) {
      logger.error('subscribe: socket not inited', args[0]);
      return;
    }

    socket.on(...args);
    logger.log('subscribe', args[0]);
  };

  const off = (...args: Parameters<Socket<SE, CE>['off']>) => {
    if (!socket) {
      logger.error('unsubscribe: socket not inited', args[0]);
      return;
    }

    socket.off(...args);
    logger.log('unsubscribe', args[0]);
  };

  const disconnect = () => {
    if (!socket) {
      logger.error('disconnect: socket not inited');
      return;
    }

    socket.disconnect();
    socketsMap.delete(wsUrl);
  };

  const emit = (...args: Parameters<Socket<SE, CE>['emit']>) => {
    if (!socket) {
      logger.error('emit: socket not inited', args[0]);
      return;
    }
    socket.emit(...args);
    logger.log('emit', args[0]);
  };

  return {
    init,
    on,
    off,
    emit,
    disconnect,
    logger,
  };
}
