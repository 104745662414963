import qs from 'qs';
import { type Nillable, type Nullable, UiColor } from '@karta.io/ui-components';
import type { AppFilterTagDatePreset } from '@karta.io/app-components';

import {
  FILTER_DATE_FORMAT_DEFAULT,
  getFilterDateDefaultPeriod,
  getFilterDatePresets,
} from '@/data';

import type { FilterDatePeriodName } from '@/enums';
import type { Periodable } from '@/interfaces';

const ALLOWED_PERIOD_DEFAULT = 366;
const dayjs = useDayjs();

const getFFC = (date: Nillable<Date>): Nullable<string> => {
  if (!date) return null;
  return dayjs(date).format(FILTER_DATE_FORMAT_DEFAULT);
};

/**
 * @tutorial
 * При использовании кастомного набора пресетов и/или кастомного дефолтного периода
 * нужно опрокидывать их во всех местах использования функции
 * Пример: для транзакций отличается набор пресетов, поэтому мы его опрокидываем
 * и в компоненте с фильтрами, и в компоненте страницы (где достаем dateParams для запроса)
 */
export function useFilterDate({
  defaultPeriod = getFilterDateDefaultPeriod(),
  allowedPeriod = ALLOWED_PERIOD_DEFAULT,
  getPresets = getFilterDatePresets,
  updateFilter,
}: {
  defaultPeriod?: {
    start: Nullable<Date>;
    end: Nullable<Date>;
  };
  allowedPeriod?: Nullable<number>;
  getPresets?: () => AppFilterTagDatePreset[];
  updateFilter?: (filters: Record<string, any>) => void;
} = {}) {
  const route = useRoute();

  const presets = getPresets();

  const defaultPresetKey =
    presets.find(
      item =>
        getFFC(item.start) === getFFC(defaultPeriod.start) &&
        getFFC(item.end) === getFFC(defaultPeriod.end),
    )?.periodName || null;

  const setDefaultPeriod = () => {
    const newPeriod = {
      startDate: dayjs(defaultPeriod.start).format(FILTER_DATE_FORMAT_DEFAULT),
      endDate: dayjs(defaultPeriod.end).format(FILTER_DATE_FORMAT_DEFAULT),
    };
    updateFilter?.({
      periodName: undefined,
      periodDates: [newPeriod.startDate, newPeriod.endDate],
    });
  };

  const isDefaultPeriod = computed(
    () =>
      getFFC(period.value.start) === getFFC(defaultPeriod.start) &&
      getFFC(period.value.end) === getFFC(defaultPeriod.end),
  );

  const period = computed({
    get: () => {
      const query = qs.parse(route.query as Record<string, any>) as {
        filters?: {
          periodName?: FilterDatePeriodName;
          periodDates?: string;
        };
      };

      if (query.filters?.periodName) {
        const periodNameFromQuery = query.filters.periodName;

        const value = presets.find(
          item => item.periodName === periodNameFromQuery,
        );

        const { start, end } = value || {};

        /**
         * Проверяем есть ли start и end в периоде с переданным в кверю названием
         * (так же обрабатываем кейс, если нет такого периода)
         * В противном случае возвращаем дефолтный период и обновляем кверю
         */
        if (start === undefined && end === undefined) {
          setDefaultPeriod();

          return defaultPeriod;
        }

        return { start, end };
      }

      if (query.filters?.periodDates) {
        const [startDate, endDate] = query.filters.periodDates.split(',');

        /**
         * Проверяем валидны ли start и end
         * В противном случае возвращаем дефолтный период и обновляем кверю
         */
        if (!dayjs(startDate).isValid() || !dayjs(endDate).isValid()) {
          setDefaultPeriod();
          return defaultPeriod;
        }

        /**
         * Проверяем в правильном ли порядке переданы start и end
         * В противном случае меняем их местами и обновляем кверю
         */
        const isEndAfterStart =
          dayjs(startDate).isBefore(dayjs(endDate)) ||
          dayjs(startDate).isSame(dayjs(endDate));

        if (isEndAfterStart) {
          return {
            start: dayjs(startDate).toDate(),
            end: dayjs(endDate).toDate(),
          };
        }

        updateFilter?.({
          periodName: undefined,
          periodDates: [endDate, startDate],
        });

        return {
          start: dayjs(endDate).toDate(),
          end: dayjs(startDate).toDate(),
        };
      }
      return defaultPeriod;
    },
    set: newValue => {
      const { start, end } = newValue;
      const preset = presets.find(
        item =>
          getFFC(item.start) === getFFC(start) &&
          getFFC(item.end) === getFFC(end),
      );
      const periodName = preset?.periodName || null;

      if (periodName) {
        updateFilter?.({
          periodName: periodName === defaultPresetKey ? undefined : periodName,
          periodDates: undefined,
        });

        return;
      }

      const isEndAfterStart = dayjs(start).isBefore(dayjs(end));
      const currentStartDate = isEndAfterStart ? dayjs(start) : dayjs(end);
      let currentEndDate = isEndAfterStart ? dayjs(end) : dayjs(start);

      if (
        allowedPeriod &&
        currentEndDate.diff(currentStartDate, 'day') > allowedPeriod
      ) {
        currentEndDate = currentStartDate.add(allowedPeriod, 'day');
        UiToastNotify({
          title: 'Changing the selected date',
          message: `The date range was shortened due to a ${allowedPeriod}-day limit.`,
          color: UiColor.Warning,
        });
      }

      const newPeriod = {
        startDate: dayjs(currentStartDate).format(FILTER_DATE_FORMAT_DEFAULT),
        endDate: dayjs(currentEndDate).format(FILTER_DATE_FORMAT_DEFAULT),
      };
      updateFilter?.({
        periodName: undefined,
        periodDates: [newPeriod.startDate, newPeriod.endDate],
      });
    },
  });

  const clear = () => {
    period.value = {
      start: defaultPeriod.start,
      end: defaultPeriod.end,
    };
  };

  const dateParams = computed<Periodable>(() => ({
    startDate: period.value?.start
      ? dayjs(period.value.start).format(FILTER_DATE_FORMAT_DEFAULT)
      : undefined,
    endDate: period.value?.end
      ? dayjs(period.value.end).format(FILTER_DATE_FORMAT_DEFAULT)
      : undefined,
  }));

  const uiDatePickerPresets = presets.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.periodName]: {
        value: {
          start: curr.start,
          end: curr.end,
        },
        title: curr.listTitle,
      },
    }),
    {},
  );

  return {
    clear,

    period,
    dateParams,
    isDefaultPeriod,
    appFilterTagDatePresets: presets,
    uiDatePickerPresets,
  };
}
