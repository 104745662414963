export enum StagingStatus {
  Stopped = 'STOPPED',
  Updating = 'UPDATING',
  Running = 'RUNNING',
  Unknown = 'UNKNOWN',
}

export enum StagingAction {
  Stop = 'STOP',
  Run = 'RUN',
}

export enum StagingManagementWsClientEvent {
  AuthLogin = 'auth:login',
}

export enum StagingManagementWsServerEvent {
  AuthLoginSuccess = 'auth:success',
  AuthLoginFailed = 'auth:failed',
  StagingCreated = 'karta-staging:created',
  StagingUpdated = 'karta-staging:updated',
  StagingDeleted = 'karta-staging:deleted',
  TaskUpdated = 'task:updated',
}
