import Axios, {
  type AxiosError,
  type AxiosRequestConfig,
  type AxiosResponse,
  type InternalAxiosRequestConfig,
} from 'axios';

import { useEnv } from '@/composables';
import {
  requestInterceptor,
  responseErrorInterceptor,
  responseSuccessInterceptor,
} from './interceptors';

const { apiBaseUrl } = useEnv();

export const http = Axios.create({
  baseURL: `${apiBaseUrl}/api/v1/app/`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

http.interceptors.request.use(requestInterceptor);
http.interceptors.response.use(
  responseSuccessInterceptor,
  responseErrorInterceptor,
);

export const httpPlain = Axios.create({
  baseURL: `${apiBaseUrl}/api/v1/app/`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

httpPlain.interceptors.response.use(
  responseSuccessInterceptor,
  responseErrorInterceptor,
);

export const httpPdf = Axios.create({
  baseURL: `${import.meta.env.PDF_BASE_URL}/api/v1/pdf/`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/pdf',
  },
  responseType: 'blob',
});

httpPdf.interceptors.response.use((response: AxiosResponse) => response.data);

export const httpCMS = Axios.create({
  baseURL: `${import.meta.env.CMS_BASE_URL}/api/`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

httpCMS.interceptors.response.use((response: AxiosResponse) => response.data);

export const httpStagingManagement = Axios.create({
  baseURL: `${import.meta.env.STAGING_MANAGEMENT_API_URL}/staging/`,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  auth: {
    username: import.meta.env.STAGING_MANAGEMENT_USERNAME,
    password: import.meta.env.STAGING_MANAGEMENT_PASSWORD,
  },
});

httpStagingManagement.interceptors.response.use(
  (response: AxiosResponse) => response.data,
);

export type HttpResponse = AxiosResponse;
export type HttpRequestConfig = AxiosRequestConfig;
export type HttpInternalRequestConfig = InternalAxiosRequestConfig;
export type HttpError = AxiosError;

export default http;
