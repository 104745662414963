<script setup lang="ts" generic="T extends Record<string, any>">
import type { UiTableColumn } from '@karta.io/ui-components';
import type { UiTableInstance } from '@/interfaces';

interface Props {
  columns: UiTableColumn[];
  rows: T[];
  sorting?: string;
  loadingItems?: number;
  loading: boolean;
  isHeadCellLoading?: boolean;
  page?: number;
  limit?: number;
  count?: number;
  isPageHeaderSticky?: boolean;
  hasError?: boolean;
  isInfinite?: boolean;
}

defineOptions({ name: 'Table' });

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  loadingItems: 25,
  isPageHeaderSticky: false,
  isHeadCellLoading: false,
  hasError: false,
  isInfinite: false,
});

const emit = defineEmits<{
  onSort: [value?: string];
  onPaginate: [page: number, limit: number];
  onRowMouseEnter: [row: T];
  onRowMouseLeave: [row: T];
  onRowClick: [row: T];
  onCellClick: [column: UiTableColumn, row: T];
  onScrollFinish: [];
}>();

const { isDesktop } = useBreakpoint();

const pagination = computed(() => {
  if (!props.page && !props.count) return null;

  return {
    page: props.page,
    limit: props.limit,
    count: props.count,
  };
});

const isHeadSticky = computed(() => isDesktop.value);

const handleSort = (value?: string) => emit('onSort', value);
const handlePaginate = (page: number, limit: number) =>
  emit('onPaginate', page, limit);

const handleRowClick = (row: T) => emit('onRowClick', row);
const handleRowMouseEnter = (row: T) => emit('onRowMouseEnter', row);
const handleRowMouseLeave = (row: T) => emit('onRowMouseLeave', row);
const handleCellClick = (column: UiTableColumn, row: T) =>
  emit('onCellClick', column, row);

const handleScrollFinish = () => emit('onScrollFinish');

const tableRef = ref<UiTableInstance>();
defineExpose({
  scrollIntoView: () => tableRef.value?.scrollIntoView(),
});
</script>

<template>
  <UiTable
    ref="tableRef"
    :columns="props.columns"
    :rows="props.rows"
    :sorting="props.sorting"
    :loadingItems="props.loadingItems"
    :loading="props.loading"
    :pagination="pagination"
    :head="{ isSticky: isHeadSticky }"
    needScroll
    :hasError="props.hasError"
    :isInfinite="props.isInfinite"
    @sort="handleSort"
    @paginate="handlePaginate"
    @click:row="handleRowClick"
    @mouseenter:row="handleRowMouseEnter"
    @mouseleave:row="handleRowMouseLeave"
    @click:cell="handleCellClick"
    @finish:scroll="handleScrollFinish"
  >
    <template v-if="$slots.toolbar" #toolbar>
      <slot name="toolbar" />
    </template>

    <template #head-cell="headCell">
      <TableHeadCell
        :column="headCell.column"
        :sorting="headCell.sorting"
        :isLoading="props.isHeadCellLoading ? headCell.loading : false"
        @sort="handleSort"
      />
    </template>

    <template #cell-skeleton="{ column }">
      <TableCellSkeleton :column="column" />
    </template>

    <template #cell="{ column, row }">
      <TableCell :column="column" :row="row" />
    </template>

    <template v-if="$slots.empty" #empty>
      <slot name="empty" />
    </template>
  </UiTable>
</template>

<style lang="scss">
.table {
  .ui-table__pagination {
    --ui-table-pagination-height: auto;

    .ui-table-pagination {
      flex-wrap: wrap;
    }
  }
}

@include breakpoint('sm') {
  .table {
    .ui-table__pagination {
      --ui-table-pagination-height: 72px;

      .ui-table-pagination {
        flex-wrap: nowrap;
      }
    }
  }
}
</style>
