<script setup lang="ts">
import {
  UiAvatarSize,
  UiColorVar,
  UiTypography,
} from '@karta.io/ui-components';

import { Currency } from '@/enums';
import type { CompanyAccount } from '@/interfaces';

interface Props {
  companyAccount: CompanyAccount;
  loading?: boolean;
  size?: UiAvatarSize;
}

defineOptions({ name: 'CompanyAccountBlock' });

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  size: UiAvatarSize.Lg,
});

const cn = useClassName('company-account-block');

const isReady = computed(() => !props.loading && props.companyAccount);

const accountBalance = computed(() => {
  const { companyAccount } = props;
  if (!companyAccount) return null;

  return formatMoney(
    companyAccount.balance,
    companyAccount.currency || Currency.Usd,
  );
});

const typography = computed(() =>
  props.size === UiAvatarSize.Lg
    ? UiTypography.TextXlMedium
    : UiTypography.TextSmMedium,
);
const typographyBrSm = computed(() =>
  props.size === UiAvatarSize.Lg
    ? UiTypography.DisplayXsMedium
    : UiTypography.TextSmMedium,
);
</script>

<template>
  <div :class="[cn.b(), cn.mv('size', props.size)]">
    <CompanyAccountAvatar
      :size="props.size"
      :type="props.companyAccount.type"
    />

    <div style="flex-grow: 1">
      <AppText
        :loading="!isReady"
        skeletonWidth="40%"
        textEllipsis="100%"
        :typography="typography"
        :typographyBrSm="typographyBrSm"
      >
        {{ props.companyAccount?.name }}
      </AppText>

      <AppText
        :loading="!isReady"
        skeletonWidth="40%"
        :typography="UiTypography.TextXsMedium"
        :color="UiColorVar.Secondary500"
      >
        {{ accountBalance }}
      </AppText>
    </div>
  </div>
</template>

<style lang="scss">
.company-account-block {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-column-gap: var(--spacing-03);

  &_size_lg {
    grid-column-gap: var(--spacing-04);
  }
}
</style>
