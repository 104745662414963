import type { RouteRecordRaw } from 'vue-router';

import { permissionsGuard } from '@/router/guards';
import { useSaveQuery } from '@/composables';
import { CoreUserPermissionsMap } from '@/data';
import {
  COMPANY_ACCOUNT_BUDGETS_PAGE,
  COMPANY_ACCOUNT_CARDS_PAGE,
  COMPANY_ACCOUNT_INDEX_PAGE,
  COMPANY_ACCOUNT_TRANSACTIONS_PAGE,
} from './data';

const { setQuery: setTransactionsQuery } = useSaveQuery(
  COMPANY_ACCOUNT_TRANSACTIONS_PAGE.name,
);
const { setQuery: setBudgetsQuery } = useSaveQuery(
  COMPANY_ACCOUNT_BUDGETS_PAGE.name,
);
const { setQuery: setCardsQuery } = useSaveQuery(
  COMPANY_ACCOUNT_CARDS_PAGE.name,
);

export const companyAccountsRoutes: RouteRecordRaw[] = [
  {
    path: '/accounts/company/:id',
    name: COMPANY_ACCOUNT_INDEX_PAGE.name,
    component: () =>
      import('@/modules/company-accounts/pages/CompanyAccountIndexPage.vue'),
    beforeEnter: permissionsGuard,
    meta: {
      auth: true,
      navigation: COMPANY_ACCOUNT_INDEX_PAGE.navigation,
      permissions: [CoreUserPermissionsMap.ReadCompanyAccount],
    },
    children: [
      {
        path: 'transactions',
        name: COMPANY_ACCOUNT_TRANSACTIONS_PAGE.name,
        component: () =>
          import(
            '@/modules/company-accounts/pages/CompanyAccountTransactionsPage.vue'
          ),
        beforeEnter: [permissionsGuard, setTransactionsQuery],
        meta: {
          auth: true,
          navigation: COMPANY_ACCOUNT_TRANSACTIONS_PAGE.navigation,
          permissions: [CoreUserPermissionsMap.ReadTransaction],
        },
      },
      {
        path: 'budgets',
        name: COMPANY_ACCOUNT_BUDGETS_PAGE.name,
        component: () =>
          import(
            '@/modules/company-accounts/pages/CompanyAccountBudgetsPage.vue'
          ),
        beforeEnter: [permissionsGuard, setBudgetsQuery],
        meta: {
          auth: true,
          navigation: COMPANY_ACCOUNT_BUDGETS_PAGE.navigation,
          permissions: [CoreUserPermissionsMap.ReadBudget],
        },
      },
      {
        path: 'cards',
        name: COMPANY_ACCOUNT_CARDS_PAGE.name,
        component: () =>
          import(
            '@/modules/company-accounts/pages/CompanyAccountCardsPage.vue'
          ),
        beforeEnter: [permissionsGuard, setCardsQuery],
        meta: {
          auth: true,
          navigation: COMPANY_ACCOUNT_CARDS_PAGE.navigation,
          permissions: [CoreUserPermissionsMap.ReadCard],
        },
      },
    ],
  },
];

export default companyAccountsRoutes;
