/**
 * @todo После слива стори веток мультипровайдерности и мультивалютности
 * избавить от статики и оставить только вычисляемые
 */

import { CoreLocalStorage } from '@/enums';

interface Vars {
  isApp: boolean;
  isDemo: boolean;
  isProduction: boolean;
  isStaging: boolean;
  isDevelopment: boolean;
  apiBaseUrl: string;
  cmsBaseUrl: string;
  docsBaseUrl: string;
  mediaAssetsUrl: string;
  appVersion: string;
  appEnv: string;
  appType: string;
  sentryDsn: string;
  authDomain: string;
  authAudience: string;
  authAudiencePublic: string;
  authClientIdPublicApi: string;
  authClientId: string;
  smartyKey: string;
  neuroIdScriptUrl: string;
  iovationScriptUrl: string;
  firstbaseUrl: string;
  websiteBaseUrl: string;
  alloyJourneyToken: string;
  alloySdkKey: string;
  segmentKey: string;
  segmentProxyCdnUrl: string;
  segmentProxyApiUrl: string;
  stagingManagementApiUrl: string;
  stagingManagementWsUrl: string;
  stagingManagementUsername: string;
  stagingManagementPassword: string;
  marqetaScriptUrl: string;
  airwallexScriptUrl: string;
  ipInfoToken: string;
}

const apiBaseUrlStore = useStorage<string>(CoreLocalStorage.ApiBaseUrl, '');

export function useEnv(): Vars {
  return {
    isApp: import.meta.env.APP_TYPE === 'app',
    isDemo: import.meta.env.APP_TYPE === 'demo',
    isProduction: import.meta.env.APP_ENV === 'production',
    isStaging: import.meta.env.APP_ENV === 'staging',
    isDevelopment: import.meta.env.APP_ENV === 'development',
    apiBaseUrl: apiBaseUrlStore.value || import.meta.env.API_BASE_URL,
    cmsBaseUrl: import.meta.env.CMS_BASE_URL,
    docsBaseUrl: import.meta.env.DOCS_BASE_URL,
    mediaAssetsUrl: import.meta.env.MEDIA_ASSETS_URL,
    appVersion: import.meta.env.APP_VERSION,
    appEnv: import.meta.env.APP_ENV,
    appType: import.meta.env.APP_TYPE,
    sentryDsn: import.meta.env.SENTRY_DSN,
    authDomain: import.meta.env.AUTH_DOMAIN,
    authAudience: import.meta.env.AUTH_AUDIENCE,
    authAudiencePublic: import.meta.env.AUTH_AUDIENCE_PUBLIC,
    authClientIdPublicApi: import.meta.env.AUTH_CLIENT_ID_PUBLIC_API,
    authClientId: import.meta.env.AUTH_CLIENT_ID,
    smartyKey: import.meta.env.SMARTY_KEY,
    neuroIdScriptUrl: import.meta.env.NEURO_ID_SCRIPT_URL,
    iovationScriptUrl: import.meta.env.IOVATION_SCRIPT_URL,
    firstbaseUrl: import.meta.env.FIRSTBASE_URL,
    websiteBaseUrl: import.meta.env.WEBSITE_BASE_URL,
    alloyJourneyToken: import.meta.env.ALLOY_JOURNEY_TOKEN,
    alloySdkKey: import.meta.env.ALLOY_SDK_KEY,
    segmentKey: import.meta.env.SEGMENT_KEY,
    segmentProxyCdnUrl: import.meta.env.SEGMENT_PROXY_CDN_URL,
    segmentProxyApiUrl: import.meta.env.SEGMENT_PROXY_API_URL,
    stagingManagementApiUrl: import.meta.env.STAGING_MANAGEMENT_API_URL,
    stagingManagementWsUrl: import.meta.env.STAGING_MANAGEMENT_WS_URL,
    stagingManagementUsername: import.meta.env.STAGING_MANAGEMENT_USERNAME,
    stagingManagementPassword: import.meta.env.STAGING_MANAGEMENT_PASSWORD,
    marqetaScriptUrl: import.meta.env.MARQETA_SCRIPT_URL,
    airwallexScriptUrl: import.meta.env.AIRWALLEX_SCRIPT_URL,
    ipInfoToken: import.meta.env.IP_INFO_TOKEN,
  };
}
