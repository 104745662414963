import { permissionsGuard } from '@/router/guards';
import { useSaveQuery } from '@/composables';
import { CoreUserPermissionsMap } from '@/data';
import {
  TRANSACTIONS_COMPANY_PAGE,
  TRANSACTIONS_EXTERNAL_PAGE,
  TRANSACTIONS_INDEX_PAGE,
} from './data';

const { setQuery: setCompanyQuery } = useSaveQuery(
  TRANSACTIONS_COMPANY_PAGE.name,
);
const { setQuery: setExternalQuery } = useSaveQuery(
  TRANSACTIONS_EXTERNAL_PAGE.name,
);

export default [
  {
    path: '/transactions',
    name: TRANSACTIONS_INDEX_PAGE.name,
    component: () =>
      import('@/modules/transactions/pages/TransactionsIndexPage.vue'),
    beforeEnter: permissionsGuard,
    meta: {
      auth: true,
      navigation: TRANSACTIONS_INDEX_PAGE.navigation,
      permissions: [CoreUserPermissionsMap.ReadTransaction],
    },
    children: [
      {
        path: 'personal',
        redirect: '/transactions/company',
      },
      {
        path: 'company',
        name: TRANSACTIONS_COMPANY_PAGE.name,
        component: () =>
          import('@/modules/transactions/pages/TransactionsCompanyPage.vue'),
        beforeEnter: [permissionsGuard, setCompanyQuery],
        meta: {
          auth: true,
          navigation: TRANSACTIONS_COMPANY_PAGE.navigation,
          permissions: [CoreUserPermissionsMap.ReadTransaction],
        },
      },
      {
        path: 'external',
        name: TRANSACTIONS_EXTERNAL_PAGE.name,
        component: () =>
          import('@/modules/transactions/pages/TransactionsExternalPage.vue'),
        beforeEnter: [permissionsGuard, setExternalQuery],
        meta: {
          auth: true,
          navigation: TRANSACTIONS_COMPANY_PAGE.navigation,
          permissions: [CoreUserPermissionsMap.ReadExternalTransaction],
        },
      },
    ],
  },
];
